import { useSelector } from 'react-redux';

/**
 * Custom hook to get the authenticated user from the Redux store.
 * This hook abstracts the Redux selector logic for fetching the user,
 * making it easily reusable across components.
 * 
 * @returns {Object} The authenticated user object from the Redux store.
 */
export const useAuthUser = () => {
  const user = useSelector(state => state.auth.user);
  return user;
};
