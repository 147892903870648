import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Flex, Box, HStack } from "@chakra-ui/react";

import SideNav from "../components/nav/SideNav";
import AllPostsFromAuthUser from "../components/posts/AllPostsFromAuthUser";
import PageHeading from "../components/general/PageHeading";

import PageLayout from "../components/general/PageLayout";

function AllPosts() {
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }
  }, []);

  return (
    <PageLayout activeLink="/creator/posts">
      <Box h="full">
        <AllPostsFromAuthUser />
      </Box>
    </PageLayout>
  );
}

export default AllPosts;
