import React from 'react';
import { HStack, Link, Box, Text, useBreakpointValue } from '@chakra-ui/react'; // Import useBreakpointValue

const SubscriptionOptions = ({ isSameUser, profile, isSubscribed }) => {


  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paddingx = useBreakpointValue({ base: "0", md: "16" });
  const boxWidth = useBreakpointValue({ base: "100%", md: "100%" });
  const marginBoxTop = useBreakpointValue({ base: "8px", md: "0px" });

  if (isSameUser || !profile?.plan.isActive || isSubscribed) {
    return null; // Don't render anything if it's the same user or the plan is not active
  }

  return (
    <HStack 
      flexDirection={flexDirection}
      spacing="8px"
      w={boxWidth}
      mt={marginBoxTop}
    >
      <Link
        href={`/register/subscriber?creator=${profile._id}&interval=yearly`}
        bg="white" 
        border="1.5px solid" 
        borderColor="#F1F1F1" 
        borderRadius="6px" 
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0px 8px"
        height="44px"
        _hover={{ bg: "#F1F1F1" }}
        fontWeight="600"
        w="100%"
      >
        Subscribe for ${profile.plan.yearly}/year
      </Link>

      <Link
        href={`/register/subscriber?creator=${profile._id}&interval=monthly`}
        bg="#2261EB"
        borderRadius="6px"
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0px 8px"
        height="44px"
        _hover={{ bg: "#1751D0" }}
        fontWeight="600"
        w="100%"
      >
        Subscribe for ${profile.plan.monthly}/month
      </Link>
    </HStack>
  );
};

export default SubscriptionOptions;


