import axios from 'axios';

const userAPIService = {
    
    async fetchProfileByUsername(username) {
        try {
          const response = await axios.post('/api/users/get-profile-by-username', { username });
          return { data: response.data, error: null };
        } catch (error) {
          return { data: null, error: error.response?.data || 'An error occurred' };
        }
      },
    
      async fetchProfileById(id) {
        try {
          const response = await axios.post('/api/users/get-profile', { id });
          return { data: response.data, error: null };
        } catch (error) {
          return { data: null, error: error.response?.data || 'An error occurred' };
        }
      },

      async updateProfile(formData){
        try {
          const response = await axios.post(`api/users/update-profile`, formData);
          return { data: response.data, error: null };
        } catch (error) {
          return { data: null, error: error.response?.data || 'An error occurred' };
        }
      },

  // Add more API functions as needed
};

export default userAPIService;



