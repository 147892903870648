import React, { useState, useEffect } from 'react';
import { Tooltip, Card, CardHeader, CardBody, Heading, Text } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons'

import axios from 'axios';
import { useSelector } from 'react-redux';

const MRRCard = () => {
    const [MRR, setMRR] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});

    const [error, setError] = useState(null);

    const data = useSelector(state => state.auth);
    //console.log(data);
    //console.log(data.user.id);

    const fetchProfile = async () => {
        try {
            const profileData = await axios.post('/api/users/get-profile', { id: data.user.id });
            //console.log(profileData.data)
            setProfile(profileData.data);
            //console.log(profile)
            //console.log(profile.stripeConnectedAccountID)

            fetchRevenue(profileData.data.stripeConnectedAccountID);
        } catch (err) {
            setError(error);
            setLoading(false);
            //console.log(err);
        }
    };

    const fetchRevenue = async (id) => {
        try {
            const response = await axios.post('/api/subscription/get-connectedAccount-recurring', {
                connectedAccountId: id
            });
            //console.log(response.data);
            setMRR(response.data.monthly_recurring_revenue);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };






    useEffect(() => {
        fetchProfile();

    }, []);

    if (loading) {
        return (
            <Card 
            style={{
                boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
                width: '100%',
                height: '100%',
                gap: '0px',
                borderRadius: '8px',
                border: '1px solid #EFEFEF',
                paddingBottom: '8px'

              }}
            mr={4} flex='1'  mb={{base:'16px', md: '0'}}
            >
                <CardHeader>
                    <Heading size='lg'>0</Heading>
                </CardHeader>
                <CardBody py="8px" display="flex" flexDirection="horizontal" gap={2} alignItems="center">
                <Text mb={0}  fontWeight="500" fontSize='18px'>Monthly Reccuring Revenue</Text>
                </CardBody>
            </Card>
        );
    }

    if (error) {
        return (
            <Card 
            style={{
                boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
                width: '100%',
                height: '100%',
                gap: '0px',
                borderRadius: '8px',
                border: '1px solid #EFEFEF',
                paddingBottom: '8px'

              }}
            mr={4} flex='1'  mb={{base:'16px', md: '0'}}
            >
                <CardHeader>
                    <Heading size='lg'>0</Heading>
                </CardHeader>
                <CardBody py="8px" display="flex" flexDirection="horizontal" gap={2} alignItems="center">
                <Text mb={0}  fontWeight="500" fontSize='18px'>{error.message}</Text>
                </CardBody>
            </Card>
        );
    }

    return (
        <Card 
        style={{
            boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
            width: '100%',
            height: '100%',
            gap: '0px',
            borderRadius: '8px',
            border: '1px solid #EFEFEF',
            paddingBottom: '8px'
          }}
          mr={4} 
          flex='1'
          mb={{base:'16px', md: '0'}}
        >
            <CardHeader pb={0} mb={0}>
                <Heading  size='lg'>${MRR && MRR.toFixed(2)}</Heading>
            </CardHeader>
            <CardBody py="8px" display="flex" flexDirection="horizontal" gap={2} alignItems="center">
            <Text mb={0} fontWeight="500" fontSize='18px'>Monthly Reccuring Revenue</Text>
            </CardBody>
        </Card>
    );
};

export default MRRCard;
