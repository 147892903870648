import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const SubscriptionCountCard = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});

    const [error, setError] = useState(null);

    const data = useSelector(state => state.auth);
    ////console.log(data);
    ////console.log(data.user.id);

    const fetchProfile = async () => {
        try {
            const profileData = await axios.post('/api/users/get-profile', { id: data.user.id });
            //console.log(profileData.data)
            setProfile(profileData.data);
            //console.log(profile)
            //console.log(profile.stripeConnectedAccountID)

            fetchSubscriptions(profileData.data.stripeConnectedAccountID);
        } catch (err) {
            setError(error);
            setLoading(false);
            //console.log(err);
        }
    };

    const fetchSubscriptions = async (id) => {
        try {
          const response = await axios.post('/api/subscription/list-subscriptions', {
            connectedAccountId: id
          });
          const activeSubscriptions = response.data.filter(subscription => subscription.status === 'active');
          //console.log(activeSubscriptions);
          setSubscriptions(activeSubscriptions);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
      






    useEffect(() => {
        fetchProfile();
       
    }, []);

    if (loading) {
        return (
            <Card 
            style={{
                boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
                width: '100%',
                height: '100%',
                gap: '0px',
                borderRadius: '8px',
                border: '1px solid #EFEFEF',
                paddingBottom: '8px'


              }}
            mr={4} flex='1'  mb={{base:'16px', md: '0'}}
            >
                <CardHeader>
                    <Heading size='lg'>0</Heading>
                </CardHeader>
                <CardBody py="8px">
                <Text mb={0}  fontWeight="500" fontSize='18px'>Active Subscribers</Text>
            </CardBody>
            </Card>
        );
    }

    if (error) {
        return (
            <Card         
            style={{
            boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
            width: '100%',
            height: '100%',
            gap: '0px',
            borderRadius: '8px',
            border: '1px solid #EFEFEF',
            paddingBottom: '8px'


          }}mr={4} flex='1' mb={{base:'16px', md: '0'}}
          >
            <CardBody py="8px">
                <Text mb={0}  fontWeight="500" fontSize='18px'>{error.message}</Text>
                </CardBody>
            </Card>
        );
    }

    return (
        <Card mr={4} flex='1'
        style={{
            boxShadow: '0px 1px 2px 0px rgba(24, 24, 28, 0.06), 0px 0px 2px 0px rgba(24, 24, 28, 0.03)',
            width: '100%',
            height: '100%',
            gap: '0px',
            borderRadius: '8px',
            border: '1px solid #EFEFEF',
            paddingBottom: '8px'
          }}
          mb={{base:'16px', md: '0'}}
        >
            <CardHeader pb={0} mb={0}>
                <Heading  size='lg'>{subscriptions.length}</Heading>
            </CardHeader>
            <CardBody py="8px">
                <Text mb={0}  fontWeight="500" fontSize='18px'>Active Subscribers</Text>
            </CardBody>
        </Card>
    );
};

export default SubscriptionCountCard;
