import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  Text,
  useColorModeValue,
  IconButton,
  HStack,
  useBreakpointValue
} from "@chakra-ui/react";
import { FiImage, FiCode } from "react-icons/fi"; // Assuming these are the icons you want to use
import FeatherIcon from "feather-icons-react";

import PageLayout from "../components/general/PageLayout";
import PageHeading from "../components/general/PageHeading";

import Badge from "../components/general/Badge";

function NewPost() {
  const bgColor = useColorModeValue("#ffffff");
  const hoverBgColor = useColorModeValue("#F8F8F8");
  const navigate = useNavigate();

  // Function to handle navigation
  const handleNavigate = (path) => {
    navigate(path);
  };

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  return (
    <PageLayout activeLink="/new/post">
      <PageHeading
        heading="New Post"
        subHeading="Share something new with your audience"
      />
      <Box
        direction="row"
        align="center"
        w="full"
        h="full"
        p={2}
        overflowY="auto"
      >
        <Flex
          direction={flexDirection}
          justify="space-between"
          p={4}
          gap={8}
          align="stretch"
          w="full"
        >
          {/* Design CTA */}
          <Box
            background="#fbfcfe"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            as="button"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            onClick={() => handleNavigate("/new/post/design")}
            gap={2}
            w="full"
            color="#113B98"
            _hover={{ borderWidth:"2px", borderColor:"#113B98" }}

          >
            <FeatherIcon size="56px" icon="figma" />
            <Heading mb={0} size="lg">Design</Heading>
            <Text fontWeight={400} mb={2}>Share your lastest design exploration</Text>
          </Box>

          {/* Code CTA */}
          <Box
            background="#fcfafe"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            as="button"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            _hover={{ borderWidth:"2px", borderColor:"#5C1DA7" }}
            onClick={() => handleNavigate("/new/post/code")}
            gap={2}
            w="full"
            color="#5C1DA7"
          >
            <FeatherIcon size="56px" icon="code" />
            <Heading size="lg">Code</Heading>
            <Text fontWeight={400} mb={2}>Share your latest code snippets</Text>
          </Box>
        </Flex>
      </Box>
    </PageLayout>
  );
}

export default NewPost;
