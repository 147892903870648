import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useActiveSubscriptions = (email, selectedUsername) => {
  const [allPosts, setAllPosts] = useState([]);
  const [displayPosts, setDisplayPosts] = useState([]);
  const [options, setOptions] = useState([]);
  const [stripeSubslist, setStripeSubslist] = useState([]);
  const [subscribedUsernames, setSubscribedUsernames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    try {
      const postsResponse = await axios.post('/api/posts/get-all-subscribed-posts-2', {
        list: subscribedUsernames,
        page: currentPage,
        limit: 9
      });
      const newPosts = postsResponse.data.posts;
      if (currentPage === 1) {
        setAllPosts(newPosts);
      } else {
        setAllPosts(prev => [...prev, ...newPosts]);
      }
      setHasMore(currentPage < postsResponse.data.totalPages);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, subscribedUsernames]);

  useEffect(() => {
    if (subscribedUsernames.length > 0) {
      fetchPosts();
    }
  }, [currentPage, subscribedUsernames, fetchPosts]);

  useEffect(() => {
    const updateDisplayPosts = () => {
      const filtered = selectedUsername === 'All Subscriptions' ? allPosts : allPosts.filter(post => post.username === selectedUsername);
      setDisplayPosts(filtered);
    };

    updateDisplayPosts();
  }, [selectedUsername, allPosts]);

  useEffect(() => {
    const fetchActiveSubscriptions = async () => {
      setLoading(true);
      try {
        const mongoSubsResponse = await axios.post('/api/subscription/findByCustomerEmail', { customerEmail: email });
        const stripeSubsResponse = await axios.post('/api/subscription/getInfoListActive', { subscriptions: mongoSubsResponse.data });
        setStripeSubslist(stripeSubsResponse.data);

        const usernamesResponse = await axios.post('/api/subscription/find-subscriptions-by-stripe-id', { subscriptions: stripeSubsResponse.data });
        setSubscribedUsernames(usernamesResponse.data);

        // This code showed duplicates
        // const newOptions = [{ value: 'All Subscriptions', label: 'All Subscriptions' }, ...usernamesResponse.data.map(username => ({ value: username, label: username }))];
        // setOptions(newOptions);
        // console.log(newOptions);

        // Create unique options
        const uniqueUsernames = Array.from(new Set(usernamesResponse.data));
        const newOptions = [{ value: 'All Subscriptions', label: 'All Subscriptions' }, ...uniqueUsernames.map(username => ({ value: username, label: username }))];
        setOptions(newOptions);
        console.log(newOptions);


      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveSubscriptions();
  }, [email]);

  return { displayPosts, options, stripeSubslist, subscribedUsernames, loading, error, hasMore, setCurrentPage, setSubscribedUsernames };
};

export default useActiveSubscriptions;
