import React, { useRef, useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import PostCard from '../posts/PostCard';

const SubscribedPosts = ({ posts, hasMore, loadMore }) => {
  const sentinel = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loadMore]);

  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)', // Mobile
        sm: 'repeat(2, 1fr)',  // Small screens
        md: 'repeat(3, 1fr)',  // Medium screens
        lg: 'repeat(4, 1fr)'   // Large screens
      }}
      gap={12}
    >
      {posts.map((item) => (
        <PostCard action="viewDesign" data={item} key={item.id} />
      ))}
      <div ref={sentinel} style={{ height: '8px', width: '100%' }}></div>
    </Grid>
  );
};

export default SubscribedPosts;
