import React, { useEffect, useState } from 'react';
import axios from 'axios';


const InstagramCallback = () => {
    const [InstagramToken, setInstagramToken] = useState({});

  useEffect(() => {
    const handleCallback = async () => {
      const code = new URLSearchParams(window.location.search).get('code');

    // Get Access Token
      try {
        console.log(code)
        const response = await axios.post('/api/posts/instagram-callback', {code:code});
        console.log(response.data);
        console.log(response.data.access_token);

        let fowardURL = "/upload/instagram"

        setTimeout(() => {
            //window.location.href = `${fowardURL}?access_token=${response.data.access_token}`
        }, 1000);

      } catch (error) {
        console.error(error);
      }


   
    };
    handleCallback();
  }, [setInstagramToken, InstagramToken]);

  return <div>Logging in...</div>;
};

export default InstagramCallback;
