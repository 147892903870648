import { useEffect } from "react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button as StripeButton } from "@stripe/ui-extension-sdk/ui";

import {
  Image,
  Flex,
  Badge,
  Text,
  Box,
  Button,
  Heading,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  UnorderedList,
  ListItem,
  Center,
  Spinner,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";

import DeleteStripeConnect from "./DeleteStripeConnect";
import BalanceCard from "../dashboard/BalanceCard";

import Stripe2 from "../../Images/Stripe2.svg";
import PageHeading from "../general/PageHeading";

// This component shows the Stripe onboarding link or Login Link based on returned account details info

const ConnectedAccountLinks = ({ match, setIsConnectAccount }) => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const location = useLocation();
  const shouldRenderContinueButton =
    location.pathname === "/membership/onboarding/2";

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [connectedAccountId, setConnectedAccountId] = useState(null);
  const [connectedAccountDetails, setConnectedAccountDetails] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [
    createStripeExpressAccountButton,
    setCreateStripeExpressAccountButton,
  ] = useState(false);
  const [requirementsMap, setRequirementsMap] = useState({
    "business_profile.mcc":
      "The type of business category code (MCC) is missing. It tells what type of business it is.",
    "business_profile.url": "The website address for the business is missing.",
    business_type:
      "The legal structure of the business is missing. This includes things like LLC or sole proprietorship.",
    external_account:
      "The bank account where the payments will be deposited is missing.",
    "representative.first_name":
      "The first name of the person who represents the business is missing.",
    "representative.last_name":
      "The last name of the person who represents the business is missing.",
    "tos_acceptance.date":
      "The date the business agreed to the Terms of Service is missing.",
    "tos_acceptance.ip":
      "The IP address of the device used to agree to the Terms of Service is missing.",
    "individual.verification.document":
      "An ID document needs to be uploaded and verified",
    "individual.address.city":
      "The city of the individual's address is missing.",
    "individual.address.line1":
      "The first line of the individual's address is missing.",
    "individual.address.postal_code":
      "The postal code of the individual's address is missing.",
    "individual.address.state":
      "The state of the individual's address is missing.",
    "individual.dob.day":
      "The day of the individual's date of birth is missing.",
    "individual.dob.month":
      "The month of the individual's date of birth is missing.",
    "individual.dob.year":
      "The year of the individual's date of birth is missing.",
    "individual.email": "The individual's email address is missing.",
    "individual.first_name": "The individual's first name is missing.",
    "individual.last_name": "The individual's last name is missing.",
    "individual.phone": "The individual's phone number is missing.",
    "individual.ssn_last_4":
      "The last four digits of the individual's Social Security number are missing.",
  });

  const [loginLink, setLoginLink] = useState(null);
  const [onboardLink, setOnboardLink] = useState(null);

  const [loading, setLoading] = useState(false);

  const [finalRender, setFinalRender] = useState("");

  useEffect(() => {
    const GetStripeLinks = async () => {
      try {
        setLoading(true); // Add this line

        // Get the profile
        const profile = await axios.post("/api/users/get-profile", {
          id: auth.user.id,
        });
        console.log(profile);

        // Logic if user has a Stripe Connected Account ID
        if (profile.data.stripeConnectedAccountID != null) {
          setConnectedAccountId(profile.data.stripeConnectedAccountID);

          // Passes the accountID to the Onboarding Stripe Component
          // setIsConnectAccount(profile.data.stripeConnectedAccountID)

          // Get Account Details
          const accountDetails = await axios.post(
            "/api/connectedAccounts/accountDetails",
            { id: profile.data.stripeConnectedAccountID }
          );
          console.log("I've made it here");
          setConnectedAccountDetails(accountDetails.data);
          //  setIsConnectAccount(accountDetails.data)
          console.log(accountDetails.data);
          //console.log(connectedAccountDetails)

          if (accountDetails.data.details_submitted) {
            console.log("Show Login Link");
            console.log(accountDetails);

            // Login to Stripe
            const login = await axios.post(
              "/api/connectedAccounts/stripeLogin",
              { id: profile.data.stripeConnectedAccountID }
            );
            console.log(login);
            setLoginLink(login.data.url);

            // Show view to login into Stripe
            setFinalRender("Login");
            setLoading(false);
          } else {
            console.log("User has not completed onboarding");
            // Complete Onboarding
            const connectRes = await axios.post(
              "/api/connectedAccounts/connectAccount",
              { id: profile.data.stripeConnectedAccountID }
            );
            setOnboardLink(connectRes.data.url);

            // Show view to complete Stripe Onboarding
            setFinalRender("Complete Onboarding");
            setLoading(false);
          }
        } else {
          console.log("User does not have a Stripe Account");

          // Show view to start a new Stripe Onboarding
          setFinalRender("New Onboarding");
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    GetStripeLinks();
  }, [connectedAccountId, auth.user.id, dispatch, setConnectedAccountDetails]);

  // Button to Link that allows user to onboard for a new Stripe Connected Account ID
  const createStripeExpressAccountHandleSubmit = async () => {
    setSubmitLoading(true); // Set loading to true at the start of the function
    console.log("User does not have a Stripe Account, let's make one");

    try {
      const createStripeExpressAccount = await axios.post(
        "/api/connectedAccounts/createStripeExpressAccount",
        { id: auth.user.id, email: auth.user.email }
      );
      console.log(createStripeExpressAccount.data.id);

      const createStripeExpressAccountStartLink = await axios.post(
        "/api/connectedAccounts/connectAccount",
        { id: createStripeExpressAccount.data.id }
      );
      window.location.href = createStripeExpressAccountStartLink.data.url;
    } catch (error) {
      console.error("Error in creating Stripe Express Account:", error);
      // Optionally handle any errors, such as showing an error message
    }

    //setSubmitLoading(false); // Set loading to false at the end of the function
  };

  // User is fully connected with Stripe
  {
    if (loading) {
      return (
        <Center h="100vh">
          <Spinner />
        </Center>
      );
    } else if (loginLink != null && finalRender == "Login") {
      return (
        <VStack alignItems="left">
          <Box borderWidth="1px" borderRadius="md" p={4}>
            <Box display="flex" flexDirection="column">
              {/*Top*/}
              <HStack justifyContent="space-between">
                <HStack>
                  <Image boxSize="100px" src={Stripe2} alt="Stripe Logo" />

                  <VStack alignItems="left">
                    <Heading size="md"> Instant Payout via Stripe</Heading>
                    <HStack alignItems="center">
                      <Badge
                        fontSize="sm"
                        variant="subtle"
                        colorScheme={
                          connectedAccountDetails &&
                          connectedAccountDetails.charges_enabled
                            ? "green"
                            : "red"
                        }
                      >
                        {connectedAccountDetails &&
                        connectedAccountDetails.charges_enabled
                          ? "Connected"
                          : "Disconnected"}
                      </Badge>
                    </HStack>
                  </VStack>
                </HStack>

                <HStack>
                  <Box>
                    {(connectedAccountDetails &&
                      connectedAccountDetails.payouts_enabled === false) ||
                    connectedAccountDetails.charges_enabled === false ? (
                      <Button
                        _hover={{
                          backgroundColor: "#1650CF",
                          color: "#ffffff",
                        }}
                        backgroundColor="#105DFB"
                        color="#ffffff"
                        as="a"
                        href={loginLink && loginLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Stripe Dashboard
                      </Button>
                    ) : (
                      <Button
                        _hover={{
                          backgroundColor: "#1650CF",
                          color: "#ffffff",
                        }}
                        backgroundColor="#105DFB"
                        color="#ffffff"
                        as="a"
                        href={loginLink && loginLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Stripe Dashboard
                      </Button>
                    )}
                  </Box>

                  <Box>
                    {connectedAccountDetails &&
                      connectedAccountDetails.payouts_enabled &&
                      connectedAccountDetails.charges_enabled && (
                        <DeleteStripeConnect
                          connectedAccountId={connectedAccountId}
                          currentActiveStatus={true}
                          id={auth.user.id}
                        />
                      )}
                  </Box>
                </HStack>
              </HStack>

              {/*Link for Onboarding*/}
              <Box display="flex" flexDirection="column" alignItems="flex-start">

              {shouldRenderContinueButton && (
                <Link
                  variant="primaryButton"
                  width="auto"
                  href="/membership/onboarding/3"
                >
                  Save & Continue
                </Link>
              )}
              </Box>

              {/*Bottom*/}
              <Box>
                {/* Show Stripe Requirements */}
                {connectedAccountDetails &&
                connectedAccountDetails.requirements.currently_due.length >
                  0 ? (
                  <Box overflowX="auto">
                    <Text fontSize="16px">
                      Stripe requires additional information in order to fully
                      activate your account.
                    </Text>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {connectedAccountDetails.requirements.currently_due.map(
                          (requirement, index) => {
                            // Check if the requirement exists in the requirementsMap and is not undefined
                            //  if (Object.keys(requirementsMap).includes(requirement) && requirementsMap[requirement] !== undefined) {
                            return (
                              <Tr key={index}>
                                <Td>
                                  <Box display="flex" alignItems="center">
                                    <CloseIcon color="red" mr={2} />
                                    {requirement}
                                  </Box>
                                </Td>
                                <Td color="red">Required</Td>
                              </Tr>
                            );
                            //  }
                            return null; // Return null for undefined or non-existing requirements
                          }
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Box>
          </Box>
        </VStack>
      );

      {
        /* User has not completed onboarding with Stripe */
      }
    } else if (onboardLink != null && finalRender == "Complete Onboarding") {
      return (
        <Box>
          <Box>
            <PageHeading
              heading="Missing Stripe Requirements"
              subHeading="Complete your Stripe account to get paid"
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            background="#FFFFFF"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            w="100%"
            h="100%"
            px={{base:'16px', md:'16px'}}
            pb={4}
          >
            <Box>
              <HStack>
                <Box width="100%">

                  <Flex width="100%" justifyContent="space-between" alignItems="center" flexDirection={flexDirection} >
                    <Image boxSize="100px" src={Stripe2} alt="Stripe Logo" />

                    <Link
                      color="#1751D0"
                      fontWeight="550"
                      fontSize={{base:'15px', md:"18px"}}
                      href={onboardLink}
                      mb={{base:'12px', md:"0"}}
                    >
                      Complete onboarding with Stripe {" "}
                      <Icon
                        as={ArrowForwardIcon}
                        color="#1751D0"
                        fontSize="2xl"
                      />
                    </Link>
                  </Flex>

                  <DeleteStripeConnect
                    currentActiveStatus={true}
                    connectedAccountId={connectedAccountId}
                    id={auth.user.id}
                  />
                </Box>
              </HStack>

              <Box>
                {/* Show Stripe Requirements */}
                {connectedAccountDetails &&
                connectedAccountDetails.requirements.currently_due.length >
                  0 ? (
                  <Box>
                    {connectedAccountDetails &&
                      connectedAccountDetails.requirements.currently_due
                        .length > 0 && (
                        <Box overflowX="auto">
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th>Description</Th>
                                <Th>Status</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {connectedAccountDetails.requirements.currently_due.map(
                                (requirement, index) => {
                                  // Check if the requirement exists in the requirementsMap and is not undefined
                                  //  if (Object.keys(requirementsMap).includes(requirement) && requirementsMap[requirement] !== undefined) {
                                  return (
                                    <Tr key={index}>
                                      <Td>
                                        <Box display="flex" alignItems="center">
                                          <CloseIcon color="red" mr={2} />
                                          {requirement}
                                        </Box>
                                      </Td>
                                      <Td color="red">Required</Td>
                                    </Tr>
                                  );
                                  //  }
                                  return null; // Return null for undefined or non-existing requirements
                                }
                              )}
                            </Tbody>
                          </Table>
                        </Box>
                      )}
                  </Box>
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      );

      {
        /* User has not onboarded with Stripe */
      }
    } else if (
      loginLink == null &&
      onboardLink == null &&
      finalRender == "New Onboarding"
    ) {
      return (
        <Box>
          <Box>
            <PageHeading
              heading="Set up Instant Payouts"
              subHeading="Get paid directly and instantly with daily payouts"
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            background="#FFFFFF"
            border="1px solid #ECECED"
            boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
            borderRadius="8px"
            w="100%"
            h="100%"
            px={{ base: "8", sm: "8" }}
            alignItems="flex-start"
            pb={4}
          >
            <Image boxSize="120px" src={Stripe2} alt="Stripe Logo" />

            <Text
              fontFamily="'Inter'"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              color="#2D2B32"
            >
              Stripe is a powerful online payment platform that enables
              UIMarketplace to securely accept payments and manage transactions.
            </Text>

            <Button
              variant="primaryButton"
              width="auto"
              disabled={submitLoading}
              pointerEvents={submitLoading ? "none" : "auto"}
              _hover={{ backgroundColor: "#4F4D55", color: "#ffffff" }}
              onClick={createStripeExpressAccountHandleSubmit}
            >
              {submitLoading ? (
                <HStack gap={2} alignItems="center">
                  <Text m={0}>Get Paid with Stripe</Text>{" "}
                  <Spinner color="white" />
                </HStack>
              ) : (
                <Text m={0}>Get Paid with Stripe</Text>
              )}
            </Button>
          </Box>
        </Box>
      );
    } else {
      return <Box></Box>;
    }
  }
};

export default ConnectedAccountLinks;
