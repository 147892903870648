import React from 'react';
import axios from 'axios';
import { Flex, Button } from '@chakra-ui/react'

const APP_ID = '1244231486171725';
const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/oauth/instagram`;

const InstagramLogin = () => {
  const handleLoginClick = () => {
    const url = `https://api.instagram.com/oauth/authorize
    ?client_id=${APP_ID}
    &redirect_uri=${REDIRECT_URI}
    &scope=user_profile,user_media
    &response_type=code`
    window.location.href = url;
  };

  return (
    <Flex>
      <Button onClick={handleLoginClick}>Upload your Instagram Posts</Button>
    </Flex>
  );
};

export default InstagramLogin;