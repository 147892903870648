import React from 'react';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const Complete = () => {
  return (
    <Box borderRadius="md">
      <Flex alignItems="center">
        <Icon as={CheckCircleIcon} color="green.500" fontSize="2xl" />
        <Text mb={0} ml={2} fontSize="18px" fontWeight="medium" color="green.600">
          Complete
        </Text>
      </Flex>
    </Box>
  );
};

export default Complete;