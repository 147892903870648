import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser,LoginSubscriberClient } from "../../actions/authActions";
import classnames from "classnames";
import axios from "axios";

import { useNavigate } from 'react-router-dom';


class LoginSubscriber extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username:"",
      email: "",
      password: "",
      password2: "",
      errors: {},
      profile:{},
      link: ""
    };
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    const creator = urlParams.get('creator');
    const interval = urlParams.get('interval');

    const queryString = window.location.search;
    this.setState({
      queryString: queryString
      });


    const fetchProfile = async () => {
        try {
          const profileData = await axios.post('/api/users/get-profile', { id: creator });
          let link;
          //console.log(profileData.data)
        
        if(interval == "monthly"){
            link = profileData.data.plan.stripeMonthlyPriceLink
        } else if (interval == "yearly"){
            link = profileData.data.plan.stripeYearlyPriceLink
        }

        if (this.props.auth.isAuthenticated) {
          window.location.href = link;
        }

        this.setState({
        profile: profileData.data,
        link: link
        });


        } catch (err) {
          //console.log(err);
        }
      };
      fetchProfile()
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      email: this.state.email,
      password: this.state.password,
    };

    //console.log(this.state);
    this.props.LoginSubscriberClient({user:newUser, link: this.state.link}, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
        <div className="container">
          <div style={{ marginTop: "4rem" }} className="row">
            <div className="col s8 offset-s2">
              <Link to="/" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> Back to
                home
              </Link>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                  <b>Login</b> below
                </h4>
                <p className="grey-text text-darken-1">
                  Don't have an account? <Link to="/register">Register</Link>
                  <Link to={`/register/subscriber${this.state.queryString && this.state.queryString}`}>Register</Link>
                </p>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <div className="input-field col s12">
                  <input
                    onChange={this.onChange}
                    value={this.state.email}
                    error={errors.email}
                    id="email"
                    type="email"
                    className={classnames("", {
                      invalid: errors.email || errors.emailnotfound
                    })}
                  />
                  <label htmlFor="email">Email</label>
                  <span className="red-text">
                    {errors.email}
                    {errors.emailnotfound}
                  </span>
                </div>
                <div className="input-field col s12">
                  <input
                    onChange={this.onChange}
                    value={this.state.password}
                    error={errors.password}
                    id="password"
                    type="password"
                    className={classnames("", {
                      invalid: errors.password || errors.passwordincorrect
                    })}
                  />
                  <label htmlFor="password">Password</label>
                  <span className="red-text">
                    {errors.password}
                    {errors.passwordincorrect}
                  </span>
                </div>
                <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                  <button
                    style={{
                      width: "150px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    type="submit"
                    className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
  }
}

LoginSubscriber.propTypes = {
  registerUser: PropTypes.func,
  LoginSubscriberClient: PropTypes.func,
  auth: PropTypes.object,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});



export default connect(
  mapStateToProps,
  { registerUser,LoginSubscriberClient }
)(LoginSubscriber);
