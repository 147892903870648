import { useState, useEffect } from 'react';

// This extracts the SRC link from the embeded code provided by Figma
function ExtractSRC(iframeString) {
  const [src, setSrc] = useState('');

  useEffect(() => {
    // Extract the src value using a regular expression
    const srcMatch = iframeString.match(/src="([^"]+)"/);
    if (srcMatch && srcMatch[1]) {
      setSrc(srcMatch[1]);
    }
  }, [iframeString]);

  return src;
}

export default ExtractSRC;
