import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";



// Register creator Part 1
export const registerUser = (userData, history) => dispatch => {

  axios.post("/api/users/register", userData)
    .then(res => {
      loginUserAfterSubscription(userData, history)(dispatch); // Passing dispatch to maintain the redux flow
    })
    .catch(err => {
      ////console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    
    );
};

// Register creator Part 2, so the user gets signed in
export const loginUserAfterSubscription = (userData, history) => dispatch => {
  axios.post("/api/users/login", userData)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      window.location.href = "/dashboard"; 
    })
    .catch(err => {
      //console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      //console.log(err);
    });
};


// Register subscriber Part 1
export const registerSubscriber = (userData, history) => dispatch => {

  //console.log(userData, history)

  axios.post("/api/users/register", userData)
    .then(res => {
      loginSubscriber(userData, history)(dispatch); // Passing dispatch to maintain the redux flow
    })
    .catch(err => {
      //console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// Register subscriber Part 2, so the user gets signed in and fowarded to payment link
export const loginSubscriber = (userData, link) => dispatch => {
  axios.post("/api/users/login", userData)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      window.location.href = link; 
    })
    .catch(err => {
      //console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      //console.log(err);
    });
};

// Login - get user token
export const loginUser = (userData, navigate) => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));

      // Redirect to /dashboard after successful login
      navigate("/dashboard");
    })
    .catch(err =>{
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
      //console.log(err)
    }
    );
};


// Register a new subscriber auth action
export const registerSubscriberClient = obj => dispatch => {
  //console.log("inside registerSubscriberClient");

  axios
    .post("/api/users/registerSubscriber", obj.user)
    .then(res => {
      // Save to localStorage
      //console.log("Token Created")
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      //console.log("made it to the end")
      window.location.href = obj.link
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


// Register a new subscriber auth action
export const LoginSubscriberClient = obj => dispatch => {
  //console.log("inside LoginSubscriberClient");

  axios
    .post("/api/users/login", obj.user)
    .then(res => {
      //console.log(res)
      // Save to localStorage
      //console.log("Token Created")
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      //console.log("made it to the end")
      window.location.href = obj.link
    })
    .catch(err =>{
      //console.log(err)

      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    }
    );
};



// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
