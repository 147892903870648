import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Center,
  Image,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  FormLabel
} from "@chakra-ui/react";
import ExtractSRC from "./ExtractSRC";



function DribbbleNewPost({ info, onPostSuccess }) {
  // Use destructuring assignment to extract form data
  const [formData, setFormData] = useState({
    title: info.title,
    url: "",
    description: info.description,
    media: info.media,
    DribbbleID: info.DribbbleID,
  });

  const [errors, setErrors] = useState({});
  const [profile, setProfile] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // Use destructuring assignment to extract user data from redux store
  const {
    user: { id },
  } = useSelector((state) => state.auth);

  const allowedPrefixes = [
    "https://www.figma.com/",
    "https://www.sketch.com/",
    "https://xd.adobe.com/",
    "https://projects.invisionapp.com/",
    "https://marvelapp.com/",
    "https://www.canva.com/",
    "https://app.zeplin.io/",
    "https://framer.com/",
  ];

  // Handles form input changes
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    //console.log(formData);
  };

  // Handles url input changes
  const onUrlChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({ ...formData, [name]: trimmedValue });

    const newErrors = { ...errors };

    // Validate URL: Check against allowed prefixes
    const isValidPrefix = allowedPrefixes.some((prefix) =>
      trimmedValue.startsWith(prefix)
    );
    if (!isValidPrefix && trimmedValue !== "") {
      newErrors.url = `You can only share URLs from the following apps: Figma, Sketch, Adobe XD, InVision, Marvel, Canva, Zeplin, Framer`;
    } else {
      delete newErrors.url; // Remove error if URL is corrected
    }

    setErrors(newErrors);
    //console.log(errors)
  };



  useEffect(() => {
    //console.log(formData);

    //console.log("Current errors:", errors);
    //console.log(Object.keys(errors).length);
    //console.log(submitDisabled);

    setSubmitDisabled(Object.keys(errors).length > 0);

    // Fetches user profile data
    const fetchProfile = async () => {
      try {
        const profileData = await axios.post("/api/users/get-profile", { id });
        //console.log(profileData.data);
        setProfile(profileData.data);
      } catch (err) {
        //console.log(err);
      }
    };
    fetchProfile();
  }, [id, errors]);

  // Handles form submission
  const onSubmit = async (e) => {
    //console.log("inside onSubmit");
    e.preventDefault();

    setErrors({});

    if (!formData.title) {
      setErrors({ title: "A title is required" });
      return;
    }

    if (!formData.url) {
      setErrors({ url: "A url is required" });
      return;
    }

    //console.log(formData);

    if (Object.keys(errors).length === 0) {
      // No errors, proceed with form submission
      setSubmitDisabled(false)

    try {
      const response = await axios.post("/api/posts/new-post-dribbble", {
        title: formData.title,
        media: formData.media,
        url: formData.url,
        description: formData.description,
        user: id,
        username: profile.username,
        fullName: profile.name,
        profileImage: profile.plan.profileImage,
        DribbbleID: formData.DribbbleID,
      });

      //console.log("axios post made");
      onPostSuccess();

      try {
        const post = await axios.post("/api/posts/get-post", {
          id: response.data._id,
        });
        if (post.data) {
          // Call onPostSuccess function to notify parent component
        }
      } catch (err) {
        //console.log(err);
        setErrors({
          post: "Page navigation failed, click All Posts in the side nav ",
        });
      }
    } catch (err) {
      //console.log(err);
      setErrors({
        post: "Your post was unable to be created, please try again",
      });
    }
  } else {
    // There are validation errors; do not proceed with submission
    //setSubmitLoading(false); // Reset loading state since we're not submitting
  }
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Box mb={2}>
          <Center>
            <Image src={info.media} boxSize="300px" />
          </Center>
        </Box>

        <Box mb={2}>
          <FormLabel variant="formHeading" htmlFor="title">Title:</FormLabel>
          <Input
            type="text"
            name="title"
            value={formData.title}
            onChange={onChange}
            variant="input"
          />
          {errors.title && <Text color="red.500">{errors.title}</Text>}
          
        </Box>

        <Box mb={2}>
          <FormLabel variant="formHeading" htmlFor="url">URL:</FormLabel>
          <Input
            size="md"
            variant="input"
            type="text"
            name="url"
            value={formData.url}
            onChange={onUrlChange}
          />
          {errors.url && (
       <Text color="red.500">{errors.url}</Text>

          )}
        </Box>

        <Box mb={2}>
          <FormLabel variant="formHeading" htmlFor="description">Description:</FormLabel>
          <Textarea
            variant="input"
            type="text"
            name="description"
            value={formData.description}
            onChange={onChange}
            
          />
        </Box>

        {errors.post && <Text>{errors.post}</Text>}

        <Box mb={2}>
          <Button isDisabled={submitDisabled} variant="primaryButton" type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default DribbbleNewPost;
