
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import axios from "axios";

import { Badge, Container, Row, Col } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

const useFetchProfile = (username) => {
  const [profile, setProfile] = useState(null);

  const fetchProfile = useCallback(async () => {
    try {
      const profileData = await axios.post('/api/users/get-profile-by-username', {username: username});

      setProfile(profileData.data);
    } catch (err) {
      //console.log(err);
    }
  }, [username]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return profile;
};



const ShowMemberPage = () => {

  // Authenticated user
  const data = useSelector(state => state.auth);
  const authUsername = data.user.username;

  // User whose being viewed
  const { username } = useParams();

  // profile gets the user whose account is being viewed
  const profile = useFetchProfile(username);



  useEffect(() => {

    //console.log(profile)
}, [username, profile]);

  return(
    <div>

      <p>Hi</p>

    </div>
  );
  };


export default ShowMemberPage;
