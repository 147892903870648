import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo2 from "../Images/Logo2.png";

import { useProfileByUsername } from "../customHooks/useProfileByUsername";
import { useProfileById } from "../customHooks/useProfileById";
import { usePostById } from "../customHooks/usePostById";
import { useAuthUser } from "../customHooks/useAuthUser";
import MembersPageBio from "../components/membersPage/MembersPageBio";
import SubscriptionOptions from "../components/membersPage/SubscriptionOptions";
import MembershipStatusBadge from "../components/MembershipSettings/MembershipStatusBadge";
import useActiveSubscriptions from "../customHooks/useActiveSubscriptions";

import PostModal from "../components/posts/PostModal";
import PostsByUser2 from "../components/posts/PostsByUser2";

import PublicNav from "../components/general/PublicNav";


import {
  Flex,
  Box,
  Center,
  Spacer,
  ButtonGroup,
  Button,
  Heading,
  Link,
  Spinner,
  Avatar,
  HStack,
  Image,
  useBreakpointValue
} from "@chakra-ui/react";

const ViewPublicMemberPage = () => {
  const { username, postId } = useParams();
  console.log(username);
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  // Call useAuthUser at the top level of the component
  const authUserDetails = useAuthUser();

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });


  const {
    subscribedUsernames,
    list,
    options,
    stripeSubslist,
    loading: subsLoading,
    error,
  } = useActiveSubscriptions(auth?.user.email);

  // Log the returned values
  console.log("subscribedUsernames", subscribedUsernames);

  // Use custom hooks for fetching data
  const profile = useProfileByUsername(username);
  console.log(profile);
  const authUser = useProfileById(useAuthUser()?.id); // Assuming useAuthUser returns the user object with an id
  const { post, loading: postLoading, error: postError } = usePostById(postId);

  // Determine if the current user has subscribed to the profile being viewed
  const isSubscribed = subscribedUsernames.includes(username);

  // State for UI control, not directly related to data fetching
  //const [editPage, setEditPage] = useState(null);

  // Determine if the viewed profile belongs to the authenticated user
  const isSameUser =
    profile.profile &&
    authUser.profile &&
    profile.profile._id === authUser.profile._id;

  // Handle redirect if username doesnt exist
  if (profile.loading == false) {
    if (profile && profile.profile == null) {
      navigate("/inactive-user");
    }
  }

  // Redirect logic, adjusted to skip if the user is subscribed
  // if (!profile.loading && profile.profile && profile.profile?.plan?.isActive === false && !isSubscribed) {
  //   navigate("/inactive-user");
  // }

  useEffect(() => {

    if (
      !profile.loading &&
      !subsLoading &&
      profile.profile?.plan?.isActive === false &&
      !isSubscribed
    ) {
      console.log("Redirecting to /inactive-user");
      navigate("/inactive-user");
    }
  }, [profile.loading, subsLoading, isSubscribed, profile.profile, navigate]);

  // Close modal handler
  const handleCloseModal = () => {
    navigate(`/u/${username}`); // Navigate back to user's page
  };

  const editPage = useMemo(() => {
    if (isSameUser) {
      return (
        <Flex flexDirection={{ base: "column", md: "row" }}>

        <Button my={4} p={0} style={{textDecoration: "none", background: "none"}} as={Link} href="/home">
          <Image
            objectFit="cover"
            src={Logo2}
            alt="UI Marketplace"
            w="250px"
          />
          </Button>


        <Spacer />
        <Box display="flex" flexDirection={{ base: "column", md: "row" }} justifyContent="center"  gap="2">          

          <Button
            variant="outline"
            color="#0A090B"
            onClick={() => navigate("/edit-membership-details")}
          >
            Edit Page Details
          </Button>
          <Button onClick={() => navigate("/dashboard")}>
            Return to Dashboard
          </Button>
        </Box>
      </Flex>
      );
    } else if (!isSameUser || !authUser.profile) { // Check for null authUser
      return (
          <PublicNav />
      );
    }
    return null; // Or an empty box, if you need a placeholder
  }, [isSameUser, authUser.profile]); // Recalculate if either changes

  // Sticky Nav
  const [isSticky, setIsSticky] = useState(false);
  const subscriptionRef = useRef(null); // Ref for the SubscriptionOptions component
  const contentRef = useRef(null); // Define contentRef here

  useEffect(() => {
    const handleScroll = () => {
      // Ensure that the ref and window are available
      if (contentRef.current && window) {
        const { bottom } = contentRef.current.getBoundingClientRect();
        const triggerSticky = bottom <= 0; // Check if the bottom of the element is at or above the top of the viewport
        setIsSticky(triggerSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const paddingx = useBreakpointValue({ base: "8", md: "16" });
  const paddingy = useBreakpointValue({ base: "2", md: "2" });


  return (
    <Box bg="#fdfdfd" px={paddingx} py={paddingy} w="100%" h="100%" m={0}>
    {editPage}
    <Box>
      
      {/* Check if either profile or subscriptions are still loading */}
      {profile.loading || subsLoading ? (
        <Center h="100vh">
          <Spinner />
        </Center>
      ) : (
        <Flex w="full" bg="#ffffff" minH="100vh" direction="column">
          <Flex
            alignItems="start"
            flexDirection="column"
            pt="24px"
            bg="#fdfdfd"
            w="full"
          >
            {profile.loading ? (
              <Center h="100vh">
                <Spinner />
              </Center>
            ) : profile.error ? (
              <Center>Error loading profile</Center>
            ) : (
              <Box pt={8}>
              <MembersPageBio
                profileImage={profile.profile?.plan.profileImage}
                name={profile.profile?.name}
                bio={profile.profile?.plan.bio}
                location={profile.profile?.plan.location}
                portfolioLink={profile.profile?.plan.portfolioLink}
                contactEmail={profile.profile?.plan.contactEmail}
                handle={username}
              />
              </Box>
            )}

            <SubscriptionOptions
              isSameUser={isSameUser}
              profile={profile.profile}
              isSubscribed={subscribedUsernames.includes(username)}
            />
          </Flex>
          <Box ref={contentRef}> </Box>

          {/* Sticky Navbar */}
          {isSticky && (
            <Flex
              position="fixed"
              top={0}
              left={0}
              right={0}
              zIndex={10}
              boxShadow="md"
              bg="white"
              justifyContent="space-between"
              alignItems="center"
              p={4}
              flexDirection={flexDirection}
            >
              <HStack spacing={0}>
                <Avatar
                  name={profile.profile?.name}
                  src={profile.profile?.plan.profileImage}
                  size="md"
                  mr={4}
                />
              </HStack>
                <SubscriptionOptions
                  isSameUser={isSameUser}
                  profile={profile.profile}
                  isSubscribed={subscribedUsernames.includes(username)}
                />
            </Flex>
          )}

          <Box>
            <Box overflowY="scroll">
              <PostsByUser2 userId={authUserDetails?.id} username={username} />

              {postLoading ? (
                <Center h="100vh">
                  <Spinner />
                </Center>
              ) : postError ? (
                <Center>Error loading post: {postError}</Center>
              ) : (
                <PostModal
                  isOpen={!!postId}
                  onClose={handleCloseModal}
                  data={post}
                  profile={profile.profile}
                  isSubscribed={subscribedUsernames.includes(username)}
                />
              )}
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
    </Box>
  );
};

export default ViewPublicMemberPage;
