// apiService.js
import axios from 'axios';

/**
 * Service for interacting with the posts API.
 */
const postsAPIService = {

  async fetchPostById(postId) {
    try {
      // Make a POST request with the postId in the request body
      const response = await axios.post('/api/posts/get-post', { id: postId });
      return { data: response.data, error: null };
    } catch (error) {
      console.error("Error fetching post:", error);
      return { data: null, error: error.response?.data.message || 'An error occurred while fetching the post.' };
    }
  },

  async getAllPostsByUser (userId) {
    try {
      const response = await axios.post('/api/posts/get-all-posts-from-user', { id:userId });
      return { data: response.data, error: null };
    } catch (error) {
    return { data: null, error: error.response?.data.message || 'An error occurred while fetching this users posts.' };
    }
  },

  // This is the endpoint used in the ViewPublicPage
  async getAllPostsByUsername(username, page = 1) {
    try {
      const response = await axios.post('/api/posts/get-all-posts-from-username', { username, page, limit: 9 });
      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error: error.response?.data.message || 'An error occurred while fetching this users posts.' };
    }
  },
  


};

export default postsAPIService;
