import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import Search from '../../Images/search.svg';
import { Image, Flex, Badge, Text, Box, Button, Heading, Container, HStack, VStack } from '@chakra-ui/react';


const NoSubscriptions = ({ match }) => {

  return (
    <div>

      <Flex
        direction="column"
        justify="center"
        align="center"
        height="50vh"
        maxW="400px"
        mx="auto"
      >
        <Image mb={4} boxSize='60px' src={Search} alt='No Results' />
        <Heading size='lg' mb={2}>No Active Subscriptions</Heading>
        <Text textAlign="center">
          You currently have no active subscriptions.
        </Text>
      </Flex>
    </div>
  );
};

export default NoSubscriptions;
