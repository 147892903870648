import React, { useEffect, useState } from 'react';
import { Button, Modal,  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, useToast } from '@chakra-ui/react';
import axios from 'axios';

{/*ARCHIVED FOR TOGGLE*/}
function MembershipStatusButton({currentActiveStatus, id}) {
  const [isActive, setIsActive] = useState();
  const [showModal, setShowModal] = useState(false);
  const toast = useToast()

  useEffect(() => {
    setIsActive(currentActiveStatus)
  }, [currentActiveStatus]);

  const handleClick = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleSubmit = () => {
    axios.put('/api/users/change-membership-status', { plan: { isActive: !isActive }, userId: id })
      .then(response => {
        setIsActive(response.data.plan.isActive);
        setShowModal(false);

        if(response.data.plan.isActive){
          toast({
            title: 'Membership page',
            description: "Membership page is activated",
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        } else{
          toast({
            title: 'Membership page',
            description: "Membership page is deactivated",
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      })
      .catch(error => {
        //console.log(error);
      });
  }

  return (
    <>
      <Button onClick={handleClick}>
        {isActive ? 'Deactivate' : 'Activate'}
      </Button>
      
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to {isActive ? 'deactivate' : 'activate'} your membership?
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {isActive ? 'Deactivate' : 'Activate'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MembershipStatusButton;
