import React, { useState, useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import SideNav from "../components/nav/SideNav";
import ExtractSRC from "../components/posts/ExtractSRC";

import DribbbbleLogin from "../components/posts/DribbbbleLogin";
import InstagramLogin from "../components/posts/InstagramLogin";
import PageHeading from "../components/general/PageHeading";

import {
  Flex,
  Box,
  Button,
  HStack,
  Image,
  FormControl,
  FormLabel,
  VStack,
  Stack,
  Textarea,
  Input,
  Text,
  Heading,
  Center,
} from "@chakra-ui/react";
import PageLayout from "../components/general/PageLayout";
import NewPostForm from "../components/posts/NewPostForm";

function NewPostDesign() {
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    description: "",
    tags: "",
    media: "",
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [profile, setProfile] = useState({});
  const [image, setImage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const inputRef = useRef();

  const data = useSelector((state) => state.auth);
  console.log(data);
  console.log(data.user.id);

  function extractSrc(url) {
    const srcRegex = /src="(.+?)"/;
    const match = srcRegex.exec(url);
    if (match) {
      return match[1];
    }
    return "";
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    // Use trimmedValue only for validation, not for setting state
    const trimmedValue = value.trim();
  
    // Update form data with the original value, not the trimmed one
    setFormData({ ...formData, [name]: value });
  
    // Proceed with your validation using trimmedValue
    const newErrors = { ...errors };
  
    // Validate Title: Simple non-empty check
    if (name === "title") {
      if (!trimmedValue) {
        newErrors.title = "Title is required";
      } else {
        delete newErrors.title; // Remove error if title is corrected
      }
    }
  
    // Validate URL: Check against allowed prefixes
    if (name === "url") {
      const isValidPrefix = allowedPrefixes.some((prefix) =>
        trimmedValue.startsWith(prefix)
      );
      if (!isValidPrefix && trimmedValue !== "") {
        newErrors.url = `You can only share URLs from the following apps: Figma, Sketch, Adobe XD, InVision, Marvel, Canva, Zeplin, Framer`;
      } else {
        delete newErrors.url; // Remove error if URL is corrected
        // Assuming extractSrc works with both trimmed and untrimmed values
        const src = extractSrc(value); // Use the original value for extracting src
        console.log(value); // Log the original value
        console.log(src);
        setPreviewUrl(src);
      }
    }
  
    setErrors(newErrors);
  };
  

  const allowedPrefixes = [
    "https://www.figma.com/",
    "https://www.sketch.com/",
    "https://xd.adobe.com/",
    "https://projects.invisionapp.com/",
    "https://marvelapp.com/",
    "https://www.canva.com/",
    "https://app.zeplin.io/",
    "https://framer.com/",
  ];

  const onUrlChange = (e) => {
    const urlValue = e.target.value.trim(); // Trim whitespace
    setFormData({ ...formData, url: urlValue });

    // Validate the URL against allowed prefixes
    const isValidPrefix = allowedPrefixes.some((prefix) =>
      urlValue.startsWith(prefix)
    );

    if (!isValidPrefix && urlValue !== "") {
      setErrors({
        ...errors,
        url: `You can only share URLs from the following apps: Figma, Sketch, Adobe XD, InVision, Marvel, Canva, Zeplin, Framer`,
      });
    } else {
      // If there was an error previously set for the URL field, clear it when corrected
      const newErrors = { ...errors };
      delete newErrors.url;
      setErrors(newErrors);

      const src = extractSrc(urlValue);
      console.log(urlValue);
      console.log(src);
      setPreviewUrl(src);
    }
  };

  useEffect(() => {
    console.log("Current errors:", errors);
    console.log(Object.keys(errors).length);
    console.log(submitDisabled);

    setSubmitDisabled(Object.keys(errors).length > 0);

    if (!data.isAuthenticated) {
      window.location.href = "/login";
    }

    const fetchProfile = async () => {
      try {
        const profileData = await axios.post("/api/users/get-profile", {
          id: data.user.id,
        });
        console.log(profileData.data);
        setProfile(profileData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [data.user.id, errors]);

  // const handleImageChange = (e) => {
  //   // Check if any files are selected
  //   if (e.target.files && e.target.files[0]) {
  //     const file = e.target.files[0];

  //     // Optionally, check if the selected file is an image
  //     if (file.type.startsWith("image/")) {
  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         // Set the image data on successful load
  //         setImage(reader.result);
  //       };

  //       reader.onerror = (error) => {
  //         // Handle errors that occur during the read process
  //         console.error("Error reading file:", error);
  //       };

  //       // Read the file as a data URL (base64 encoded string)
  //       try {
  //         reader.readAsDataURL(file);
  //       } catch (error) {
  //         console.error("Error in reading file:", error);
  //       }
  //     } else {
  //       console.log("Selected file is not an image");
  //     }
  //   } else {
  //     console.log("No file selected");
  //   }
  // };


  const handleImageChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
  
    if (file) {
      const newErrors = { ...errors };
      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  
      // Optionally, add a file size check (e.g., 5MB)
      const maxFileSize = 5 * 1024 * 1024; // 5MB
  
      // Check if the selected file is an image of allowed type
      if (!allowedFileTypes.includes(file.type)) {
        newErrors.image = 'Unsupported file type. Allowed types are JPEG, PNG, GIF, and WEBP.';
      }
      // Check file size
      else if (file.size > maxFileSize) {
        newErrors.image = `File size should not exceed ${maxFileSize / 1024 / 1024}MB.`;
      }
      // If the file passes both checks
      else {
        delete newErrors.image; // Remove any existing error for the image
  
        const reader = new FileReader();
        reader.onloadend = () => {
          // Set the image data on successful load
          setImage(reader.result);
        };
        reader.onerror = () => {
          newErrors.image = 'Error reading file. Please try again.';
        };
        reader.readAsDataURL(file);
      }
  
      // Update the state with any new or removed errors
      setErrors(newErrors);
    } else {
      // Handle the case where no file is selected
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'An image is required',
      }));
    }
  };

  


  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
  
    const newErrors = {};
    // Perform all validations and populate `newErrors` as needed
    if (!formData.title) newErrors.title = "A title is required";
    if (!formData.url) newErrors.url = "A URL is required";
    if (!image) newErrors.image = "An image is required";
  
    setErrors(newErrors); // Update errors state with validation results
  
    if (Object.keys(newErrors).length === 0) {
      // No errors, proceed with form submission
      setSubmitDisabled(false)
      try {
        const res = await axios.post("/api/posts/new-post", {
          title: formData.title,
          base64: image,
          url: formData.url,
          description: formData.description,
          tags: formData.tags,
          user: data.user.id,
          username: profile.username,
          fullName: profile.name,
          profileImage: profile.plan.profileImage,
        });
        console.log("Form submission successful", res.data);
        
        // Redirect to the new post page
        window.location.href = `/post/${res.data._id}`;


      } catch (err) {
        console.error("Error during form submission", err);
        setErrors((prevErrors) => ({
          ...prevErrors,
          post: "Your post was unable to be created, please try again",
        }));
      } finally {
        setSubmitLoading(false); // Reset loading state
      }
    } else {
      // There are validation errors; do not proceed with submission
      setSubmitLoading(false); // Reset loading state since we're not submitting
    }
  };
  

  return (
    <PageLayout activeLink="/new/post">
      <Box overflowY="auto" >
        <PageHeading
          heading="New Design Post"
          subHeading="Share something new with your audience"
        />
        <NewPostForm
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          submitLoading={submitLoading}
          inputRef={inputRef}
          handleImageChange={handleImageChange}
          image={image}
          errors={errors}
          submitDisabled={submitDisabled}
        />
      </Box>
    </PageLayout>
  );
}

export default NewPostDesign;
