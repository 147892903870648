import React, { useEffect, useState } from "react";
import axios from "axios";

import { logoutUser } from "../../actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FeedbackModal from "./FeedbackModal";
import Logo2 from "../../Images/Logo2.png";

import {
  Link,
  Box,
  HStack,
  VStack,
  Button,
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  useDisclosure,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useMediaQuery,
  CloseButton,
  Divider,
  Image,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import {
  HiHome,
  HiCog,
  HiChartBar,
  HiUserCircle,
  HiCash,
  HiClipboardList,
  HiPencil,
  HiChevronDown,
  HiMenu,
  HiChatAlt,
  HiLogin,
} from "react-icons/hi";

const UserProfile = ({ name, profileImage, userData }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onLogoutClick = (e) => {
    e.preventDefault();
    //console.log("inside onLogoutClick");
    dispatch(logoutUser());
  };

  useEffect(() => {
    //console.log(auth);
    if (!auth.isAuthenticated) {
      window.location.href = "/";
    }
  }, [auth]);

  return (
    <Box>
      <Menu>
        <MenuButton
          as={Flex}
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderRadius="4px"
          _hover={{ bg: "gray.100" }}
          w="100%"
        >
          <HStack alignItems="center">
            <Avatar size="sm" name={name} src={profileImage} />
            <IconButton
              icon={<HiChevronDown />}
              variant="outlined"
              aria-label="Options"
              size="sm" // You can adjust the size as needed
              isRound // Makes the button round, remove this line if you don't want it round
              _hover={{ bg: "transparent" }} // To keep the button transparent on hover
              _active={{ bg: "transparent" }} // To keep the button transparent when clicked
            />
          </HStack>
        </MenuButton>
        <MenuList>
          {userData &&
            userData.plan &&
            userData.plan.monthly !== 0 &&
            userData.plan.yearly !== 0 && (
              <span>
                <Link style={{ textDecoration: 'none' }} href="/edit-membership-details" passHref>
                  <MenuItem gap={2} py={2} as="a"> <HiUserCircle size="1.5em" /> Channel Settings</MenuItem>
                </Link>

                <Link style={{ textDecoration: 'none' }} href="/payout" passHref>
                  <MenuItem gap={2} py={2} as="a"> <HiCash size="1.5em" /> Payout</MenuItem>
                </Link>
              </span>
            )}

          <Link style={{ textDecoration: 'none' }} href="/edit-account-settings" passHref>
            <MenuItem gap={2} py={2} as="a"> <HiCog size="1.5em" /> Account Settings</MenuItem>
          </Link>

          <Divider p={0} my={2} />

          <Box w="full">
          <Button gap={2} bg="#ffffff" borderRadius={0} w="full" onClick={onOpen}> <HiChatAlt size="1.5em" /> Report Bug/Feedback</Button>
          <FeedbackModal isOpen={isOpen} onClose={onClose} />
          </Box>

        <Divider p={0} my={2} />

          <MenuItem gap={2} py={2} onClick={onLogoutClick}> <HiLogin size="1.5em" /> Logout</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const SideNav = ({ activeLink }) => {
  const [userData, setUserData] = useState(null);
  const [link, setLink] = useState(null);
  const [showDashboardLink, setShowDashboardLink] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useSelector((state) => state.auth);
  //console.log(data);
  const id = data.user.id;

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onLogoutClick = (e) => {
    e.preventDefault();
    //console.log("inside onLogoutClick");
    dispatch(logoutUser());
  };

  const [isLargeScreen] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    //console.log(data);

    // if (!auth.isAuthenticated) {
    //   window.location.href = "/";
    // }

    axios
      .post("/api/users/findbyID", { id: id })
      .then((res) => {
        //console.log(res.data);
        setUserData(res.data);
        setLink(`/u/${res.data.username}`);

        if (res.data.username) {
          setShowDashboardLink(true);
        }
      })
      .catch((err) => console.error(err));
  }, [id, auth]);

  const hoverStyles = {
    ...linkStyle,
    color: "#000", // black text
  };

  const linkStyle = {
    padding: "8px",
    marginRight: "16px",
    fontWeight: "500",
    color: "#989898",
    textDecoration: "none",
    _hover: hoverStyles,
    height: "45px",
  };

  const activeLinkStyle = {
    ...linkStyle,
    textDecoration: "none",
    borderBottom: "4px solid #0A090B", // Apply a border only at the bottom with 1px thickness and blue color
    color: "#0A090B",
    _hover: hoverStyles,
  };

  const iconStyle = {
    color: "#989898",
    _hover: {
      color: "#0A090B", // black icon
    },
  };

  const activeIconStyle = {
    ...iconStyle,
    color: "#0A090B",
    _hover: {
      color: "#0A090B", // black icon
    },
  };

  const hoverStyle = {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
    fontWeight: "600",
    textDecoration: "none",
    borderBottom: "4px solid #737279", // Apply a border only at the bottom with 1px thickness and blue color
    color: "#0A090B",
  };

  const navItems = [
    {
      href: "/home",
      label: "Home",
      icon: <HiHome size="1.5em" />,
      show: showDashboardLink,

    },
    {
      href: "/dashboard",
      label: "Dashboard",
      icon: <HiChartBar size="1.5em" />,
      show: showDashboardLink,
    },
    {
      href: link,
      label: "Channel",
      icon: <HiUserCircle size="1.5em" />,
      show: showDashboardLink,
    },
    {
      href: "/creator/posts",
      label: "All Posts",
      icon: <HiClipboardList size="1.5em" />,
      show: showDashboardLink,
    },
    {
      href: "/new/post",
      label: "New Post",
      icon: <HiPencil size="1.5em" />,
      show: showDashboardLink,
    },
  ];

  return (
    <Box w="100%" h="100%">
      <Flex
      flexDirection="row"
        bg="#ffffff"
        w="100%"
        justifyContent="space-between" // Use space-around for equal spacing
        justifyItems="space-between" // Use space-around for equal spacing
        alignItems="center"
        px={0}
        py={4}
        m={0}
      >
        {/* Container 1 (Logo) */}
        <Box>
        <Button p={0} style={{textDecoration: "none", background: "none"}} as={Link} href="/home">
          <Image
            objectFit="cover"
            src={Logo2}
            alt="UI Marketplace"
            w="250px"
          />
          </Button>
          </Box>



        {/* Container 2 (Mobile Nav) */}
        
          {!isLargeScreen && (
            <Box>
            <IconButton
              aria-label="Open Menu"
              icon={<HiMenu />}
              onClick={onOpen}
              variant="ghost"
              size="xl"
            />
            </Box>
          )}
        

        {/* Container 3 (Desktop Nav) */}
        <Box>
          {isLargeScreen && (
            <HStack spacing={8} // Add spacing to the desktop nav HStack
              alignItems="center" // Align items to the center
              display="flex" justifyContent="center"
            >
              {/* Nav Links */}
              <HStack display="flex" justifyContent="center" spacing={4}>
                {navItems.map(
                  (item) =>
                    item.show && (
                      <Link
                        key={item.href}
                        href={item.href}
                        sx={
                          activeLink === item.href ? activeLinkStyle : linkStyle
                        }
                      >
                        <HStack
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          {item.icon}
                          <Text margin={0} fontSize="l">
                            {item.label}
                          </Text>
                        </HStack>
                      </Link>
                    )
                )}

              </HStack>

            </HStack>
          )}
        </Box>

        {/* User Profile */}
        <Box>
        {isLargeScreen && (
        <UserProfile
        name={userData?.name}
        profileImage={userData?.plan.profileImage}
        userData={userData}
      />
        )}
      </Box>

      </Flex>

      {/* Mobile Drawer */}
      {!isLargeScreen && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <HStack justifyContent="space-between">
              <Box>
          <Image
            objectFit="cover"
            src={Logo2}
            alt="UI Marketplace"
            w="150px"
          />
          </Box>
                <CloseButton onClick={onClose} />
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4}>
                {navItems.map(
                  (item) =>
                    item.show && (
                      <Link
                        key={item.href}
                        href={item.href}
                        sx={
                          activeLink === item.href ? activeLinkStyle : linkStyle
                        }
                      >
                        <HStack
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          {item.icon}
                          <Text margin={0} fontSize="l">
                            {item.label}
                          </Text>
                        </HStack>
                      </Link>
                    )
                )}
              </VStack>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              {userData && (
                <UserProfile
                  name={userData.name}
                  profileImage={userData.plan.profileImage}
                  userData={userData}
                />
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

SideNav.propTypes = {
  logoutUser: PropTypes.func,
  auth: PropTypes.object,
};

export default SideNav;
