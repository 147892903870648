import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SideNav from "../components/nav/SideNav";
import ConnectedAccountLinks from "../components/Stripe/ConnectedAccountLinks";
import GetBalance from "../components/Stripe/GetBalance";
import PageLayout from "../components/general/PageLayout";

import ConnectPaypal from "../components/Paypal/ConnectPaypal";
import { useSelector } from "react-redux";

import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { Container, Row, Col } from "react-bootstrap";
import PageHeading from "../components/general/PageHeading";

import "../index.css";

import { Flex, Box, Card } from "@chakra-ui/react";

function Payout() {
  const [balance, setBalance] = useState(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    // Fetch balance data and update state with setBalance
  }, []);

  return (
    <PageLayout>
      <Box p={2} flex="1" overflowY="auto">
        {balance && <div>{balance}</div>}
        <ConnectedAccountLinks />
      </Box>
    </PageLayout>
  );
}

export default Payout;
