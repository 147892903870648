import React from 'react';
import { Center, VStack, Box, Button, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FileMinus } from 'react-feather';

function TableEmptyState() {
  return (
    <Center py={16}>
      <VStack spacing={1}>
        <Box mb={2}>
          <FileMinus size={48} />
        </Box>
        <Text
          textAlign="center"
          color="zinc.950"
          fontSize="2xl"
          fontWeight="semibold"
          lineHeight="shorter"
          fontFamily="Inter"
          mb={0}
        >
          No Subscriptions
        </Text>
        <Text textAlign="center"  color="neutral.800" fontSize="lg" fontWeight="normal" lineHeight="tall">
          You don't have any subscribers yet.
        </Text>
        <Button
          as={Link}
          to="/new/post"
          borderRadius="md"
          variant="largeButton"
        >
          Share your work
        </Button>

      </VStack>
    </Center>
  );
}

export default TableEmptyState;
