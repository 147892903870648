import {
  Flex,
  HStack,
  VStack,
  Avatar,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PageLayout from "../general/PageLayout";
import axios from "axios";

import DribbbleUserShots from "./DribbbleUserShots";

const DribbbleUpload = () => {
  const [userData, setUserData] = useState({});
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const handleCallback = async () => {
      const token =
        new URLSearchParams(window.location.search)?.get("access_token") ?? "";

      // Get User Details
      try {
        const getUserID = await axios.post("/api/posts/dribbble-getID", {
          token: token,
        });
        console.log(getUserID.data);
        setUserData(getUserID.data);
      } catch (error) {
        console.error(error);
      }

      // Get User Shots
      try {
        const getUserShots = await axios.post("/api/posts/dribbble-getPosts", {
          token: token,
        });
        console.log(getUserShots.data);
        setPosts(getUserShots.data);
      } catch (error) {
        console.error(error);
      }
    };
    handleCallback();
  }, [setUserData, setPosts]);

  return (
    <PageLayout>
      <Box h="full">
          {userData?.links && (
            <HStack py={4} mb={8}>
              <Box>
                <Avatar
                  name={userData.name}
                  src={userData.avatar_url}
                  size="xl"
                  mr={4}
                />
              </Box>

              <VStack spacing={1} align="start">
                <Heading mb={0} size="md"> {userData.name} </Heading>
                <Text fontWeight="bold" mb={0} color="gray.500">
                  {userData.login}
                </Text>
                <Text mb={2} color="gray.500">
                  {userData.location}
                </Text>
              </VStack>
            </HStack>
          )}

          {posts?.length > 0 && <DribbbleUserShots shots={posts} />}
      </Box>
    </PageLayout>
  );
};

export default DribbbleUpload;
