import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from 'react-redux';
import { Alert, InputGroup, ButtonGroup, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

import SideNav from "../components/nav/SideNav";
import MembershipStatusButton from "../components/MembershipSettings/MembershipStatusButton";

import { Stack, Flex, StackDivider, HStack, Link, Avatar, Center, Badge, Box, Text, Heading, Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'


const MembershipDetails = (SuccessfulBanner) => {

  // Initialize state for form data
  const [formData, setFormData] = useState({});
  //const [SuccessfulBanner, setSuccessfulBanner] = useState(false);

  const auth = useSelector(state => state.auth);
  console.log(auth);
  console.log(auth.user.stripeConnectedAccountID);



  // Show exsiting data in the form
  useEffect(() => {

    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    // Make an axios GET request to retrieve the user's current profile information
    axios.post('/api/users/get-profile', { id: auth.user.id })
      .then(response => {
        console.log(response);
        // Prepopulate the form with the user's current profile information
        setFormData({
          isActive: response.data.plan.isActive,
          userId: auth.user.id,
          name: response.data.name,
          profileImage: response.data.plan.profileImage,
          contactEmail: response.data.plan.contactEmail,
          username: response.data.username,
          bio: response.data.plan.bio,
          portfolioLink: response.data.plan.portfolioLink,
          monthly: response.data.plan.monthly,
          yearly: response.data.plan.yearly,
        }, () => console.log(formData));

      })
      .catch(error => {
        console.error(error);
      });

  }, []);


  return (


    <Flex h='100vh' px={28} py={16}>

      <Box px={2}  w='240px'>
        <SideNav activeLink='/edit-membership-details'/>
      </Box>


      <Box p={2} flex='1' overflowY="auto"  py={8} px={24}>
        
          <Heading size='md'>Subscription Settings</Heading>
      
        <Stack spacing='4'>

          <Box>
            <Link href="/edit-membership-details" pt='2' fontSize='sm'>Edit Membership </Link>
          </Box>


          <Flex>
            <Heading size='xs' textTransform='uppercase'> Status </Heading>
            <Text pt='2' fontSize='sm'> {formData.isActive ? (<Badge colorScheme='green'>Active</Badge>) : (<Badge colorScheme='red'>Inactive</Badge>)} </Text>
          </Flex>

          <HStack>

            <Avatar name={formData.name} src={formData.profileImage} />

            <Box>
              <Text>{formData.name}</Text>
              <Text>{formData.username}</Text>
            </Box>

          </HStack>


          <Box>
            <Heading size='xs' textTransform='uppercase'> Biography </Heading>
            <Text pt='2' fontSize='sm'> {formData.bio} </Text>
          </Box>

          <Box>
            <Heading size='xs' textTransform='uppercase'> Portfolio Link </Heading>
            <Text pt='2' fontSize='sm'> {formData.portfolioLink} </Text>
          </Box>

          <Box>
            <Heading size='xs' textTransform='uppercase'> Contact Email </Heading>
            <Text pt='2' fontSize='sm'> {formData.contactEmail} </Text>
          </Box>

          <HStack>

            <Box>
              <Heading size='xs' textTransform='uppercase'> Monthly </Heading>
              <Text pt='2' fontSize='sm'> ${formData.monthly} </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='uppercase'> Yearly </Heading>
              <Text pt='2' fontSize='sm'> ${formData.yearly} </Text>
            </Box>

          </HStack>

          <MembershipStatusButton currentActiveStatus={formData.isActive} id={auth.user.id} />


        </Stack>

      </Box>

    </Flex>





  );
}

export default MembershipDetails;
