
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { extendTheme } from "@chakra-ui/react";

import { logoutUser } from "../../actions/authActions";
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TogglePageStatus from "../MembershipSettings/TogglePageStatus";

import {
  Flex,
  Alert,
  AlertIcon,
  Card,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner
  
} from '@chakra-ui/react'

import SideNav from "../nav/SideNav";

import { useNavigate } from 'react-router-dom';
import PageHeading from "../general/PageHeading";
import CancelSubscriptionList from './CancelSubscriptionList';




const EditAccountSettings = () => {

  // Initialize state for form data
  const [formData, setFormData] = useState({});

  const [allSubscriptions, setAllSubscriptions] = useState([])

  //History routing
  const navigate = useNavigate();

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [deletionError, setDeletionError] = useState({
    yourSubscriptions: '',
    email: '',
    channel: '',
    server: '',
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const [SuccessfulBanner, setSuccessfulBanner] = useState(false);
  const [ErrorBanner, setErrorBanner] = useState(false);

  const [deleteEmail, setDeleteEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [isOnboardingOneComplete, setIsOnboardingOneComplete] = useState(false);
  const [isOnboardingTwoComplete, setIsOnboardingTwoComplete] = useState(false);
  const [isOnboardingThreeComplete, setIsOnboardingThreeComplete] = useState(false);
  const [completeness, setCompleteness] = useState(0);


  const onLogoutClick = () => {
    //e.preventDefault();
    ////console.log("inside onLogoutClick")
    dispatch(logoutUser());
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleUpdateDeletionError = (hasActiveNonCancellable) => {
    if (hasActiveNonCancellable) {
    setDeletionError(prev => ({
      ...prev,
      yourSubscriptions: "You have at least 1 active subscription; please cancel them before deleting your account."
    }));
    } else {
        setDeletionError(prev => ({
          ...prev,
          yourSubscriptions: ""
        }));
    }
};
  
  



  // Show existing data in the form
  const fetchData = async () => {
    try {
      const response = await axios.post('/api/users/get-profile', { id: auth.user.id });
      //console.log(response.data);
      //console.log(response.data.plan.isActive);

      setFormData({
        userId: auth.user.id,
        name: response.data.name,
        email: response.data.email,
        profileImage: response.data.plan.profileImage,
        isActive: response.data.plan.isActive,
        stripeID: response.data.stripeConnectedAccountID,
        username:response.data.username,
      });

      // Set Status of Onboarding One
      if (
        response.data.username != null ||
        response.data.username != undefined
      ) {
        setIsOnboardingOneComplete(true);
      }

      // Set Status of Onboarding Two
      if (
        response.data.stripeConnectedAccountID != null ||
        response.data.stripeConnectedAccountID != undefined
      ) {
        setIsOnboardingTwoComplete(true);
      }

      // Set Status of Onboarding Three
      if (
        response.data.plan.stripeMonthlyPriceLink != null &&
        response.data.plan.stripeYearlyPriceLink != null &&
        response.data.plan.monthly >= 5 &&
        response.data.plan.yearly >= 5
      ) {
        setIsOnboardingThreeComplete(true);
      } else {
        setIsOnboardingThreeComplete(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserProfile = async () => {
    try {
      const response = await axios.post('/api/users/get-profile', { id: auth.user.id });
      setFormData({
        userId: auth.user.id,
        name: response.data.name,
        email: response.data.email,
        profileImage: response.data.plan.profileImage,
        isActive: response.data.plan.isActive,
        stripeID: response.data.stripeConnectedAccountID,
        username:response.data.username,
      });
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error; // Optionally, you can throw the error to handle it at the calling side
    }
  };

  useEffect(() => {
    if (auth.user && auth.user.id) {
      fetchUserProfile();
    }

    console.log()
  }, [auth.user]);




  useEffect(() => {
    // Calculate completeness based on boolean values
    const newCompleteness =
      (isOnboardingOneComplete ? 25 : 0) +
      (isOnboardingTwoComplete ? 25 : 0) +
      (isOnboardingThreeComplete ? 25 : 0) +
      (formData.plan?.isActive ? 25 : 0);

    setCompleteness(newCompleteness);
    console.log(formData)
  }, [
    isOnboardingOneComplete,
    isOnboardingTwoComplete,
    isOnboardingThreeComplete,
    formData.plan?.isActive,
  ]);

    // Determine if any onboarding step is incomplete
    const isAnyOnboardingIncomplete = !isOnboardingOneComplete || !isOnboardingTwoComplete || !isOnboardingThreeComplete;





  // Function to handle input changes and update form data in state
  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }



  // Function to handle form submission
  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitLoading(true)
    setErrorBanner(false)
    //console.log(formData)



    if (Object.values(formData).some(val => (val == null || val == ''))) {
      setError("Some fields are null");
      setSuccessfulBanner(false)
      setSubmitLoading(false)
      return;
    }

    setError('');
    setErrorBanner(false)


    // Make a POST request to update the profile information
    try {
      const response = await axios.post('/api/users/update-account', formData);
      setSubmitLoading(false)
      setSuccessfulBanner(true);
      //console.log(response.data);
      fetchData();

      setTimeout(() => {
        setSuccessfulBanner(false);
      }, 5000);
    } catch (error) {
      console.error(error);
    }

  }

  const handleDeleteAccount = async () => {

    //console.log(formData)
    setShowSpinner(true); // Show the spinner

    //     if (deletionError) {
    //     //console.log(deletionError); // Or handle the display of this message appropriately
    //     return;
    // }
  
    try {
      // Fetch the user profile information
      const response = await axios.post('/api/users/get-profile', { id: auth.user.id });
  
      // Check if the account is active
      if (response.data.plan.isActive) {
        setDeletionError(prev => ({
          ...prev,
          channel: "Channel is still active. Please deactivate your channel before deletion."
        }));
        return; // Exit the function early as the condition is not met
      }
  
      // Check if the inputted email matches the account email
      if (response.data.email !== deleteEmail) {
        setDeletionError(prev => ({
          ...prev,
          email: "Email does not match the account email."
        }));
        
        return; // Exit the function early as the condition is not met
      }
  
      //Check if this user is a creator 
      //console.log(response.data.stripeConnectedAccountID)
      if(response.data.stripeConnectedAccountID != undefined){
      // List all subscribers
      const subscriptionsResponse = await axios.post('/api/subscription/list-active-subscriptions', { connectedAccountId: formData.stripeID });
      const allSubscriptions = subscriptionsResponse.data;
      
      // Check for active subscribers
      if(subscriptionsResponse.data.length > 0){
        // Cancel all subscriptions
        const cancelPromises = allSubscriptions.map(subscription =>
          axios.post('/api/subscription/cancel-subscription', { subscriptionId: subscription.id, connectedAccountId: formData.stripeID, username:formData.username })
        );
    
        // Wait for all cancellations to process
        await Promise.all(cancelPromises);
        //console.log("All active subscribers are cancelled");
    
      } else {
        //console.log("This user has no active subscribers")
      }
    } else{
      //console.log("This user is not a creator")
    }
  
      // Proceed with account deletion
      await axios.delete(`/api/users/delete-user/${auth.user.id}`);
      //console.log("Account deleted. Logging out...");
  
      // Handle post-deletion logic, such as logging out the user
      onLogoutClick(); // Adjust as per your logout logic

    } catch (error) {
      console.error('Error during account deletion process:', error);
      setDeletionError(prev => ({
        ...prev,
        server: "Error deleting account. Please contact support"
      }));
      
    } finally {
      setShowSpinner(false); // Ensure spinner is hidden after all operations
    }
  };
  
  
  
  




  return (
    <Flex borderWidth="1px" borderRadius="md">

      <Box w='500px'>
        {SuccessfulBanner && (
          <Alert status="success">
            <AlertIcon />
            Form submitted successfully!
          </Alert>
        )}

        {error && (
          <Alert status="error">
            <AlertIcon />
            An error occurred: {error}
          </Alert>
        )}



          <form onSubmit={handleSubmit}>
            <Box
              py={{ base: '4', sm: '8' }}
              px={{ base: '4', sm: '8' }}
              w='full'>

              <Stack spacing={4} w='full'>
                
                <FormControl>
                  <FormLabel htmlFor="email">Full Name</FormLabel>
                  {/* Name input */}
                  <Input
                    variant='input'
                    size='md'
                    placeholder='Enter your full name'
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name || ''} 
                    onChange={handleChange}

                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  {/* Email input */}
                  <Input
                    variant="input"
                    size='md'
                    placeholder='Enter your email address'
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email || ''} 
                    onChange={handleChange} />
                </FormControl>

                {/* Submit button */}
                <Button
                  type="submit"
                  isLoading={submitLoading}
                  loadingText='Updating'
                  spinnerPlacement='start'
                  variant="primaryButton"
                >
                  Update Settings
                </Button>

              </Stack>
            
            </Box>
          </form>

          <Box py={{ base: '4', sm: '8' }} px={{ base: '4', sm: '8' }} w='full'>
            <Divider mb={4} />
            <PageHeading heading='Delete Account' subHeading='Deleting your account will erase all your personal details and data' />

            <Button colorScheme='red' onClick={handleOpenModal}>Delete Account</Button>

            <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Delete Account</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                {showSpinner ? (
                  <Box textAlign="center">
                    <Text fontWeight="bold" mb={2}>We're sad to see you go!</Text>
                    <Text mb={4}>Hang tight while we delete your account</Text>
                    <Spinner   
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl' />
                  </Box>
                ) : (
                    <Box>
                    {auth.user.connectedAccountId != null ?(
                    <Box>
                    {formData && (
                      <>
                      <TogglePageStatus
                        currentActiveStatus={formData.isActive}
                        id={formData.userId}
                        disabled={isAnyOnboardingIncomplete} // Pass disabled prop based on the onboarding completeness
                        stripeID={formData.stripeID}
                      />
                      <Text mb={4}>{formData.isActive}</Text>
                      {deletionError.channel && <Text color="red">{deletionError.channel}</Text>}

                      </>
                    )}
                    </Box>
                    ): <Box p={0} m={0}></Box>}

                  

                  <CancelSubscriptionList  />
                  {deletionError.yourSubscriptions && <Text color="red">{deletionError.yourSubscriptions}</Text>}


                    <Text mb={4}>Confirm your account deletion by typing in your email address:</Text>
                    <FormControl isRequired>
                      <FormLabel>Email Address</FormLabel>
                      <Input type='email' value={deleteEmail} onChange={(e) => setDeleteEmail(e.target.value)} />
                    </FormControl>
                    {deletionError.email && <Text color="red">{deletionError.email}</Text>}

                    </Box>
                )}

                {deletionError.server && <Text color="red">{deletionError.server}</Text>}

                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='red' onClick={handleDeleteAccount}>Delete Account</Button>
                  <Button variant='ghost' ml={4} onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

          </Box>





        </Box>

    </Flex>

  );
}

EditAccountSettings.propTypes = {
  logoutUser: PropTypes.func,
  auth: PropTypes.object,
};

export default EditAccountSettings;
