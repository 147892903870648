import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useStripeAccountStatus = () => {
  const auth = useSelector((state) => state.auth);
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [payoutsEnabled, setPayoutsEnabled] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [requirementsDue, setRequirementsDue] = useState(false);

  useEffect(() => {
    const fetchStripeAccountDetails = async () => {
      try {
        setProfileError(false);
        setStripeError(false);

        // Fetch user profile
        const profileResponse = await axios.post("/api/users/get-profile", {
          id: auth.user.id,
        });
        const stripeConnectedAccountID =
          profileResponse.data.stripeConnectedAccountID;

        if (stripeConnectedAccountID) {
          // Fetch Stripe account details
          const accountDetailsResponse = await axios.post(
            "/api/connectedAccounts/accountDetails",
            { id: stripeConnectedAccountID }
          );

          setChargesEnabled(accountDetailsResponse.data.charges_enabled);
          setPayoutsEnabled(accountDetailsResponse.data.payouts_enabled);
        }
      } catch (error) {
        console.error(error);
        setProfileError(true);
        setStripeError(true);
      }
    };

    if (auth.isAuthenticated) {
      fetchStripeAccountDetails();
    }
  }, [auth]);

  return { chargesEnabled, payoutsEnabled, profileError, stripeError, requirementsDue };
};

export default useStripeAccountStatus;