import React, { useState } from "react";
import axios from "axios";
import { Flex, Image,Box, Button, Input,Link, Text, VStack, useToast,  } from "@chakra-ui/react";
import {ArrowForwardIcon } from '@chakra-ui/icons'


import Logo2 from "../Images/Logo2.png";

const ResetPassword = () => {
  const [pin, setPin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pin") setPin(value);
    if (name === "password") setPassword(value);
    setError(""); // Clear previous error on input change
  };

  const newPassword = async () => {
    try {
      const response = await axios.post("/api/users/resetPassword", {
        pin,
        password,
      });
      setConfirmationMessage("Password Successfully Changed");
      setError("");
      toast({
        title: "Password Updated",
        description: "Your password has been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      setError("This pin doesn't match our records. Password reset failed.");
      toast({
        title: "Error",
        description: "This pin doesn't match our records.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      direction="column"
      w="full"
      minH="100vh"
      align="center"
      justify="center"
      p={6}
      bg="gray.50"
    >
      <Flex w="400px" p="32px" bg="white" boxShadow="xl" borderRadius="lg">
        <VStack display="flex" alignItems="start" w="full">
          <Button
            mb={4}
            p={0}
            style={{ textDecoration: "none", background: "none" }}
            as={Link}
            href="/"
          >
            <Image objectFit="cover" src={Logo2} alt="UI Marketplace" />
          </Button>
  
          <Text mb={2} fontSize="18px" fontWeight="semibold">
            Password Reset
          </Text>
  

  
          <Box w="full">
            <Text fontWeight="medium" mb={2}>Pin</Text>
            <Input
              type="text"
              name="pin"
              placeholder="Enter your pin"
              value={pin}
              onChange={handleChange}
              isInvalid={!!error}
              autoComplete="off" // Add this line
            />
          </Box>
  
          <Box w="full" mt={4}>
            <Text mb={2} fontWeight="medium">New Password</Text>
            <Input
              type="password"
              name="password"
              placeholder="Enter your new password"
              value={password}
              onChange={handleChange}
              isInvalid={!!error}
              autoComplete="new-password" // Prevent browser autofill
            />
          </Box>
  
          <Button
            w="full"
            variant="primaryButton"
            onClick={newPassword}
            mt={4}
            isDisabled={!pin || !password}
          >
            Change Password
          </Button>
  
          {confirmationMessage && (
            <Text mt={4} fontSize="sm" fontWeight="medium" color="green.700" textAlign="center">
              {confirmationMessage}. {" "}
              <Link color="blue.500" href="/login">
                Login in <ArrowForwardIcon />
              </Link>
              
            </Text>
          )}
  
          {error && (
            <Text mt={2} fontSize="sm" color="red.600" textAlign="center">
              {error}
            </Text>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};

export default ResetPassword;
