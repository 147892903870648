import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Link,
  VStack,
  useBreakpointValue,
  FormControl,
  FormErrorMessage,
  Image,
} from "@chakra-ui/react";

import { FaGoogle } from "react-icons/fa";
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import Logo2 from "../../Images/Logo2.png";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Register = ({ registerUser, auth, errors, history }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [session, setSession] = useState(null);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/home");
    }

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        // Optionally, you can handle additional user setup here
        // navigate("/home"); // Assuming you redirect to '/home' after successful registration
      }
    });
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    if (errors) {
    }
  }, [errors]);

  const onChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value.toLowerCase());
    } else {
      switch (e.target.id) {
        case "name":
          setName(e.target.value);
          break;
        case "username":
          setUsername(e.target.value);
          break;
        case "password":
          setPassword(e.target.value);
          break;
        case "password2":
          setPassword2(e.target.value);
          break;
        default:
          break;
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      name,
      username,
      email,
      password,
      password2,
    };
    registerUser(newUser, navigate); // Use navigate instead of history
  };

  const formWidth = useBreakpointValue({ base: "full", md: "md" });

  if (session) {
    return (
      <div>
        Logged in!
        <button onClick={() => supabase.auth.signOut()}>Sign out</button>
      </div>
    );
  }

  return (
    <Flex
      direction="column"
      w="full"
      minH="100vh"
      align="center"
      justify="center"
      p={6}
      bg="gray.50"
    >
      <Flex w="400px" p="32px" bg="white" boxShadow="xl" borderRadius="lg">
        {/* Form Container */}
        <VStack display="flex" alignItems="start" w="full">
          <VStack spacing="0" align="start" mb={2}>

            <Button
              mb={8}
              p={0}
              style={{ textDecoration: "none", background: "none" }}
              as={Link}
              href="/"
            >
              <Image objectFit="cover" src={Logo2} alt="UI Marketplace" />
            </Button>


            <Text mb={0} fontSize="2xl" fontWeight="semibold">
              Create your account
            </Text>
            <Text mb={0} fontSize="md" color="gray.600">
              Enter the fields below to get started
            </Text>
          </VStack>
          <Box as="form" w="full" onSubmit={onSubmit} noValidate>
            <VStack align="flex-start" spacing={4}>
              <Box w="full">
                <FormControl isInvalid={errors.name}>
                  <Input
                    id="name"
                    type="text"
                    value={name}
                    onChange={onChange}
                    placeholder="Full name"
                    autoComplete="off" // Add this line
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box w="full">
                <FormControl isInvalid={errors.email}>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Email"
                    autoComplete="off" // Add this line
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box w="full">
                <FormControl isInvalid={errors.password}>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={onChange}
                    placeholder="Password"
                    autoComplete="new-password" // Prevent browser autofill
                  />
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box w="full">
                <FormControl isInvalid={errors.password2}>
                  <Input
                    id="password2"
                    type="password"
                    value={password2}
                    onChange={onChange}
                    placeholder="Confirm Password"
                    autoComplete="new-password" // Prevent browser autofill
                  />
                  <FormErrorMessage>{errors.password2}</FormErrorMessage>
                </FormControl>
              </Box>

              <Button w="full" variant="primaryButton" type="submit">
                Create Account
              </Button>
            </VStack>
          </Box>

          {/* Footer */}
          <Flex flexDirection="column" justify="flex-start" align="flex-start" mt="2">
            <Text fontSize="sm" color="gray.600">
              Already have an account?{" "}
              <Link as={RouterLink} to="/login" color="blue.500">
                Log in
              </Link>
            </Text>

            <Text fontSize="sm" color="gray.600">
            By continuing, you agree to UIMarketplace's{" "}
              <Link as={RouterLink} to="/terms" color="blue.500">
                Terms and Conditions{" "}
              </Link>
              and {" "}
              <Link as={RouterLink} to="/privacy" color="blue.500">
                Privacy Policy
              </Link>
            </Text>
          </Flex>
        </VStack>

        {/* Image Container 
        <Box w="full" h="full" display={{ base: "none", md: "block" }}>
          <Image
            src="https://cdn.dribbble.com/userupload/12477166/file/original-8d9d0c87cc353ccfd053afaad5b9264c.png?resize=752x"
            alt="Login Image"
            objectFit="cover"
            h="full"
            w="full"
            borderRadius="lg"
          />
        </Box>
        */}
      </Flex>
    </Flex>
  );
};

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Register);
