import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Avatar, Text, Alert, AlertIcon, AlertTitle, AlertDescription, Link, HStack } from '@chakra-ui/react';
import Moment from 'react-moment';
import NoSubscriptions from './NoSubscriptions';

const CustomerMembershipSettings = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const [error, setError] = useState('');

  const auth = useSelector(state => state.auth);

  const fetchSubscriptions = async () => {
    setIsLoading(true);
    setError('');

    try {
        const { data: mongoSubs } = await axios.post('/api/subscription/findByCustomerEmail', {
            customerEmail: auth.user.email
        });

        const subscriptionDetails = await Promise.all(mongoSubs.map(async (subscription) => {
            let subscriptionInfo, creatorInfo, stripeSession;

            try {
                const subscriptionInfoResponse = await axios.post('/api/subscription/getInfo', {
                    id: subscription.subscriptionId,
                    connectedAccountId: subscription.subscribedAccountConnectedAccountId
                });
                subscriptionInfo = subscriptionInfoResponse.data;

                const creatorInfoResponse = await axios.post('/api/users/findbyStripeConnectedAccountID', {
                    stripeConnectedAccountID: subscription.subscribedAccountConnectedAccountId
                });
                creatorInfo = creatorInfoResponse.data;

                const stripeSessionResponse = await axios.post('/api/subscription/create-billing-portal-session', {
                    customerId: subscription.customerId,
                    subscribedAccountConnectedAccountId: subscription.subscribedAccountConnectedAccountId
                });
                stripeSession = stripeSessionResponse.data;
            } catch (error) {
                console.error("Failed to fetch subscription data:", error);
                setError('An error occurred while fetching subscriptions. Please try again later or contact support.');
                setIsLoading(false);
            }

            return {
                id: subscriptionInfo.id,
                status: subscriptionInfo.status,
                amount: subscriptionInfo.items.data[0].price.unit_amount,
                interval: subscriptionInfo.plan.interval,
                start_date: subscriptionInfo.start_date,
                sessionURL: stripeSession.url,
                creatorName: creatorInfo.name,
                cancel_at: subscriptionInfo.cancel_at,
                cancel_at_period_end: subscriptionInfo.cancel_at_period_end
            };
        }));

        const uniqueSubscriptions = Array.from(new Map(subscriptionDetails.map(sub => [sub.id, sub])).values());

        setSubscriptions(uniqueSubscriptions);
        setIsLoading(false);
    } catch (error) {
        console.error("An error occurred:", error);
        setError('An error occurred while fetching subscriptions. Please try again later or contact support.');
        setIsLoading(false);
    }
};

useEffect(() => {
    fetchSubscriptions();
}, [auth.user.email]);

if (isLoading) {
    return <Box>Loading subscriptions...</Box>;
}

if (error) {
    return (
        <Alert status="error">
            <AlertIcon />
            <AlertTitle>Error:</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    );
}

if (!subscriptions.length) {
    return <NoSubscriptions />;
}

return (
    <Flex borderWidth="1px" borderRadius="md" direction="column" w="full">
        <Box w="full">
            <Box py={{ base: '0', sm: '4' }} px={{ base: '2', sm: '4' }} w="full">
                <Table borderWidth="1px" borderRadius="md">
                    <Thead>
                        <Tr>
                            <Th>User</Th>
                            <Th>Status</Th>
                            <Th>Membership</Th>
                            <Th>Subscribed</Th>
                            <Th>Will Cancel On</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions.map(subscription => (
                            <Tr w="full" key={subscription.id}>
                                <Td>
                                    <HStack>
                                        <Avatar fontSize="md" name={subscription.creatorName} size="sm" />
                                        <Text ml={2} m={0} color="#222124">{subscription.creatorName}</Text>
                                    </HStack>
                                </Td>
                                <Td>
                                    <Text color="#222124" as="b">{subscription.status}</Text>
                                </Td>
                                <Td>${(subscription.amount / 100).toFixed(2)} / {subscription.interval}</Td>
                                <Td><Moment fromNow>{subscription.start_date * 1000}</Moment></Td>
                                <Td>{subscription.cancel_at_period_end ? <Moment format="MMM D, YYYY h:mm A">{subscription.cancel_at * 1000}</Moment> : 'N/A'}</Td>
                                <Td>
                                    <Link href={subscription.sessionURL} isExternal>Manage Subscription</Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    </Flex>
);
};

export default CustomerMembershipSettings;
