import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { Center, Spinner } from "@chakra-ui/react";

import {
  ButtonGroup,
  Button,
  Form,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

const PreviewPostModal = ({ match }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backLink, setBackLink] = useState(null);

  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useState(isOpen);

  useEffect(() => {
    //console.log(id);

    axios
      .post("/api/posts/get-post", { id: id })
      .then((res) => {
        //console.log(res);
        setData(res.data);
        setBackLink(`/u/${res.data.username}`);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [id, setData]);

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {data && (
        <div>
          <Row>
            <Col>
              <Col>
                <a href={backLink}>Back to Profile</a>
              </Col>

              <Row>
                <Avatar name={data.username} src="https://bit.ly/broken-link" />
                <h3>{data.username}</h3>
              </Row>

              <Col>
                <img
                  style={{ width: "650px", height: "450px" }}
                  src={data.mediaLink}
                />
                <h2>{data.title}</h2>
                <p>{data.tags}</p>
                <p>{data.tags}</p>
              </Col>
            </Col>

            <Col></Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PreviewPostModal;
