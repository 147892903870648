import { Badge, Box, Flex, Image, Heading, Text, useBreakpointValue } from "@chakra-ui/react";

const DetailsCard = ({ Header, Body, cover }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        overflow: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        padding: "24px 24px"
      }}
    >
      <Flex 
      flexDirection="column"
      gap="16px"
      alignItems="center"
      >
    <Box w="full"> {/* Or use 'w' and 'h' for specific width */}
      <Image
        src={cover}
        alt="Cover Photo"
        objectFit="contain"
      />
    </Box>
        <Heading
          style={{
            color: "#0F0F10",
            fontSize: "28px",
            fontFamily: "Inter",
            fontWeight: "600",
            textTransform: "capitalize",
            lineHeight: "36px",
            wordWrap: "break-word",
            margin: "0px",
            textAlign: "center",

          }}
        >
          {Header}
        </Heading>
        <Text
          style={{
            color: "#606A76",
            fontSize: "15.90px",
            fontFamily: "Inter",
            fontWeight: "400",
            lineHeight: "24px",
            wordWrap: "break-word",
            margin: "0px",
            textAlign: "center",
          }}
        >
          {Body}
        </Text>

        {Header === "Upload with Dribbble" && (
          <Badge backgroundColor="#FFE9BA" color="#0A090B" borderRadius={12} py={2} px={4}>Coming Soon</Badge>
        )}
      </Flex>
    </Box>
  );
};

export default DetailsCard;
