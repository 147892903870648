import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";

import PageHeading from "../components/general/PageHeading";
import PageLayout from "../components/general/PageLayout";
import useStripeAccountStatus from '../customHooks/useStripeAccountStatus';
import StripeAccountRequirementsCheck from "../components/Stripe/StripeAccountRequirementsCheck";


import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Flex,
  HStack,
  Link,
  Avatar,
  Center,
  Badge,
  Box,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  VStack,
} from "@chakra-ui/react";

import Select from "react-select";

// Onboarding Step 1
const OnboardingPrices = () => {
  // Initialize state for form data
  const [formData, setFormData] = useState({
    monthly: '',
    yearly: '',
  });

  const auth = useSelector((state) => state.auth);
  console.log(auth);
  //console.log(auth.user.stripeConnectedAccountID);

  const [error, setError] = useState("");
  const [SuccessfulBanner, setSuccessfulBanner] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [ErrorBanner, setErrorBanner] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);


  const { chargesEnabled, payoutsEnabled, profileError, stripeError } = useStripeAccountStatus();
  console.log(chargesEnabled)


  const handleSelectChange2 = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Update formData state with the selected currency
    setFormData((currentFormData) => ({
      ...currentFormData,
      currency: selectedOption.value, // Assuming you want to store the value
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.post("/api/users/get-profile", {
        id: auth.user.id,
      });
      console.log(response);
      // Prepopulate the form with the user's current profile information
      setFormData({
        monthly: response.data.plan.monthly || '',
        yearly: response.data.plan.yearly || '',
        currency: response.data.plan.currency,
        stripeConnectedAccountID: response.data.stripeConnectedAccountID,
        userId: response.data._id,
        name: response.data.name,
        username: response.data.username,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Show existing data in the form
  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    fetchData();
  }, []);

  // useEffect to log formData whenever it changes
  useEffect(() => {
    console.log("formData updated:", formData);
  }, [formData]); // This effect depends on formData and runs whenever formData changes

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        name === "monthly" || name === "yearly" ? parseFloat(value) : value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    setErrorBanner(false);
    console.log(formData);

    // First, check if the currency has been selected
    if (!selectedOption || !selectedOption.value) {
      setError("Currency is required");
      setSubmitLoading(false);
      return;
    }

    if (formData.monthly < 5) {
      setError("Monthly value must be greater than $5");
      setSubmitLoading(false);
      return;
    }

    if (formData.yearly < 5) {
      setError("Yearly value must be greater than $5");
      setSubmitLoading(false);
      return;
    }

    if (Object.values(formData).some((val) => val == null || val == "")) {
      setError("Some fields are null");
      setSuccessfulBanner(false);
      setSubmitLoading(false);
      return;
    }

    setError(null);

    //Make a POST request to update the profile information
    try {
      const response = await axios.post("/api/users/onboarding-prices", {
        monthly: formData.monthly,
        yearly: formData.yearly,
        currency: selectedOption.value,
        name: formData.name,
        username: formData.username,
        userId: formData.userId,
        connectedAccountId: formData.stripeConnectedAccountID,
      });
      setSubmitLoading(false);
      setSuccessfulBanner(true);
      console.log(response.data);
      window.location.href = "/dashboard";
      fetchData();

      setTimeout(() => {
        setSuccessfulBanner(true);
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  };

  const currencyOptions = [
    { value: "USD", label: "United States (USD)" },
    { value: "AUD", label: "Australia (AUD)" },
    { value: "EUR", label: "Austria (EUR)" },
    { value: "EUR", label: "Belgium (EUR)" },
    { value: "BGN", label: "Bulgaria (BGN)" },
    { value: "CAD", label: "Canada (CAD)" },
    { value: "HRK", label: "Croatia (HRK)" },
    { value: "EUR", label: "Cyprus (EUR)" },
    { value: "CZK", label: "Czech Republic (CZK)" },
    { value: "DKK", label: "Denmark (DKK)" },
    { value: "EUR", label: "Estonia (EUR)" },
    { value: "EUR", label: "Finland (EUR)" },
    { value: "EUR", label: "France (EUR)" },
    { value: "EUR", label: "Germany (EUR)" },
    { value: "EUR", label: "Greece (EUR)" },
    { value: "HKD", label: "Hong Kong (HKD)" },
    { value: "HUF", label: "Hungary (HUF)" },
    { value: "EUR", label: "Ireland (EUR)" },
    { value: "EUR", label: "Italy (EUR)" },
    { value: "JPY", label: "Japan (JPY)" },
    { value: "EUR", label: "Latvia (EUR)" },
    { value: "EUR", label: "Lithuania (EUR)" },
    { value: "EUR", label: "Luxembourg (EUR)" },
    { value: "EUR", label: "Malta (EUR)" },
    { value: "MXN", label: "Mexico (MXN)" },
    { value: "EUR", label: "Netherlands (EUR)" },
    { value: "NZD", label: "New Zealand (NZD)" },
    { value: "NOK", label: "Norway (NOK)" },
    { value: "PLN", label: "Poland (PLN)" },
    { value: "EUR", label: "Portugal (EUR)" },
    { value: "RON", label: "Romania (RON)" },
    { value: "SGD", label: "Singapore (SGD)" },
    { value: "EUR", label: "Slovakia (EUR)" },
    { value: "EUR", label: "Slovenia (EUR)" },
    { value: "EUR", label: "Spain (EUR)" },
    { value: "SEK", label: "Sweden (SEK)" },
    { value: "CHF", label: "Switzerland (CHF)" },
    { value: "THB", label: "Thailand (THB)" },
    { value: "GBP", label: "United Kingdom (GBP)" },
  ];

  return (
    <PageLayout activeLink="/dashboard">
      <Box h="100%">
          <PageHeading
            heading="Set Subscription Prices"
            subHeading="Determine how much you will charge subscribers for access to your channel"
          />

        {/*Form*/}

        {chargesEnabled ? (
           <Box py={{ base: "8", sm: "0" }} borderWidth="1px" borderRadius="md" px={4}>
                  <form onSubmit={handleSubmit} className="">
                    <Box py={{ base: "0", sm: "8" }} px={{ base: "2", sm: "8" }}>
                      <Stack spacing={4}>
                        {SuccessfulBanner && (
                          <Alert status="success"> Form submitted successfully! </Alert>
                        )}
                        {error && (
                          <Alert
                            variant="danger"
                            dismissible
                            onClose={() => setSuccessfulBanner(false)}
                          >
                            {error}
                          </Alert>
                        )}
        
                        <Stack spacing={4}>
                          <VStack>
                            <HStack w="100%">
                              {/* Monthly Price input */}
                              <FormControl id="monthly" isRequired>
                                <FormLabel htmlFor="email">Monthly</FormLabel>
                                <Input
                                  htmlSize={4}
                                  w="100%"
                                  variant="outline"
                                  placeholder="$5"
                                  type="number"
                                  name="monthly"
                                  id="monthly"
                                  min="5"
                                  value={formData.monthly}
                                  onChange={handleChange}
                                />
                              </FormControl>
        
                              {/* Yearly Price input */}
                              <FormControl id="yearly" isRequired>
                                <FormLabel htmlFor="email">Yearly</FormLabel>
                                <Input
                                  htmlSize={4}
                                  w="100%"
                                  variant="outline"
                                  placeholder="$50"
                                  type="number"
                                  name="yearly"
                                  id="yearly"
                                  min="5"
                                  value={formData.yearly}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </HStack>
        
                            <FormControl id="currency" isRequired>
                              <FormLabel>Currency</FormLabel>
                              <Select
                                value={selectedOption}
                                onChange={handleSelectChange2}
                                options={currencyOptions}
                                style={{
                                  width: "75px",
                                }}
                              />
                            </FormControl>
                          </VStack>
        
                          {/* Submit button */}
                          <Button
                            type="submit"
                            variant="primaryButton"
                            isLoading={submitLoading}
                            loadingText="Completing Onboarding"
                            spinnerPlacement="start"
                            width="240px"
                            _hover={{
                              bg: "#4F4D55", // Example hover state change
                            }}
                            _disabled={{
                              bg: "#0A090B", // Disabled background should stay consistent
                              color: "rgb(255, 255, 255)", // Text color also remains consistent
                              opacity: 0.6, // Lower opacity to indicate the button is disabled
                              _hover: {
                                bg: "#0A090B", // Ensure the background remains the same on hover
                                opacity: 0.6, // Maintain the same opacity on hover to avoid disappearing
                              },
                            }}
                            _loading={{
                              bg: "#4F4D55", // Keep the same hover background color in loading state
                              color: "rgb(255, 255, 255)", // Ensure text color is consistent
                              _hover: {
                                bg: "#4F4D55", // Ensure hover color is consistent in loading state
                              },
                            }}
                          >
                            Complete
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  </form>
           </Box>
        ) : ( <StripeAccountRequirementsCheck />
        )}



      </Box>
    </PageLayout>
  );
};

export default OnboardingPrices;
