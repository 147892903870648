import React, { useState, useEffect } from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { HiDocumentSearch } from "react-icons/hi";

const ErrorGettingContent = ({}) => {
  return (
    <Box>
      <Flex
        direction="column"
        justify="center"
        align="center"
        height="50vh"
        mx="auto"
      >
        <HiDocumentSearch color="#A1A0A3" size={80} className="empty-icon" />
        <Heading size="md" mt={4} mb={4}>
          Error retrieving content, please try again later
        </Heading>
      </Flex>
    </Box>
  );
};

export default ErrorGettingContent;
