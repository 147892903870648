import React, { useEffect, useState } from 'react';
import { Flex, Badge, Text, Box, Button, Heading, Container, HStack, VStack } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import axios from 'axios';


function DeleteStripeConnect({currentActiveStatus, connectedAccountId, id}) {
  const [isActive, setIsActive] = useState();
  const [showModal, setShowModal] = useState(false);

  // Show exsiting data in the form
  useEffect(() => {
    setIsActive(currentActiveStatus)
    console.log(currentActiveStatus)
    console.log(isActive)

  }, [currentActiveStatus,isActive]);

  const handleClick = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleSubmit = () => {
    axios.post('/api/connectedAccounts/delete-connected-account', { connectedAccount: connectedAccountId, userId: id })
      .then(response => {
        //setIsActive(response.data.plan.isActive);
        setShowModal(false);
        window.location.href="/payout";
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      {/*
      <Button variant='ghost' onClick={handleClick}>
        {isActive ? 'Disconnect' : ''}
      </Button>
      */}
      <Modal isOpen={showModal} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          Are you sure you want to {isActive ? 'disconnect' : ''} your Stripe Account? This will deactivate your membership page.
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isActive ? 'Disconnect' : ''}
          </Button>
        </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteStripeConnect;
