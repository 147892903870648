import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa"; // Using FontAwesome for the info icon

const InfoNotification = ({ message }) => {
  return (
    <Box
      w="100%"
      h="100%"
      px={4}
      py={2}
      bg="white"
      borderRadius="8px"
      overflow="hidden"
      border="1px solid #EBF0FB"
      mb={4}
    >
      <Flex gap={2} align="center" justify="flex-start">

          <Icon as={FaInfoCircle} w="16px" h="16px" color="#1751D0" />

        <Box flex="1" display="inline-flex" flexDirection="column">
          <Text
            color="#1751D0"
            fontSize="14px"
            fontWeight="450"
            lineHeight="20px"
            fontFamily="Inter"
            wordBreak="break-word"
            m={0}
          >
            {message}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default InfoNotification;