import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import  SuccessBanner  from './SuccessBanner';
import "../../index.css";
import dribbble from '../../Images/dribbble.png'


import {
  Box, HStack, VStack, Image, Text, Button, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Moment from 'react-moment';
import DribbbleNewPost from './DribbbbleNewPost';
import axios from 'axios';

import { useToast } from "@chakra-ui/react";


var striptags = require('striptags');

const UserShots = ({ shots }) => {

  const toast = useToast();
  const [showBanner, setShowBanner] = useState(false);
  const [matchingPosts, setMatchingPosts] = useState([]);
  const [modalStates, setModalStates] = useState(shots.map(() => ({
    isOpen: false,
    title: '',
    media: '',
    description: '',
  })));

  const userUsername = useSelector(state => state.auth.user.username);
  const user = useSelector(state => state.auth.user);

  
  async function checkIfUploaded() {
    try {
      const response = await axios.post('/api/posts/get-all-posts-from-user',{id: user.id});
      setMatchingPosts(response.data.filter(post => shots.some(shot => shot.id === post.DribbbleID)));
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    checkIfUploaded();
    console.log(shots.length)
  }, [shots]);

  const isUploaded = (shot) => {
    const matchingPost = matchingPosts.find(post => post.DribbbleID === shot.id);
    return matchingPost ? true : false;
  };

  const openModal = (index) => {
    setModalStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? { ...state, isOpen: true } : state))
    );
  };

  const closeModal = (index) => {
    setModalStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? { ...state, isOpen: false } : state))
    );
  };

  async function onPostSuccess() {
    console.log("inside onPostSuccess")

    setModalStates(modalStates.map((modalState) => ({
      ...modalState,
      isOpen: false
    })));

    checkIfUploaded()

          // Show success alert
          toast({
            title: "Post Uploaded Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

    setShowBanner(true);
  };



  return (
    <Box>
      {showBanner && <SuccessBanner className="floating-banner" />}

      <HStack align="start">
        <Image boxSize='22px' objectFit='cover' src={dribbble} alt="Dribbble Logo"/>
        <Text mb={0} variant="listItemHeading">{shots.length} Dribbble Shots</Text>
      </HStack>

      {shots &&
        shots.map((shot, index) => (
          <HStack key={shot.id} py={4} mb={4} bg="#FFFFFF" borderBottom="1px solid #E2E8F0">
            <Image src={shot.images.hidpi} boxSize="150px" />
            <HStack align="center" justify="space-between" w="100%">
              <VStack align="start" spacing={1}>
                <Text mb={0} variant="listItemHeading">{shot.title}</Text>
                <Text mb={0} variant="listItemSubHeading">
                  {striptags(shot.description)}
                </Text>
                <Text mb={0} variant="listItemMicro">
                  <Moment format="dddd, MMMM D YYYY">{shot.published_at}</Moment>
                </Text>
              </VStack>
              {isUploaded(shot) ? (
                <Button color="#3E3E40" bg="#CBD5E0" size="md" isDisabled>
                  Uploaded
                </Button>
              ) : (
                <Button variant="secondaryButton" onClick={() => openModal(index)}>
                  Upload
                </Button>
              )}



              <Modal isOpen={modalStates[index].isOpen} onClose={() => closeModal(index)} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader> Upload</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <DribbbleNewPost
                      info={{
                        title: shot.title,
                        media: shot.images.hidpi,
                        description: striptags(shot.description),
                        DribbbleID: shot.id,
                      }}
                      onPostSuccess = {onPostSuccess}
                    />
                  </ModalBody>


                </ModalContent>
              </Modal>
            </HStack>
          </HStack>
        ))}
    </Box>
  );
};

export default UserShots;
