import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Spinner,Center, Flex, Text, Box, Heading, Link } from '@chakra-ui/react';
import { HiCheckCircle, HiExclamation } from "react-icons/hi";


const VerifyCheckoutSession = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    const connectId = new URLSearchParams(window.location.search).get('connectId');
    const username = new URLSearchParams(window.location.search).get('username');

    axios.post('/api/subscription/verify-checkout-session', { session_id: sessionId, connectId: connectId, username: username, authEmail: auth.user.email })
      .then(response => {
        //console.log(response.data);

        if(response.data.payment_status == "paid"){
        // Email Subscriber successful subscription
        axios.post('/api/mail/new-subscriber', { 
          creatorName: username, 
          subscriberEmail: auth.user.email,
          amount: response.data.amount_total, 
          created: response.data.created, 
          invoice: response.data.invoice,
          currency: response.data.currency,
        })
        .then(response => {
          //console.log(response.data);
        })
        .catch(error => {
          setIsLoading(false);
          setIsError(true);
          console.error(error);
        });

        // Set success to true if checkout session is paid
        setIsLoading(false);
        setIsSuccess(true);
      } else{
        //Else checkout session is not paid show error
        setIsLoading(false);
        setIsError(true);
      }
      })
      .catch(error => {
        // There was an error show error
        setIsLoading(false);
        setIsError(true);
        console.error(error);
      });
    




  }, []);

  return (
    <div>
      {isLoading && <Center h="100vh"><Spinner /></Center>}
      {isSuccess &&
        <Box>
          <Flex
            direction="column"
            justify="center"
            align="center"
            height="50vh"
            maxW="400px"
            mx="auto"
          >
            <HiCheckCircle color="#A1A0A3" size={80} className="empty-icon" />
            <Heading size='lg' mb={4}> Checkout session verified!</Heading>
            <Text mb={4} textAlign="center">
              Congratulations! Your subscription payment has been successfully processed through Stripe.
            </Text>
            <Link href='/home' variant='primaryButton'>View Subscription</Link>
          </Flex>
        </Box>
      }
      {isError &&

        <Box>
        <Flex
          direction="column"
          justify="center"
          align="center"
          height="50vh"
          maxW="400px"
          mx="auto"
        >
          <HiExclamation color="#A1A0A3" size={80} className="empty-icon" />
          <Heading size='lg' mb={4}> Error proccesing your payment</Heading>
          <Text mb={4} textAlign="center">
            Error validating your payment with Stripe, try again later or contact support
          </Text>
          <Text>support@uimarketplace.com</Text>
        </Flex>
      </Box>


      }
    </div>
  );
};

export default VerifyCheckoutSession;


