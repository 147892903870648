import React, { Component, useState, useEffect } from 'react';
import { Badge, Box, Heading, Avatar, Image, Text, Link, Stack, HStack, VStack, SpaceProps, Grid, GridItem } from '@chakra-ui/react'

function PostCard({ action, data }) {
  const [link, setLink] = useState(null);

  const postLink = `/post/${data._id}`
  const postPreviewLink = `/post/preview/${data._id}`

  const profileLink = `/profile/${data.username}`

  useEffect(() => {

    if (action == "subscribe") {
      setLink(postPreviewLink)
    } else {
      setLink(postLink)
    }

  }, [action]);

  const cardStyle = {
    fontWeight: '500',
    color: '#737279',
    textDecoration: 'none',
    _hover: {
      transform: 'scale(1.05)',
      borderColor: '#F6F6F6',
      textDecoration: 'none',
      borderWidth: '2px',
      borderRadius: '4px',
    },
  };




  return (

<GridItem>
  <Link href={postLink} textDecoration="none" _hover={{ textDecoration: 'none' }}>
    <Box mb='10px' borderWidth={1} borderRadius="md" overflow="hidden">
      <Image
        src={data.mediaLink}
        alt={data.username}
        w='100%'
        h='250px'
        objectFit='cover'
        transition="0.3s ease-in-out"
        _hover={{
          transform: 'scale(1.05)',
        }}
      />
    </Box>

    <VStack align='stretch' spacing={0}>
      <Heading fontSize="md" textAlign='left' _hover={{ textDecoration: 'none' }}>{data.title}</Heading>
      <HStack spacing={1}>
        <Avatar mr={2} size='sm' name={data.fullName} src={data.profileImage} />
        <Text fontSize="md"  mb={0}>{data.fullName}</Text>
      </HStack>
      <Box>
           {data.postType === 'Design' && <Badge colorScheme='green'>Design</Badge>}
            {data.postType === 'Code' && <Badge colorScheme='purple'>Code</Badge>}
        </Box>
    </VStack>
  </Link>
</GridItem>


  );
}

export default PostCard;

