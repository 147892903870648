import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  GridItem,
  Box,
  Image,
  Center,
  Spinner,
  VStack,
  Heading,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import Badge from "../general/Badge";
import { usePostsByUsername } from "../../customHooks/usePostsByUsername";

const PostsByUser2 = ({ userId, username }) => {
  const navigate = useNavigate();
  const { posts, loading, error, totalPages, setCurrentPage, hasMore } =
    usePostsByUsername(username);
  const sentinel = useRef(null);

  const handlePostClick = (post) => {
    navigate(`/u/${username}/post/${post._id}`);
  };
  const bgColor = useColorModeValue("gray.100", "gray.700");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return () => observer.disconnect();
  }, [loading, hasMore, setCurrentPage]);

  if (loading && posts.length === 0) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (error) {
    return <Text>Error loading posts.</Text>;
  }

  return (
    <Grid
      py="24px"
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      gap={12}
    >
      {posts?.length > 0 &&
        posts.map((post) => (
          <GridItem
            _hover={{ cursor: "pointer" }}
            key={post._id}
            onClick={() => handlePostClick(post)}
          >
            <Box
              mb="10px"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              bg={bgColor} 
              aspectRatio="1" 
              position="relative"
            >
              <Image
                src={post.mediaLink}
                alt={post.username}
                w="full"
                h="full"
                objectFit="cover"
                transition="0.3s ease-in-out"
                _hover={{
                  transform: "scale(1.05)",
                }}
              />
            </Box>
            <VStack align="start" spacing={1}>

            <Flex width="100%" justify="space-between" align="center">
              <Text
                mb={0}
                fontWeight="500"
                fontSize="14px"
                isTruncated
                noOfLines={1} // This limits the text to a single line with ellipsis
                maxW="150px" // Adjust this width to fit approximately 25 characters
              >
                {post.title}
              </Text>


                {post.postType === "Design" && (
                  <Badge label="Design" bg="#EBF0FB" color="#113B98" />
                )}
                {post.postType === "Code" && (
                  <Badge label="Code" bg="#F1E9FB" color="#5C1DA7" />

                )}
              </Flex>
            </VStack>
          </GridItem>
        ))}
      <div ref={sentinel}></div>
      {loading && hasMore && (
        <Center>
          <Spinner />
        </Center>
      )}
    </Grid>
  );
};

export default PostsByUser2;
