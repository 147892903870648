import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import Search from '../../Images/search.svg';
import { Image, Flex, Badge, Text, Box, Button, Heading, Container, HStack, VStack, Center } from '@chakra-ui/react';


const NoSubscriptions = ({ match }) => {

  return (
    <Center borderWidth="1px" borderRadius="md" h="300px" align="center" justify="center">
      <Flex
        direction="column"
        justify="center"
        align="center"
        w="full"
        
        
      >
        <Image mb={4} boxSize="60px" src={Search} alt="No Results" />
        <Heading size="lg" mb={2}>
          No Subscriptions
        </Heading>
        <Text textAlign="center">
          You don't have any active or inactive subscriptions.
        </Text>
      </Flex>
    </Center>

  );
};

export default NoSubscriptions;
