// usePostsByUser.js
import { useState, useEffect, useCallback } from 'react';
import postsAPIService from '../apiServices/postsAPIService';

  // This is the endpoint used in the ViewPublicPage
  export const usePostsByUsername = (username) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [hasMore, setHasMore] = useState(true);
  
    const fetchPosts = useCallback(async () => {
      if (!hasMore) return; // Stop fetching if no more pages
  
      setLoading(true);
      try {
        const { data } = await postsAPIService.getAllPostsByUsername(username, currentPage);
        setPosts(prevPosts => [...prevPosts, ...data.posts]);
        setTotalPages(data.totalPages);
        setHasMore(currentPage < data.totalPages); // Update hasMore based on pages left
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }, [username, currentPage, hasMore]);
  
    useEffect(() => {
      fetchPosts();
    }, [username, currentPage, fetchPosts]);
  
    return { posts, loading, error, totalPages, setCurrentPage, hasMore };
  };

