import React, { Component, useState, useEffect } from "react";
import Complete from "../status/Complete";


import {
  Center,
  Box,
  Heading,
  Link,
  Text,
  HStack,
  VStack,
  Image,
  Badge,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Stack } from "react-bootstrap";

const ActionContainer = ({
  imgSrc,
  heading,
  subheading,
  buttonText,
  buttonLink,
  badge,
  disabled,
  tooltipLabel,
}) => {
  const [bg, badgeText] = badge
    ? ["green", "Complete"]
    : ["#FDFBE7", "Incomplete"];
  //console.log(badge, disabled);

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paddingx = useBreakpointValue({ base: "4", md: "4" });

  return (
    <Box
      display="flex"
      flexDirection="column"
      background="#FFFFFF"
      border="1px solid #ECECED"
      boxShadow="0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02)"
      borderRadius="8px"
      w="100%"
      h="100%"
      justifyItems="center"
      p={paddingx}
      gap={4}
    >
      {/* Change to render the badge with the correct color scheme and label */}
      <Center alignItems="center" justifyContent="center">
        <Image width="120px" height="80px" src={imgSrc} />
      </Center>

      <VStack
        flex="1"
        flexDirection="column"
        justifyContent="center" // change to center
        alignItems="flex-start"
        padding="12px"
        justifyItems="center"
      >
        <Stack>
          <Heading
            fontFamily="'Inter'"
            fontStyle="normal"
            fontWeight="600"
            fontSize="20px"
            lineHeight="24px"
            display="flex"
            alignItems="center"
            letterSpacing="-0.26px"
            color="#0A090B"
            flex="none"
            flexGrow="0"
            margin="0"
            mb="4px"
            w="full"
          >
            {heading}
          </Heading>

          <Text
            fontFamily="'Inter'"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
            lineHeight="22px"
            display="flex"
            color="#0A090B"
            alignSelf="stretch"
            flexGrow="0"
            margin="0"
          >
            {subheading}
          </Text>
        </Stack>

        {badge ? (
          <Complete /> // Render the Complete component if badge is true
        ) : (
          <Link
            as="a"
            href={!disabled ? buttonLink : "#"}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            padding={"8px 16px"}
            color="#ffffff"
            borderRadius="7px"
            flex="none"
            flexGrow="0"
            marginTop="16px"
            fontFamily="'Inter'"
            fontStyle="normal"
            fontWeight="600"
            fontSize="16px"
            textDecoration="none"
            background={disabled ? "#BDBDBD" : badge ? "#BDBDBD" : "#0A090B"}
            pointerEvents={disabled ? "none" : "auto"}
            _hover={{
              bg: "#4F4D55", // Example hover state change
            }}
            _disabled={{
              bg: "#0A090B", // Disabled background should stay consistent
              color: "rgb(255, 255, 255)", // Text color also remains consistent
              opacity: 0.6, // Lower opacity to indicate the button is disabled
              _hover: {
                bg: "#0A090B", // Ensure the background remains the same on hover
                opacity: 0.6, // Maintain the same opacity on hover to avoid disappearing
              },
            }}
          >
            {buttonText}
          </Link>
        )}
      </VStack>
    </Box>
  );
};

export default ActionContainer;
