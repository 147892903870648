import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { datadogRum } from '@datadog/browser-rum';

import PageLayout from "../components/general/PageLayout";
import TogglePageStatus from "../components/MembershipSettings/TogglePageStatus";
import StripeAccountRequirementsCheck from "../components/Stripe/StripeAccountRequirementsCheck";

import SideNav from "../components/nav/SideNav";
import SubscriptionTable from "../components/dashboard/SubscriptionTable";
import SubscriptionCountCard from "../components/dashboard/SubscriptionCountCard";
import TableEmptyState from "../components/dashboard/TableEmptyState";
import MRRCard from "../components/dashboard/MRRCard";
import YRRCard from "../components/dashboard/YRRCard";
import OnboardingOverview from "./OnboardingOverview";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  Flex,
  SimpleGrid,
  Center,
  Button,
  StackDivider,
  Stack,
  Box,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react"; // Import a spinner component from your UI library

const Dashboard = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // useState hooks for setting profile and subscriptions
  const [profile, setProfile] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Check if user is authenticated
    const isCreator = async (id) => {
      try {
        if (!auth.isAuthenticated) {
          window.location.href = "/login";
          return; // Exit the function if not authenticated
        }

        // Fetch user details for additional validation (optional)
        await axios.post("/api/users/findbyID", { id: id });
      } catch (error) {
        console.error("Error retrieving user");
        return; // Exit the function on error
      }
    };

    // Fetches the user's profile data
    const fetchProfile = async () => {
      try {
        // Requesting user profile from the server
        const profileData = await axios.post("/api/users/get-profile", {
          id: auth.user.id,
        });
        // Updating state with the fetched profile data
        setProfile(profileData.data);
        console.log(profileData.data);

        //Update datadog RUM with user data
        datadogRum.setUser({ 
          id: profileData.data._id,
          name: profileData.data.name,
          email: profileData.data.email
        });

        // Fetching subscriptions using the Stripe connected account ID
        fetchSubscriptions(profileData.data.stripeConnectedAccountID);
        
        setTimeout(() => {
          setLoading(false);
        }, 1000);

      } catch (err) {
        // Log any errors encountered during fetching profile
        console.error(err);
      }
    };

    // Fetches the subscriptions data based on the given account ID
    const fetchSubscriptions = async (id) => {
      console.log(id);
      try {
        // Requesting subscription data from the server
        const subscriptionsData = await axios.post(
          "/api/subscription/list-subscriptions",
          {
            connectedAccountId: id,
          }
        );
        console.log(subscriptionsData);
        // Updating state with the fetched subscriptions
        setSubscriptions(subscriptionsData.data);
      } catch (error) {
        // Handle errors encountered during fetching subscriptions
        console.error(error);
        setLoading(false);
      }
    };

    // Check authentication and fetch profile
    isCreator(auth.user.id).then(() => {
      fetchProfile();
    });

    console.log(auth.user);
  }, [auth]); // Dependency on auth object

  // Logout handler
  const onLogoutClick = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  // Render
  const { user } = auth;


  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paddingx = useBreakpointValue({ base: "4", md: "28" });

  

  return (
    <PageLayout activeLink="/dashboard">
      <Box h="100%">
        {profile && profile.plan && profile.plan.monthly === 0 ? (
          <Box h="100%">
            <OnboardingOverview />
          </Box>
        ) : (
          <Box  mt={0} pt={0} h="100%">
            {/* StripeAccountRequirementsCheck && Toggle*/}
            {profile && (
              <Box>
                <StripeAccountRequirementsCheck />

                <Stack
                  direction={flexDirection} // 'column' on base (smaller screens), 'row' on md (medium screens) and up
                  py={4}
                  justifyContent="space-between"
                  align="center" // Ensure items are aligned center in the cross-axis
                  spacing={4} // Adjust the spacing between items appropriately
                >
                  <Box>
                    <Text fontSize="3xl" as="b">
                      Welcome, {profile.name}
                    </Text>
                  </Box>

                  <Box >
                    {profile.plan && (
                      <TogglePageStatus
                        currentActiveStatus={profile.plan.isActive}
                        id={profile._id}
                        // Commented out the below line for clarity, as isAnyOnboardingIncomplete logic is not shown in this snippet
                        // disabled={isAnyOnboardingIncomplete} // Pass disabled prop based on the onboarding completeness
                        stripeID={profile.stripeConnectedAccountID}
                      />
                    )}
                  </Box>
                </Stack>
              </Box>
            )}

            {/* Metrics */}
            <Flex alignItems="flex-start" direction={flexDirection} w="100%" mb={4}>
              <SubscriptionCountCard />
              <MRRCard />
              <YRRCard />
            </Flex>

            {/* Subscriptions Table */}
            <Box h="full">
              <Box>
                {loading ? (
              <Center h="50vh"><Spinner /></Center>
                ) : subscriptions && subscriptions.length > 0 ? (
                  <SubscriptionTable subscriptions={subscriptions} />
                ) : (
                  <TableEmptyState />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Dashboard;
