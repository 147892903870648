import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerSubscriber } from "../../actions/authActions";
import classnames from "classnames";
import axios from "axios";
import Logo2 from "../../Images/Logo2.png";
import InfoNotification from "../general/InfoNotification";

import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormErrorMessage,
  Link,
  Center,
  Spinner,
  Image
} from "@chakra-ui/react";
 
class RegisterSubscriber extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username:"",
      email: "",
      password: "",
      password2: "",
      errors: {},
      profile:{},
      link: "",
      queryString:"",
      loading: true, // Add a loading state
    };
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    const creator = urlParams.get('creator');
    const interval = urlParams.get('interval');

    const queryString = window.location.search;
    this.setState({
      queryString: queryString
      });

    // If logged in and user navigates to Register page, should redirect them to dashboard
    // if (this.props.auth.isAuthenticated) {
    //   window.location.href = "/dashboard";
    // }

    const fetchProfile = async () => {
        try {
          const profileData = await axios.post('/api/users/get-profile', { id: creator });
          let link;
          //console.log(profileData.data);
        
        if(interval == "monthly"){
            link = profileData.data.plan.stripeMonthlyPriceLink
        } else if (interval == "yearly"){
            link = profileData.data.plan.stripeYearlyPriceLink
        }

        if (this.props.auth.isAuthenticated) {
          window.location.href = link;
        } else{
          this.setState({
            loading: false,
            });
        }

        this.setState({
        profile: profileData.data,
        link: link
        });          

        } catch (err) {
          //console.log(err);
        } 
      };
      fetchProfile()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2
    };

    //console.log(this.state);
    this.props.registerSubscriber(newUser, this.state.link);
  };

  render() {
    const { errors, loading } = this.state;
    if (loading) {
      return  <Center h="100vh"><Spinner /></Center>;
    }

    return (
      <Flex
      direction="column"
      w="full"
      minH="100vh"
      align="center"
      justify="center"
      p={6}
      bg="gray.50"
    >
      <Flex flexDirection="column" w="400px" p="32px" bg="white" boxShadow="xl" borderRadius="lg">
        {/* Header */}
        <VStack spacing="0" align="start" mb="2">
        <Button
              mb={12}
              p={0}
              style={{ textDecoration: "none", background: "none" }}
              as={Link}
              href="/"
            >
              <Image objectFit="cover" src={Logo2} alt="UI Marketplace" />
            </Button>

          <InfoNotification message="Create an account before subscribing" />
          <Text mb={0} fontSize="xl" fontWeight="semibold">Create your account</Text>
        </VStack>











        {/* Registration Form */}
        <form onSubmit={this.onSubmit}>
          <VStack spacing="4">
            <FormControl isInvalid={errors.name}>
              <Input
                placeholder="Enter name"
                id="name"
                type="text"
                onChange={this.onChange}
                value={this.state.name}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email}>
              <Input
                placeholder="Enter email"
                id="email"
                type="email"
                onChange={this.onChange}
                value={this.state.email}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <Input
                placeholder="Create a password"
                id="password"
                type="password"
                onChange={this.onChange}
                value={this.state.password}
              />
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>


                <FormControl isInvalid={errors.password2}>
                  <Input
                    id="password2"
                    type="password"
                    value={this.state.password2}
                    onChange={this.onChange}
                    placeholder="Confirm Password"
                  />
                  <FormErrorMessage>{errors.password2}</FormErrorMessage>
                </FormControl>

            {/* Error Message Display */}
            {errors.RegisterErrorMessage && (
              <Text color="red.500">{errors.RegisterErrorMessage}</Text>
            )}

            {/* Submit Button */}
            <Button variant="primaryButton" w="full" type="submit">Create account</Button>
          </VStack>
        </form>

        {/* Footer */}
        <Flex flexDirection="column" justify="flex-start" align="flex-start" mt="2">
          <Text fontSize="sm" color="gray.600">
            Already have an account? <Link as={RouterLink} to="/login" color="blue.500">Log in</Link>
          </Text>

          <Text fontSize="sm" color="gray.600">
            By continuing, you agree to UIMarketplace's{" "}
              <Link as={RouterLink} to="/terms" color="blue.500">
                Terms and Conditions{" "}
              </Link>
              and {" "}
              <Link as={RouterLink} to="/privacy" color="blue.500">
                Privacy Policy
              </Link>
            </Text>
            
        </Flex>
      </Flex>
    </Flex>
    );
  }
}

RegisterSubscriber.propTypes = {
  registerSubscriber: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});



export default connect(
  mapStateToProps,
  { registerSubscriber }
)(RegisterSubscriber);













































