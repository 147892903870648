import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Center,
  Button,
  HStack,
  Image,
  Input,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Link,
  Badge,
  Flex,
} from "@chakra-ui/react";
import Moment from "react-moment";
import SuccessBanner from "./SuccessBanner";
import DeleteBanner from "./DeleteBanner";

import striptags from "striptags";
import NoPosts from "./NoPosts";

const AllPostsFromAuthUser = () => {
  const [posts, setPosts] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [showDeleteBanner, setDeleteBanner] = useState(false);
  const [modalStates, setModalStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [updatedPost, setUpdatedPost] = useState({ mediaLink: "" });
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPosts, setHasPosts] = useState(false);

  const [deletePostId, setDeletePostId] = useState(null); // Add this line
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // For the deletion confirmation modal


  const userUsername = useSelector((state) => state.auth.user.username);
  const user = useSelector((state) => state.auth.user);

  const inputRef = useRef();

  useEffect(() => {
    //console.log(user);
    getPosts();
  }, []);


// INFNITE SCROLLING
const sentinel = useRef(null);
const [isLoading2, setIsLoading2] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(0);

const getPosts = async () => {
  setIsLoading2(true);
  try {
    const response = await axios.post("/api/posts/get-all-posts-from-user2", {
      id: user.id,
      page: currentPage,
      limit: 10
    });
    if (response.data.posts.length === 0 && currentPage === 1) {
      setHasPosts(false);
    } else {
      setHasPosts(true);
      const newPosts = currentPage === 1 ? response.data.posts : [...posts, ...response.data.posts];
      setPosts(newPosts);
      setModalStates(newPosts.map(() => ({ isOpen: false }))); // Initialize modal states
      setTotalPages(response.data.totalPages);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setIsLoading2(false);
  }
};

  
  useEffect(() => {
    getPosts(); // Initial fetch and subsequent fetches when currentPage changes
  }, [currentPage]);
  



  const loadMorePosts = () => {
    if (!isLoading2) {
      setIsLoading2(true);
      setCurrentPage(currentPage + 1);
      // Fetch data in getPosts will be triggered by currentPage change
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && currentPage < totalPages && !isLoading2) {
        loadMorePosts();
      }
    }, { threshold: 1.0 });
  
    if (sentinel.current) {
      observer.observe(sentinel.current);
    }
  
    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, [isLoading2, currentPage, totalPages]);
  


  const onInputChange = (e, key) => {
    const value = e.target.value;
    setUpdatedPost(prevState => ({
      ...prevState,
      [key]: value,
    }));
};


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const mediaLink = reader.result;
      setUpdatedPost({ ...updatedPost, mediaLink });
    };
    reader.readAsDataURL(file);
  };


  const allowedPrefixes = [
    "https://www.figma.com/",
    "https://www.sketch.com/",
    "https://xd.adobe.com/",
    "https://projects.invisionapp.com/",
    "https://marvelapp.com/",
    "https://www.canva.com/",
    "https://app.zeplin.io/",
    "https://framer.com/",
  ];

  const validateUrl = (value) => {
    const trimmedValue = value?.trim() ?? "";
    let errorMessage = "";

    // Check if URL is not empty
    if (!trimmedValue) {
      errorMessage = "Update to URL is required";
    }
    // Check for valid URL format
    else if (!/^https?:\/\/.+/.test(trimmedValue)) {
      errorMessage = "Invalid URL format";
    }
    // Validate URL against allowed prefixes
    else {
      const isValidPrefix = allowedPrefixes.some((prefix) =>
        trimmedValue.startsWith(prefix)
      );
      if (!isValidPrefix) {
        errorMessage = `You can only share URLs from the following apps: Figma, Sketch, Adobe XD, InVision, Marvel, Canva, Zeplin, Framer`;
      }
    }
    return errorMessage;
  };

  const validateTitle = (newValue) => {
    // Provide an empty string if newValue is undefined to safely call .trim()
    const trimmedValue = newValue?.trim() ?? "";
    if (!trimmedValue) {
      return "Update to title is required";
    }
    return "";
  };

  const openModal = (postId) => {
    setModalStates((prevStates) =>
      prevStates.map((state, i) =>
        posts[i]._id === postId ? { ...state, isOpen: true } : state
      )
    );
  };
  
  const closeModal = (postId) => {
    setModalStates((prevStates) =>
      prevStates.map((state, i) =>
        posts[i]._id === postId ? { ...state, isOpen: false } : state
      )
    );
  };
  

  const openDeleteModal = (postId) => {
    setDeletePostId(postId);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  

  const deletePost = async () => {
    if (!deletePostId) return;
    try {
      await axios.delete(`/api/posts/delete-post/${deletePostId}`);
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== deletePostId));
      setDeleteBanner(true); // Show delete success banner
    } catch (error) {
      console.error(error);
    } finally {
      closeDeleteModal(); // Close the delete confirmation modal
      setDeletePostId(null); // Reset the deletePostId state
    }
  };
  

  const editPost = async (index, e) => {
    e.preventDefault();

    // Validate fields regardless of changes
    const titleError = validateTitle(updatedPost.title);
    const urlError = validateUrl(updatedPost.url);
    const newErrors = { title: titleError, url: urlError };

    // Update the errors state with the latest validation results
    setErrors(newErrors);

    //console.log(newErrors);

    // Check if there are any validation errors
    if (Object.keys(newErrors).some(key => newErrors[key] !== "")) {
      return;
    }

    setIsLoading(true);
    try {
      const postId = posts[index]._id;
      const postToUpdate = { ...posts[index], ...updatedPost, id: postId };

      await axios.put(`/api/posts/edit-post/${postId}`, postToUpdate);

      // Update posts array
      setPosts(prevPosts =>
        prevPosts.map((post, i) =>
          i === index ? { ...post, ...updatedPost } : post
        )
      );

      // Reset updatedPost state to clear the form
      setUpdatedPost({});

      // Close the modal and refresh posts if needed
      closeModal(index);
      getPosts();
      setShowBanner(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
};

  return (
    <Box>
      {hasPosts ? (
        <Box>
          {showBanner && (
            <SuccessBanner
              message="Post updated successfully"
              onClose={() => setShowBanner(false)}
            />
          )}

          {showDeleteBanner && (
            <DeleteBanner onClose={() => setShowBanner(false)} />
          )}
          {posts.map((post, index) => (
            <Box
              key={post.id}
              borderBottom="1px"
              borderColor="gray.200"
              p={0}
              mb={4}
              w="100%"
            >
              <Flex w="100%" flexDirection={{base:'column', md: 'row'}} style={{ justifyContent: "space-between" }}>
                <Link
                  href={`/post/${post._id}`}
                  _hover={{ textDecoration: "none" }}
                  style={{ textDecoration: "none" }}
                  w="100%"
                >
                  <Flex
                    w="100%"
                    spacing="16px"
                    style={{ justifyContent: "space-between" }}
                    flexDirection={{base:'column', md: 'row'}}
                    alignItems={{base:'left', md:'center'}}
                  >
                    {/*Image*/}
                    <Box mb={2}>
                      <Center>
                        <Image
                          objectFit="contain"
                          borderRadius="md"
                          borderWidth="1px"
                          src={post.mediaLink || updatedPost.mediaLink}
                          boxSize="200px"
                        />
                      </Center>
                    </Box>

                    {/*Texts*/}
                    <Box paddingLeft={4} flex="1" alignItems="top">
                      <VStack alignItems="top">
                        <Text maxW="full" m={0} fontSize="22px" fontWeight="semibold">
                          {post.title}
                        </Text>
                        <Text maxW="800px" m={0} w="full" fontSize="15px">
                          {striptags(post.description)}
                        </Text>
                        <Text m={0} fontSize="16px" color="#4F4D55">
                          <Moment format="MMMM D, YYYY">
                            {post.date}
                          </Moment>
                        </Text>
                        <Box>
                        {post.postType === 'Design' && <Badge colorScheme='green'>Design</Badge>}
                        {post.postType === 'Code' && <Badge colorScheme='purple'>Code</Badge>}
                        </Box>
                      </VStack>
                    </Box>
                  </Flex>
                </Link>

                {/*Buttons*/}
                <Flex alignItems="center" flexDirection="row"  my={{base:'12px' , md:'0'}}>
                  <Button  mr={4} size="md" variant="outline" onClick={() => openModal(post._id)}>Edit</Button>
                  <Button size="md" variant="destruction" onClick={() => openDeleteModal(post._id)}>Delete</Button>
                </Flex>
              </Flex>

            {/* Infinite Scroll Sentinel */}
            {isLoading2 && (
            <Center>
                <></>
            </Center>
            )}

            <div ref={sentinel} /> {/* This is the sentinel for infinite scroll */}


              {/*Edit Modal*/}
              <Modal
                isOpen={modalStates[index]?.isOpen}
                onClose={() => closeModal(post._id)} 
                blockScrollOnMount={true}
                isCentered
                size="xl"
              >
                <ModalOverlay />
                <form onSubmit={(e) => editPost(index, e)}>
                  <ModalContent
                    maxW="90vw"
                    maxH="90vh"
                    mt="5vh"
                    mb="5vh"
                    overflowY="hidden"
                  >
                    <ModalCloseButton mt={3} p={0} />

                    <ModalHeader>Edit Post</ModalHeader>
                    <ModalBody overflowY="auto" maxH="calc(90vh - 60px)">
                      <VStack spacing={4}>
                        {/*Image*/}
                        <Box w="full" mb={2}>
                          <Center>
                            <VStack>
                              <Image
                                src={updatedPost.mediaLink || post.mediaLink}
                                alt="Uploaded Image"
                                w="420px" // Ensures image width is responsive to container width
                                maxH="60vh" // Max height to ensure image fits within modal on most devices
                                objectFit="contain" // Ensures full image is visible, may add empty space around the image
                              />
                              <Button
                                w="auto"
                                bg="#ffffff"
                                colorScheme="blue"
                                variant="outline"
                                size="md"
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                              >
                                {" "}
                                Change Image{" "}
                              </Button>
                            </VStack>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={inputRef}
                              accept="image/png, image/jpeg, image/gif, image/webp"
                              name="mediaLink"
                              id="mediaLink"
                              onChange={handleImageChange}
                            />
                          </Center>
                        </Box>

                        {/*Title*/}
                        <Box w="full" mb={2}>
                          <Text variant="formHeading">Title</Text>
                          <Input
                            variant="input"
                            type="text"
                            placeholder="Title"
                            defaultValue={post.title}
                            onChange={(e) => onInputChange(e, "title")}
                            isInvalid={Boolean(errors.title)}
                          />

                          {errors.title && (
                            <Text fontSize="sm" color="red.500">
                              {errors.title}
                            </Text>
                          )}
                        </Box>

                        {/*Design Link*/}
                        <Box w="full" mb={2}>
                          <Text variant="formHeading">Design Link</Text>
                          <Input
                            variant="input"
                            type="text"
                            placeholder="URL"
                            defaultValue={post.url}
                            onChange={(e) => onInputChange(e, "url")}
                            isInvalid={Boolean(errors.url)}
                          />
                          {errors.url && (
                            <Text fontSize="sm" color="red.500">
                              {errors.url}
                            </Text>
                          )}
                        </Box>

                        {/*Desc*/}
                        <Box w="full" mb={2}>
                          <Text variant="formHeading">Description</Text>
                          <Textarea
                            placeholder="Description"
                            defaultValue={post.description}
                            onChange={(e) => onInputChange(e, "description")}
                            rows={5}
                          />
                        </Box>
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="ghost"
                        mr={3}
                        onClick={() => closeModal(post._id)} // Ensure this is correctly set
                      >
                        Cancel
                      </Button>
                      <Button variant="primaryButton" colorScheme="blue" type="submit" isLoading={isLoading} loadingText="Updating"> Update </Button>

                    </ModalFooter>
                  </ModalContent>
                </form>
              </Modal>

              {/*Delete Modal*/}
              <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Confirm Delete</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    Are you sure you want to delete this post?
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={closeDeleteModal}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" onClick={deletePost}>Delete</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>

            </Box>
          ))}




        </Box>
      ) : (
        <NoPosts />
      )}
    </Box>
  );
};

export default AllPostsFromAuthUser;
