import React from 'react'
import PropTypes from 'prop-types'

import {

  Stack, Flex, Image, useBreakpointValue, HStack, Link, Avatar, Center, Box, Text, Heading, Card, CardHeader, CardBody, CardFooter
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'



  const MembersPageBio = ({profileImage,name,bio,location,portfolioLink,contactEmail,handle}) => {
    const flexDirection = useBreakpointValue({ base: "row", md: "row" });
    const flexDirection2 = useBreakpointValue({ base: "column", md: "row" });

    return (
      <Box p={0}>

        <Flex alignContent="center" flexDirection={flexDirection} mb={8} >
          <Box mb={8}>
              <Avatar name={name} src={profileImage} size={{ base: "2xl", md: "2xl" }} mr={4} />
          </Box>

          <Flex flexDirection="column"  align="flex-start">
              <Text mb={0} fontWeight="700" fontSize="28px"> {name} </Text>

              <Flex flexDirection="row">
                <Text  mb={1} color="gray.900"> {location} </Text>
              </Flex>

              <Text  mb={1} color="gray.900"> {bio} </Text>

              <Flex gap={2} flexDirection={flexDirection2}>
                <Link fontWeight="500" color="#1964FF" href={portfolioLink}  mb={2} > View website <ExternalLinkIcon mx='2px' /> </Link>
                <Link  fontWeight="500" color="#1964FF" href={`mailto:${contactEmail}`}>Send an Email <ExternalLinkIcon mx='2px' /></Link>
              </Flex>
          </Flex>
      </Flex>


      </Box>


    );

  
}

export default MembersPageBio;
