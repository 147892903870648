import { Flex, HStack, VStack, Avatar, Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import SideNav from "../nav/SideNav";
import axios from 'axios';

import DribbbleUserShots from "./DribbbleUserShots";



const InstagramUpload = () => {

    const [userData, setUserData] = useState({});
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const handleCallback = async () => {
            const token = new URLSearchParams(window.location.search)?.get('access_token') ?? '';

            // Get User Details
            try {
                const getUserID = await axios.post('/api/posts/dribbble-getID', { token: token });
                console.log(getUserID.data);
                setUserData(getUserID.data);
            } catch (error) {
                console.error(error);
            }

            // Get User Shots
            try {
                const getUserShots = await axios.post('/api/posts/dribbble-getPosts', { token: token });
                console.log(getUserShots.data);
                setPosts(getUserShots.data);
            } catch (error) {
                console.error(error);
            }

        };
        handleCallback();
    }, [setUserData, setPosts]);

    return (
        <Flex h='100vh' >

            <Box  h='100vh' bg="#ffffff" px={16} py={8} w='400px'>
                <SideNav />
            </Box>

            <Box px={28} py={8} h='100vh' bg="#F9FBFC" overflowY="scroll" >
                {userData?.links &&
                    <HStack
                        p={4}
                        mb={8}
                       
                    >
                        <Box>
                            <Avatar name={userData.name} src={userData.avatar_url} size="xl" mr={4}/>
                        </Box>

                        <VStack align="flex-start">
                            <Heading size="md"> {userData.name} </Heading>
                            <Text fontWeight="bold" mb={2} color="gray.500"> {userData.login} </Text>
                            <Text mb={2} color="gray.500"> {userData.location} </Text>
                        </VStack>

                    </HStack>
                }

                {posts?.length > 0 && <DribbbleUserShots shots={posts} />}
            </Box>

        </Flex>
    );
};

export default InstagramUpload
