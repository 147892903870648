import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Col className="text-center">
      <h1 className="display-4">404 Page Not Found</h1>
      <p className="lead">We're sorry, but the page you're looking for could not be found.</p>
      <Button variant="primaryButton" onClick={() => navigate('/')}>Return to Home</Button>
    </Col>
  );
};

export default NotFound;
