import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Spinner, Flex, Text, Box, Heading } from '@chakra-ui/react';
import { AiFillDribbbleCircle } from "react-icons/ai";




const DribbbleCallback = () => {
    const [DribbbleToken, setDribbbleToken] = useState({});

  useEffect(() => {
    const handleCallback = async () => {
      const code = new URLSearchParams(window.location.search).get('code');

    // Get Access Token
      try {
        const response = await axios.post('/api/posts/dribbble-callback', {code:code});
        console.log(response.data);
        console.log(response.data.access_token);

        let fowardURL = "/upload/dribbble"

        setTimeout(() => {
            window.location.href = `${fowardURL}?access_token=${response.data.access_token}`
        }, 1000);


       // const getUserID = await axios.post('/api/posts/dribbble-getID', {token:response.data.access_token});
       // console.log(getUserID);

      } catch (error) {
        console.error(error);
      }


   
    };
    handleCallback();
  }, [setDribbbleToken, DribbbleToken]);

  return <div>

          <Box>
          <Flex
            direction="column"
            justify="center"
            align="center"
            height="50vh"
            maxW="400px"
            mx="auto"
          >
            <AiFillDribbbleCircle color="#F082AC" size={80} className="empty-icon" />
            <Heading size='lg' mb={4}> Signing in to Dribbble!</Heading>
            <Text mb={4} textAlign="center">
              Please wait while we authenticate your Dribbble Account
            </Text>
            <Spinner />
          </Flex>
        </Box>


  </div>;
};

export default DribbbleCallback;
