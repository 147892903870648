import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import Search from '../../Images/search.svg';
import { Image, Flex, Badge, Text, Box, Button, Heading, Link } from '@chakra-ui/react';


const NoPosts = () => {

  return (
    <div>

      <Flex
        direction="column"
        justify="center"
        align="center"
        height="50vh"
        maxW="400px"
        mx="auto"
      >
        <Image mb={4} boxSize='60px' src={Search} alt='No Results' />
        <Heading size='lg' mb={4}>No Posts</Heading>
        <Text mb={4} textAlign="center">
          Upload your posts to start sharing your content with users
        </Text>
        <Button 
        as="a"
        href='/new/post' 
        variant="primaryButton"        
        >Share your work
        </Button>
      </Flex>
    </div>
  );
};

export default NoPosts;
