import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, VStack } from '@chakra-ui/react';

const InactiveUserPage = () => {
  const navigate = useNavigate();

  return (
    <VStack className="text-center">
      <h1 className="display-4">Page Inactive</h1>
      <p className="lead">Unfourtunetly this user's page is currently inactive.</p>
      <Button as="a" href='/dashboard' variant="primaryButton" >Return Home</Button>
    </VStack>
  );
};

export default InactiveUserPage;
