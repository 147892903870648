// usePostById.js
import { useState, useEffect } from 'react';
import postsAPIService from '../apiServices/postsAPIService';

export const usePostById = (postId) => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!postId) return;

    const fetchPost = async () => {
      setLoading(true);
      const { data, error } = await postsAPIService.fetchPostById(postId);
      if (error) {
        setError(error);
      } else {
        setPost(data);
      }
      setLoading(false);
    };

    fetchPost();
  }, [postId]);

  return { post, loading, error };
};
