import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Avatar, Text, Alert, AlertIcon, AlertTitle, AlertDescription, Link, HStack } from '@chakra-ui/react';
import Moment from 'react-moment';
import NoSubscriptions from './NoSubscriptions';




const CancelSubscriptionList = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const auth = useSelector(state => state.auth);

    const fetchSubscriptions = async () => {
        setIsLoading(true);
        setError('');
        try {
            const { data: mongoSubs } = await axios.post('/api/subscription/findByCustomerEmail', {
                customerEmail: auth.user.email
            });
        
            const subscriptionDetails = await Promise.all(mongoSubs.map(async (subscription) => {
                const { data: subscriptionInfo } = await axios.post('/api/subscription/getInfo', {
                    id: subscription.subscriptionId,
                    connectedAccountId: subscription.subscribedAccountConnectedAccountId
                });
        
                const { data: creatorInfo } = await axios.post('/api/users/findbyStripeConnectedAccountID', {
                    stripeConnectedAccountID: subscription.subscribedAccountConnectedAccountId
                });
        
                const { data: stripeSession } = await axios.post('/api/subscription/create-billing-portal-session', {
                    customerId: subscription.customerId,
                    subscribedAccountConnectedAccountId: subscription.subscribedAccountConnectedAccountId
                });
        
                return {
                    id: subscriptionInfo.id,
                    status: subscriptionInfo.status,
                    amount: subscriptionInfo.items.data[0].price.unit_amount,
                    interval: subscriptionInfo.plan.interval,
                    start_date: subscriptionInfo.start_date,
                    sessionURL: stripeSession.url,
                    creatorName: creatorInfo.name,
                    cancel_at: subscriptionInfo.cancel_at,
                    cancel_at_period_end: subscriptionInfo.cancel_at_period_end
                };
            }));
        
            setSubscriptions(subscriptionDetails);
            console.log(subscriptionDetails)
        } catch (error) {
            setError(error.response?.data || 'An error occurred while fetching subscriptions.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (auth.user && auth.user.email) {
            fetchSubscriptions();
        }
    }, [auth.user.email]);

    //     useEffect(() => {
    //     // Logic to check subscription states and update parent component
    //     const hasActiveNonCancellable = subscriptions.some(sub => sub.status === 'active' && !sub.cancel_at_period_end);
    //     onUpdateDeletionError(hasActiveNonCancellable);
    // }, [subscriptions, onUpdateDeletionError]);

    if (isLoading) {
        return <Box>Loading subscriptions...</Box>;
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>Error:</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    const activeNonCancellable = subscriptions.some(sub => sub.status === 'active' && !sub.cancel_at_period_end);
    const willCancel = subscriptions.some(sub => sub.status === 'active' && sub.cancel_at_period_end);

    if (!subscriptions.length) {
        return null; // Don't show anything if no subscriptions
    }

    return (
        <Flex borderWidth="1px" borderRadius="md" direction="column" w="full">
            {activeNonCancellable && (
                <Alert status="warning" mb={4}>
                    <AlertIcon />
                    <Text mb={0}>You have at least 1 active subscription; please cancel them before deleting your account.</Text>
                </Alert>
            )}
            {willCancel && (
                <Alert status="info" mb={4}>
                    <AlertIcon />
                    <Text mb={0}>Your subscription will cancel at the end of the billing cycle.</Text>
                </Alert>
            )}
            <Box py={{ base: '0', sm: '4' }} px={{ base: '2', sm: '4' }} w="full">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>User</Th>
                            <Th>Status</Th>
                            <Th>Membership</Th>
                            <Th>Subscribed</Th>
                            <Th>Will Cancel On</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions.map(subscription => (
                            <Tr key={subscription.id}>
                                <Td>
                                    <HStack>
                                        <Avatar name={subscription.creatorName} size="sm" />
                                        <Text ml={2}>{subscription.creatorName}</Text>
                                    </HStack>
                                </Td>
                                <Td>
                                    <Text fontWeight="bold">{subscription.status}</Text>
                                </Td>
                                <Td>${(subscription.amount / 100).toFixed(2)} / {subscription.interval}</Td>
                                <Td>
                                    <Moment fromNow>{subscription.start_date * 1000}</Moment>
                                </Td>
                                <Td>
                                    {subscription.cancel_at_period_end ? 
                                      <Moment format="MMM D, YYYY">{subscription.cancel_at * 1000}</Moment> : 'N/A'}
                                </Td>
                                <Td>
                                    <Link href={subscription.sessionURL} isExternal>Manage</Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Flex>
    );
};

export default CancelSubscriptionList;
