import { useState, useEffect } from 'react';
import userAPIService from '../apiServices/userAPIService'; // Adjust the import path as necessary
import { useToast } from '@chakra-ui/react';

const useProfile = (userId) => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();

  useEffect(() => {
    const loadProfile = async () => {
      setIsLoading(true);
      try {
        const {data} = await userAPIService.fetchProfileById(userId);
        console.log(data)
        setProfileData(data);
      } catch (err) {
        setError(err.message || 'Failed to fetch profile');
        toast({
          title: 'Error fetching profile',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) loadProfile();
  }, [userId, toast]);

  const updateProfile = async (formData) => {
    setIsLoading(true);
    try {
      const {data, error} = await userAPIService.updateProfile(formData);
      if (error) throw new Error(error);
      setProfileData(data);
    } catch (err) {
      setError(err.message || 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };
  

  return { profileData, isLoading, error, updateProfile };
};

export default useProfile;
