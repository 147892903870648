import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import PageHeading from "../components/general/PageHeading";
import PageLayout from "../components/general/PageLayout";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Stack,
  Text,
  Image,
  Center,
  Flex
} from "@chakra-ui/react";

const NewPostCode = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tags: "",
  });
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const inputRef = useRef();
  const data = useSelector((state) => state.auth);

  useEffect(() => {
    // Redirect if not authenticated
    if (!data.isAuthenticated) {
      window.location.href = "/login";
    }

    // Check if user data is available and update formData accordingly
    if (data.user && data.user.id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user: data.user.id,
        username: data.user.username,
        fullName: data.user.name,
        profileImage: data.user.plan ? data.user.plan.profileImage : "",
      }));
    }

   // console.log(formData);
  }, [data.isAuthenticated, data.user]);

  const allowedExtensions = [".html", ".css", ".js", ".jsx"];
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
  ];

  const handleFileChange = (event) => {
    const selectedFiles = [...event.target.files];
    const allFilesValid = selectedFiles.every((file) =>
      allowedExtensions.includes(
        file.name.toLowerCase().match(/\.[0-9a-z]+$/i)[0]
      )
    );

    if (!allFilesValid) {
      setErrors((prev) => ({
        ...prev,
        file: "Only HTML, CSS, JS, and JSX files are allowed.",
      }));
      setFiles([]);
      return;
    } else {
      setErrors((prev) => {
        const { file, ...rest } = prev;
        return rest;
      });
    }

    setFiles(selectedFiles);
  };

  const handleImageChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
  
    if (file) {
      const newErrors = { ...errors };
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
  
      const maxFileSize = 500 * 1024 * 1024; // 500MB
  
      try {
        // Check if the selected file is an image of allowed type
        if (!allowedFileTypes.includes(file.type)) {
          throw new Error("Unsupported file type. Allowed types are JPEG, PNG, GIF, and WEBP.");
        }
        // Check file size
        if (file.size > maxFileSize) {
          throw new Error(`File size should not exceed ${maxFileSize / 1024 / 1024}MB.`);
        }
  
        // FileReader to create a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
          // Use reader.result which contains the data URL
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
  
        delete newErrors.image;  // Remove any existing error for the image
      } catch (error) {
        newErrors.image = error.message;
      } finally {
        // Update the state with any new or removed errors
        setErrors(newErrors);
      }
    } else {
      // Handle the case where no file is selected
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "An image is required",
      }));
    }
  };
  
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (!value.trim()) {
      setErrors((prev) => ({
        ...prev,
        [name]: `${name[0].toUpperCase() + name.slice(1)} is required.`,
      }));
    } else {
      setErrors((prev) => {
        const { [name]: removed, ...rest } = prev;
        return rest;
      });
    }

   // console.log(formData);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const newErrors = {};

    // Perform all validations and populate `newErrors` as needed
    if (!formData.title.trim()) newErrors.title = "A title is required";
    if (!image) newErrors.image = "An image is required";
    if (files.length === 0) newErrors.file = "At least one file is required";

    // Set errors state with the newly found errors
    setErrors(newErrors);

    // Check if there are any errors, if so, return early
    if (Object.keys(newErrors).length > 0) {
      setSubmitLoading(false); // Make sure to stop the loading state
      return; // Stop the form submission as there are validation errors
    }

    // No errors, proceed with form submission logic
    setSubmitLoading(true);

    try {
      // Assuming you have an endpoint to handle form submission including files
      const formPayload = new FormData();
      for (const key in formData) {
        formPayload.append(key, formData[key]);
      }
      files.forEach((file) => formPayload.append("files", file));
      formPayload.append("image", image);

      console.log(image)
      console.log(formPayload)

      const response = await axios.post(
        "/api/posts/new-post-code",
        formPayload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Form submitted successfully:", response.data);
      // Redirect or clear form as needed after successful submission
      window.location.href = `/post/${response.data._id}`;
    } catch (error) {
      console.error("Submission error:", error.response?.data);
      // Display an error message
      if (error.response?.status === 400) {
        // Here you can set error messages based on the error.response.data.error
        setErrors({ ...errors, Multer: 'Your image or file size is too large. Upload a new file or image and try again.' });
      } else {
        setErrors({ ...errors, Multer: 'Your image or file size is too large. Upload a new file or image and try again.' });
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  console.log(errors);
  return (
    <PageLayout activeLink="/new/post">
      <Box  overflowY="auto">
        <PageHeading
          heading="New Code Post"
          subHeading="Share your code or design project"
        />

        <form onSubmit={handleSubmit}>
          <VStack spacing={5} align="stretch" pb={16}>
            {/* Cover Image Upload */}
            <FormControl isInvalid={!!errors.image} isRequired>
              <FormLabel>Cover Image</FormLabel>
              {!image ? (
                <Center
                h="350px"
                backgroundColor="#F9FBFC"
                w="full"
                justifyContent="center"
                py="16px"
                borderRadius="md"
                borderWidth="1px"
                >
                  <VStack>
                    <Text variant="H3">
                      Add a cover photo
                    </Text>
                    <Input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={inputRef}
                    />
                    <Button variant="primaryButton" onClick={() => inputRef.current.click()}>
                      Upload Image
                    </Button>
                  </VStack>
                </Center>
              ) : (
                <Center py={5} px={1}>
                  <VStack>
                    <Image src={image} alt="Cover image" maxH="200px" />
                    <Input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={inputRef}
                    />
                    <Button variant="secondaryButton" onClick={(event) => {
                        event.preventDefault(); // Prevent any default action
                        inputRef.current.click(); // Manually trigger the file input click
                      }}>                      
                      Change Image
                    </Button>
                  </VStack>
                </Center>
              )}
              {errors.image && <Text color="red.500">{errors.image}</Text>}
            </FormControl>

            {/* Title Input */}
            <FormControl isInvalid={!!errors.title} isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={formData.title}
                onChange={onChange}
                placeholder="Enter your title..."
              />
              {errors.title && <Text color="red.500">{errors.title}</Text>}
            </FormControl>

            {/* Description Input */}
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={onChange}
                placeholder="Describe your project..."
              />
            </FormControl>

            {/* Tags Input */}
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <Input
                name="tags"
                value={formData.tags}
                onChange={onChange}
                placeholder="Add some tags..."
              />
            </FormControl>

            {/* File Upload */}
            <FormControl isInvalid={!!errors.file} isRequired>
              <FormLabel>Project Files</FormLabel>
              <Input
                type="file"
                accept=".html,.css,.js,.jsx"
                onChange={handleFileChange}
              />
              <Text mt={2}>Allowed file types: HTML, CSS, JS, JSX</Text>
              {errors.file && <Text color="red.500">{errors.file}</Text>}
            </FormControl>

            {/* Submit Button */}
            <Flex align="left">

            <Button
              variant='primaryButton'
              isLoading={submitLoading}
              isDisabled={submitLoading}
              type="submit"
            >
              Upload
            </Button>
            </Flex>

            {errors.Multer && <Text color="red.500">{errors.Multer}</Text>}
            {errors.image && <Text color="red.500">{errors.image}</Text>}

          </VStack>
        </form>
      </Box>
    </PageLayout>
  );
};

export default NewPostCode;
