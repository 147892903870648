import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActiveSubscriptions from '../customHooks/useActiveSubscriptions';
import { Box, Center, Spinner } from '@chakra-ui/react';

import ErrorGettingContent from '../components/Home/ErrorGettingContent';
import NoSubscriptions from "../components/Home/NoSubscriptions";
import SubscribedPosts from "../components/Home/SubscribedPosts";
import NoContent from '../components/Home/NoContent';
import SubscriptionSelector from '../components/Home/SubscriptionSelector';
import PageLayout from "../components/general/PageLayout";

function Home() {
  const auth = useSelector(state => state.auth);
  const [selectedUsername, setSelectedUsername] = useState('All Subscriptions');
  const { displayPosts, options, stripeSubslist, loading, error, hasMore, setCurrentPage } = useActiveSubscriptions(auth.user.email, selectedUsername);
  
  const loadMorePosts = () => {
    if (!loading && hasMore) {
      setCurrentPage(prev => prev + 1);
    }
  };

  if (!auth.isAuthenticated) {
    window.location.href = "/login";
    return null;
  }

  return (
    <PageLayout activeLink='/home'>
      {loading && displayPosts.length === 0 ? (
        <Center h="100vh"><Spinner /></Center>
      ) : error ? (
        <ErrorGettingContent />
      ) : stripeSubslist.length > 0 ? (
        <Box>
          <SubscriptionSelector options={options} selectedUsername={selectedUsername} setSelectedUsername={setSelectedUsername} />
          {displayPosts.length > 0 ? <SubscribedPosts posts={displayPosts} hasMore={hasMore} loadMore={loadMorePosts} /> : <NoContent />}
          {loading && <Center><Spinner /></Center>}
        </Box>
      ) : (
        <NoSubscriptions />
      )}
    </PageLayout>
  );
}

export default Home;
