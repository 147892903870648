// NoSubscriptions.js

import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { HiDocumentSearch } from 'react-icons/hi';

const NoContent = () => (
  <Box>
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="50vh"
      maxW="400px"
      mx="auto"
    >
      <HiDocumentSearch color="#A1A0A3" size={80} className="empty-icon" />
      <Heading size='md' mt={4} mb={4}>No Content Found in Your Subscriptions</Heading>
      <Text mb={4} textAlign="center">
        It looks like your subscriptions don't have any content or posts yet.
        As soon as there's something to see, it'll appear right here. Stay tuned!
      </Text>
    </Flex>
  </Box>
);

export default NoContent;
