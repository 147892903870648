// useProfileByUsername.js
import { useState, useEffect } from 'react';
import userAPIService from '../apiServices/userAPIService';

export const useProfileByUsername = (username) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!username) return;

    const fetchProfile = async () => {
      console.log(username)
      setLoading(true);
      const { data, error } = await userAPIService.fetchProfileByUsername(username);
      if (error) {
        setError(error);
      } else {
        setProfile(data);
      }
      setLoading(false);
    };

    fetchProfile();
  }, [username]);

  return { profile, loading, error };
};
