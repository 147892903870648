import React, { useState, useEffect } from "react";
import ConnectedAccountLinks from "../components/Stripe/ConnectedAccountLinks";
import PageHeading from "../components/general/PageHeading";

import { useSelector } from "react-redux";


import { Link } from "@chakra-ui/react";
import "../index.css";
import PageLayout from "../components/general/PageLayout";


import { Box } from "@chakra-ui/react";

function OnboardingStripe() {
  const [balance, setBalance] = useState(null);
  const [isConnectAccount, setIsConnectAccount] = useState(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    // Fetch balance data and update state with setBalance
    console.log(isConnectAccount);
  }, [isConnectAccount]);

  return (
    <PageLayout activeLink="/dashboard">
      <Box h="full">
        {balance && <div>{balance}</div>}
        <ConnectedAccountLinks setIsConnectAccount={setIsConnectAccount} />

        <Box mt={4}>
          {isConnectAccount &&
            isConnectAccount.requirements.currently_due &&
            isConnectAccount.requirements.currently_due.length ===
              0(<Link href="/membership/onboarding/3">Save & Continue</Link>)}
        </Box>
      </Box>
    </PageLayout>
  );
}

export default OnboardingStripe;
