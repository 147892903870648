import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Logo2 from "../Images/Logo2.png";
import {ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Link,
  Image,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toast = useToast();
  const formWidth = useBreakpointValue({ base: "full", md: "md" });

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear previous error when input changes
  };

  const sendResetLink = async () => {
    setLoading(true); // Set loading to true at the start of the API call
    try {
      // Check if the email exists
      const { data: user } = await axios.post("/api/users/checkEmail", {
        email,
      });

      if (!user) {
        setError("The email you entered doesn't match our records");
        setLoading(false); // Reset loading state if there's an error
        return;
      }

      await axios.post("/api/mail/sendResetPasswordLink", { email });
      setConfirmationMessage("Check your email for the password reset link");
      setError("");
      toast({
        title: "Email Sent",
        description: "Check your email for the reset link.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setError("Failed to send reset password link. Please try again.");
    } finally {
      setLoading(false); // Always reset loading to false after the request finishes
    }
  };

  return (
    <>
      <Flex
        direction="column"
        w="full"
        minH="100vh"
        align="center"
        justify="center"
        p={6}
        bg="gray.50"
      >
        <Flex w="400px" p="32px" bg="white" boxShadow="xl" borderRadius="lg">
          <VStack display="flex" alignItems="start" w="full">
            <Button
              mb={4}
              p={0}
              style={{ textDecoration: "none", background: "none" }}
              as={Link}
              href="/"
            >
              <Image objectFit="cover" src={Logo2} alt="UI Marketplace" />
            </Button>
            <Text mb={2} fontSize="18px" fontWeight="semibold">
              Forgot Password
            </Text>
            <Box w="full">
              <Text mb={2} fontSize="sm" color="gray.600">
                Enter your email address and we’ll send you instructions to
                reset your password.
              </Text>
            </Box>

            <Box w="full">
              <Input
                id="email"
                type="email"
                value={email}
                onChange={handleChange}
                placeholder="Email"
                isInvalid={!!error}
              />
              {error && (
                <Text mb={0} fontSize="sm" color="red.600">
                  {error}
                </Text>
              )}
            </Box>

            <Button
              w="full"
              variant="primaryButton"
              onClick={sendResetLink}
              mt={2}
              isDisabled={!email || loading}
              isLoading={loading} // Show spinner while loading
            >
              Send Reset Link
            </Button>

            {confirmationMessage && (
              <Text m={0} fontSize="sm" fontWeight="medium" color="green.700">
                {confirmationMessage}
              </Text>
            )}

            <Text fontSize="sm" color="gray.600" mt={4}>
              Remembered your password?{" "}
              <Link as={RouterLink} to="/login" color="blue.500">
              Login in <ArrowForwardIcon />
              </Link>
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </>
  );
}

export default ForgotPassword;
