import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { useSelector } from 'react-redux';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Select,
  Spinner,
  useToast,
} from '@chakra-ui/react';

function FeedbackModal({ isOpen, onClose }) {
  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState({ type: false, description: false });
  const [characterCount, setCharacterCount] = useState(0);
  const toast = useToast();

  const auth = useSelector(state => state.auth);


  const validateAndSendFeedback = async () => {
    // Simple validation
    if (!type || !description) {
      setError({
        type: !type,
        description: !description,
      });
      return;
    }
    setError({ type: false, description: false });
    setLoading(true);

    const sendFeedback = async (type, description, userEmail, userId, name) => {
        try {
          const response = await axios.post('/api/mail/feedback', { type, description, userEmail, userId, name });
          return { success: true, data: response.data };
        } catch (error) {
          console.error('Error sending feedback:', error);
          return { success: false, error: error.response ? error.response.data : 'Network error' };
        }
      };

    // Assume a function sendFeedback exists to handle API requests
    const result = await sendFeedback(type, description, auth.user.email, auth.user.id, auth.user.name);
    setLoading(false);
    if (result.success) {
      toast({
        title: 'Feedback sent successfully, expect a response within 48hrs',
        status: 'success',
        duration: 10000,
        isClosable: true,
      });
      // Resetting the form inputs and character count
      setType('');
      setDescription('');
      setCharacterCount(0);
      onClose(); // Close the modal
    } else {
      toast({
        title: 'An error occurred.',
        description: result.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    if (text.length <= 1000) {
      setDescription(text);
      setCharacterCount(text.length);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report Bug or Feedback</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={error.type} isRequired>
            <FormLabel>Type</FormLabel>
            <Select placeholder="Select type" onChange={(e) => setType(e.target.value)}>
              <option value="feedback">Feedback</option>
              <option value="bug">Bug</option>
            </Select>
            {error.type && <FormErrorMessage>This field is required.</FormErrorMessage>}
          </FormControl>

          <FormControl mt={4} isInvalid={error.description} isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={handleDescriptionChange} placeholder="Describe the issue or feedback in detail." />
            <FormErrorMessage>This field is required.</FormErrorMessage>
            <div>{characterCount}/1000</div>
          </FormControl>
        </ModalBody>

        <ModalFooter gap={4}>
        <Button onClick={onClose}>Cancel</Button>
          <Button variant="primaryButton" mr={3} onClick={validateAndSendFeedback} isLoading={isLoading} isDisabled={isLoading}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FeedbackModal;
