import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions.js";

import UploadModal1 from "./UploadModal1";


class MyNavbar extends React.Component{

  constructor(props){
         super(props);
         this.state = {modalShow : true};

         this.setModalShow = this.setModalShow.bind(this);
       }

       setModalShow(x){
            this.setState((prevState)=>{
              return {modalShow: x}
            });
          }


  render () {
  return(
    <div>
    <Navbar bg="light" variant="light">
  <Container>
    <Navbar.Brand href="#home">Navbar</Navbar.Brand>
    <Nav className="me-auto">
      <Nav.Link href="#home">Home</Nav.Link>
      <Nav.Link href="#features">Features</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link>

      <Button variant="primary" onClick={() => this.setModalShow(true)}>
        Upload
      </Button>

      <UploadModal1
      show={this.modalShow}
      onHide={() => this.setModalShow(false)}
      />


      <button
        style={{
          width: "150px",
          borderRadius: "3px",
          letterSpacing: "1.5px",
          marginTop: "1rem"
        }}
        onClick={this.onLogoutClick}
        className="btn btn-large waves-effect waves-light hoverable blue accent-3"
      >
        Logout
      </button>


    </Nav>
    </Container>
  </Navbar>
  </div>
);
  }
    }

MyNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(MyNavbar);
