import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import ActionContainer from "../components/general/ActionContainer";
import PageHeading from "../components/general/PageHeading";
import Overview from "../Images/Overview.svg";
import TogglePageStatus from "../components/MembershipSettings/TogglePageStatus";

import { useProfileById } from '../customHooks/useProfileById';
import { useAuthUser } from '../customHooks/useAuthUser';

import StripeAccountRequirementsCheck from "../components/Stripe/StripeAccountRequirementsCheck";

import Stripe2 from "../Images/Stripe2.svg";

import {
  Box,
  Flex,
  Alert,
  AlertIcon,
  Link,
  Text,
  HStack,
  Heading,
  Progress,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Stack } from "react-bootstrap";

// Onboarding Step 1
const OnboardingOverview = () => {
  // Initialize state for form data
  const [formData, setFormData] = useState({});
  const [isOnboardingOneComplete, setIsOnboardingOneComplete] = useState(false);
  const [isOnboardingTwoComplete, setIsOnboardingTwoComplete] = useState(false);
  const [isOnboardingThreeComplete, setIsOnboardingThreeComplete] = useState(false);
  const [link, setLink] = useState("");
  const [completeness, setCompleteness] = useState(0);

  const auth = useSelector((state) => state.auth);
  console.log(auth);

  const authUser = useProfileById(useAuthUser()?.id); // Assuming useAuthUser returns the user object with an id
  console.log(authUser)

  const fetchData = async () => {
    try {
      const response = await axios.post("/api/users/get-profile", {
        id: auth.user.id,
      });
      console.log(response);
      setFormData(response.data);

      // Set Status of Onboarding One
      if (
        response.data.username != null ||
        response.data.username != undefined
      ) {
        setIsOnboardingOneComplete(true);
      }

      // Set Status of Onboarding Two
      if (
        response.data.stripeConnectedAccountID != null ||
        response.data.stripeConnectedAccountID != undefined
      ) {
        setIsOnboardingTwoComplete(true);
      }

      // Set Status of Onboarding Three
      if (
        response.data.plan.stripeMonthlyPriceLink != null &&
        response.data.plan.stripeYearlyPriceLink != null &&
        response.data.plan.monthly >= 5 &&
        response.data.plan.yearly >= 5
      ) {
        setIsOnboardingThreeComplete(true);
      } else {
        setIsOnboardingThreeComplete(false);
      }

      if (
        response.data.username != null ||
        response.data.username != undefined
      ) {
        setLink(`/u/${response.data.username}`);
      }

      console.log(
        isOnboardingOneComplete,
        isOnboardingTwoComplete,
        isOnboardingThreeComplete,
        formData.plan?.isActive
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Calculate completeness based on boolean values
    const newCompleteness =
      (isOnboardingOneComplete ? 25 : 0) +
      (isOnboardingTwoComplete ? 25 : 0) +
      (isOnboardingThreeComplete ? 25 : 0) +
      (formData.plan?.isActive ? 25 : 0);

    setCompleteness(newCompleteness);
  }, [
    isOnboardingOneComplete,
    isOnboardingTwoComplete,
    isOnboardingThreeComplete,
    formData.plan?.isActive,
  ]);

    // Determine if any onboarding step is incomplete
    const isAnyOnboardingIncomplete = !isOnboardingOneComplete || !isOnboardingTwoComplete || !isOnboardingThreeComplete;


  const handleMembershipStatusChange = (newStatus) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan: {
        ...prevFormData.plan,
        isActive: newStatus,
      },
    }));
  };

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paddingx = useBreakpointValue({ base: "4", md: "32" });

  return (
    <Flex h="100%">
      <VStack alignItems="flex-start" flex="1" overflowY="auto" py={0}>

      <VStack w="full">

      <Box width="100%" mb={4}>
          <StripeAccountRequirementsCheck />
        </Box>


        <Flex w="full" direction={flexDirection} justifyContent="space-between"  mb={4}>

          <Box py={0}>
          <PageHeading
            heading="Onboarding Overview"
            subHeading="Complete your onboarding to share and monetize your content"
          />
          </Box>

          {formData.plan && (
            <TogglePageStatus
              currentActiveStatus={formData.plan.isActive}
              id={formData._id}
              disabled={isAnyOnboardingIncomplete} // Pass disabled prop based on the onboarding completeness
              stripeID={formData.stripeConnectedAccountID}
            />
          )}
        </Flex>


      </VStack>



        <Flex width="100%" flexDirection={flexDirection} alignItems="flex-start" gap={4}>

        <ActionContainer
          imgSrc={Overview}
          heading="1. Membership Details"
          subheading="Complete your profile to tell your subscribers about yourself."
          buttonText="Add Details"
          buttonLink="/membership/onboarding/1"
          badge={isOnboardingOneComplete}
          disabled={false}       
           />

        <ActionContainer
          imgSrc={Stripe2}
          heading="2. Connect Stripe"
          subheading="Connect your Stripe account to receive payments."
          buttonText="Connect Stripe"
          buttonLink="/membership/onboarding/2"
          badge={isOnboardingTwoComplete }
          disabled={!isOnboardingOneComplete } // Disabled if first step is incomplete
          tooltipLabel="Complete Membership Details"
          />


        <ActionContainer
          imgSrc={Overview}
          heading="3. Set Monthly & Yearly Prices"
          subheading="Set your membership prices to earn revenue from your services."
          buttonText="Set Prices"
          buttonLink="/membership/onboarding/3"
          badge={isOnboardingThreeComplete}
          disabled={!isOnboardingOneComplete || !isOnboardingTwoComplete} // Disabled if first or second step is incomplete
          tooltipLabel="Complete Membership Details & Connect Stripe"

        />
        </Flex>
        
      </VStack>
    </Flex>
  );
};

export default OnboardingOverview;
