import { useState, useEffect } from 'react';
import { Alert } from '@chakra-ui/react';

function DeleteBanner() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);

    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Alert status="info" variant="subtle" hidden={!show}>
      Post deleted successfully
    </Alert>
  );
}

export default DeleteBanner;