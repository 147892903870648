import { Box, Flex, Text } from "@chakra-ui/react";

const Badge = ({ label,bg,color }) => {
  return (
    <Flex
      h="100%"
      px="8px"
      py="3px"
      bg={bg}
      borderRadius="5px"
      align="center"
      display="inline-flex"
    >
      <Text
        color={color}
        fontSize="14px"
        fontWeight="475"
        lineHeight="20px"
        fontFamily="Inter"
        wordBreak="break-word"
        mb={0}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default Badge;