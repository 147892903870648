// SubscriptionSelector.js

import React from 'react';
import Select from 'react-select';
import { Box } from '@chakra-ui/react';

const SubscriptionSelector = ({ options, selectedUsername, setSelectedUsername }) => (
  <Box mt={12} mb={8} display="flex" justifyContent="flex-start">
    <Select
      options={options}
      value={{ value: selectedUsername, label: selectedUsername }}
      onChange={(selectedOption) => setSelectedUsername(selectedOption.value)}
      isClearable={false}
      styles={{
        control: (provided) => ({ ...provided, width: '20vw' })
      }}
    />
  </Box>
);

export default SubscriptionSelector;
