import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
    Stack,
    Divider,
    useBreakpointValue,
  } from "@chakra-ui/react";
  import PublicNav from "../components/general/PublicNav";
import Footer from "../components/general/Footer";
  
  const PrivacyPolicy = () => {
    const paddingx = useBreakpointValue({ base: "8", md: "16" });
    const paddingy = useBreakpointValue({ base: "2", md: "2" });
    return (
      <Flex
        flexDirection="column"
        px={paddingx}
        py={paddingy}
        bg="#ffffff"
        minH="100vh"
        minW="100vw"
      >
        <PublicNav />
  
        <Container maxW="container.md" py={8}>
          <Heading as="h1" size="xl" mb={4}>
            UIMarketplace Privacy Policy
          </Heading>
          <Text mb={6}>Last updated: September 24, 2024</Text>
  
          <Stack spacing={6}>
            <Section title="1. Introduction">
              <Text>
                Montague Labs LLC ("Montague Labs", "we", "us", or "our") is
                committed to protecting your privacy. This Privacy Policy
                explains how we collect, use, disclose, and protect your
                information when you use our website, application programming
                interfaces (APIs), and all related services provided by Montague
                Labs ("UIMarketplace") and the choices you have associated with
                that information. Please read this Privacy Policy carefully. By
                accessing or using UIMarketplace, you agree to the terms
                described in this Privacy Policy.
              </Text>
            </Section>
  
            <Section title="2. Information We Collect">
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  2.1 Information You Provide to Us
                </Heading>
                We collect information that you provide directly to us when you:
                <ul>
                  <li>
                    <b>Create an Account:</b> When you create an account on
                    UIMarketplace, we may collect your name, email address,
                    username, password, and other information you choose to
                    provide.
                  </li>
                  <li>
                    <b>Upload Content:</b> If you are a Creator, we collect the
                    Content you upload, which may include design files, code
                    files, text descriptions, and other associated materials.
                  </li>
                  <li>
                    <b>Make a Purchase:</b> If you are a Subscriber, we collect
                    information necessary to process your payment, such as your
                    payment card information, billing address, and other
                    information required by our payment processor (Stripe). Note
                    that we do not store your full payment card information; it is
                    processed and stored by Stripe.
                  </li>
                  <li>
                    <b>Communicate with Us:</b> We collect information you
                    provide when you contact us for support, feedback, or other
                    inquiries. This may include your name, email address, and
                    the content of your communication.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  2.2 Information We Collect Automatically
                </Heading>
                When you use UIMarketplace, we automatically collect certain
                information about your device and usage of the platform,
                including:
                <ul>
                  <li>
                    <b>Usage Data:</b> Information about how you use
                    UIMarketplace, such as the features you access, the Content
                    you view, the time and duration of your sessions, and other
                    similar data.
                  </li>
                  <li>
                    <b>Device Information:</b> Data about the device you use to
                    access UIMarketplace, such as the hardware model, operating
                    system and version, unique device identifiers, mobile network
                    information, and browser type.
                  </li>
                  <li>
                    <b>Log Information:</b> Server log information, such as your
                    IP address, access times, browser type and language,
                    referring/exit pages and URLs, date and time of your visit,
                    and the pages you request.
                  </li>
                  <li>
                    <b>Cookies and Similar Technologies:</b> We use cookies and
                    similar technologies (e.g., web beacons, pixel tags) to
                    collect and store information. This may include information
                    about your browsing activity, preferences, and other online
                    activity. You can find more information about our use of
                    cookies in Section 7 below.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  2.3 Information We Collect from Third Parties
                </Heading>
                We may receive information about you from third parties, such
                as:
                <ul>
                  <li>
                    <b>Payment Processors:</b> Our payment processor (Stripe) may
                    provide us with information about your transactions on
                    UIMarketplace, such as payment confirmations and payment
                    method details.
                  </li>
                  <li>
                    <b>Social Media Platforms:</b> If you connect your
                    UIMarketplace account to your social media accounts, we may
                    collect information about your social media profile,
                    connections, and activity.
                  </li>
                  <li>
                    <b>Other Sources:</b> We may obtain information from
                    publicly available sources or third-party data providers to
                    supplement the information we collect directly.
                  </li>
                </ul>
              </Text>
            </Section>
  
            <Section title="3. How We Use Your Information">
              <Text>
                We use the information we collect for the following purposes:
                <ul>
                  <li>
                    <b>To Provide and Maintain UIMarketplace:</b> To operate,
                    provide, and maintain the functionality of UIMarketplace,
                    process your transactions, and fulfill your requests for our
                    services.
                  </li>
                  <li>
                    <b>To Improve and Personalize Your Experience:</b> To
                    analyze and understand how UIMarketplace is used,
                    personalize your experience (e.g., recommend Content), and
                    improve the functionality and user-friendliness of our
                    platform.
                  </li>
                  <li>
                    <b>To Communicate with You:</b> To send you administrative
                    messages, service announcements, updates, and marketing
                    communications that we believe may be of interest to you.
                  </li>
                  <li>
                    <b>For Security and Fraud Prevention:</b> To detect, prevent,
                    and address fraud, unauthorized access to UIMarketplace,
                    violations of our Terms of Service, or other illegal
                    activities.
                  </li>
                  <li>
                    <b>To Comply with Legal Obligations:</b> To comply with
                    applicable laws and regulations, subpoenas, or other legal
                    requests.
                  </li>
                </ul>
              </Text>
            </Section>
  
            <Section title="4. How We Share Your Information">
              <Text>
                We may share your information in the following circumstances:
                <ul>
                  <li>
                    <b>With Service Providers:</b> We may share your information
                    with third-party service providers that perform services on
                    our behalf, such as payment processing, data analytics,
                    marketing and advertising, customer support, and other
                    business operations.
                  </li>
                  <li>
                    <b>With Other Users:</b> If you are a Creator, your public
                    profile information and Content will be viewable by other
                    users on UIMarketplace. If you are a Subscriber, your
                    Subscription information may be shared with the Creators
                    whose Content you subscribe to.
                  </li>
                  <li>
                    <b>In Connection with Business Transfers:</b> If we are
                    involved in a merger, acquisition, financing due diligence,
                    sale of company assets, or any other transaction that
                    involves the transfer of your information, we may disclose
                    your information to the relevant third parties subject to
                    confidentiality obligations.
                  </li>
                  <li>
                    <b>For Legal Reasons:</b> We may disclose your information
                    if we believe in good faith that such disclosure is necessary
                    to: (a) comply with applicable law, regulation, legal
                    process, or governmental request; (b) enforce our Terms of
                    Service, including investigation of potential violations; (c)
                    protect against harm to the rights, property, or safety of
                    Montague Labs, our users, or others; or (d) to address
                    fraud or security issues.
                  </li>
                  <li>
                    <b>With Your Consent:</b> We may share your information for
                    any other purpose disclosed to you at the time we collect the
                    information or with your consent.
                  </li>
                </ul>
              </Text>
            </Section>
            <Section title="5. Your Privacy Choices">
              <Text>
                You have the following choices regarding your information:
                <ul>
                  <li>
                    <b>Account Information:</b> You can review and update your
                    account information by logging into your account settings
                    page.
                  </li>
                  <li>
                    <b>Marketing Communications:</b> You can opt out of receiving
                    marketing emails from us by following the unsubscribe
                    instructions included in these emails. Please note that even
                    if you opt out of receiving marketing communications, you
                    may still receive administrative messages from us.
                  </li>
                  <li>
                    <b>Cookies:</b> You can manage your cookie preferences
                    through your browser settings. However, please note that
                    disabling cookies may affect your ability to use certain
                    features of UIMarketplace.
                  </li>
                </ul>
              </Text>
            </Section>
            <Section title="6. Data Security">
              <Text>
                We use commercially reasonable security measures designed to
                protect your information from unauthorized access, disclosure,
                alteration, or destruction. However, no method of transmission
                over the internet or method of electronic storage is 100%
                secure. Therefore, while we strive to use commercially
                acceptable means to protect your information, we cannot guarantee
                its absolute security.
              </Text>
            </Section>
            <Section title="7. Children's Privacy">
              <Text>
                UIMarketplace is not intended for use by children under the age
                of 18. We do not knowingly collect information from children
                under 18. If you become aware that a child has provided us
                with personal information, please contact us at{" "}
                support@uimarketplace.com and we will take steps to delete such
                information.
              </Text>
            </Section>
            <Section title="8. Cookies and Similar Technologies">
              <Text>
                We and our third-party service providers use cookies and similar
                technologies to operate and improve UIMarketplace, personalize
                your experience, analyze trends, track user movements around the
                platform, and gather demographic information about our user base
                as a whole. You can control the use of cookies at the individual
                browser level. If you reject cookies, you may still use
                UIMarketplace, but your ability to use some features or areas
                of our website may be limited.
              </Text>
            </Section>
            <Section title="9. Changes to This Privacy Policy">
              <Text>
                We may update our Privacy Policy from time to time. If we make
                material changes, we will notify you by posting the updated
                Privacy Policy on UIMarketplace and, in some cases, we may
                provide you with additional notice (such as by adding a
                statement to our homepage or sending you an email
                notification). We encourage you to review this Privacy Policy
                periodically for any changes.
              </Text>
            </Section>
            <Section title="10. Contact Us">
              <Text>
                If you have any questions about this Privacy Policy, please
                contact us at support@uimarketplace.com
              </Text>
            </Section>
            {/* Add more sections as needed */}
          </Stack>
        </Container>
        <Footer />
      </Flex>
    );
  };
  
  const Section = ({ title, children }) => (
    <Box>
      <Heading as="h2" size="lg" mb={2}>
        {title}
      </Heading>
      <Text mb={4}>{children}</Text>
      <Divider />
    </Box>
  );
  
  export default PrivacyPolicy;