import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  VStack,
  HStack,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalCloseButton,
  Image,
  Center,
  Spinner,
  Flex,
} from "@chakra-ui/react";


import { Link as ChakraLink } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import { ViewIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import PageLayout from "../components/general/PageLayout";
import { usePostById } from "../customHooks/usePostById";
import { useProfileByUsername } from "../customHooks/useProfileByUsername";
import axios from "axios";

const ViewPost = () => {
  const { id } = useParams();
  const auth = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [userSubscribed, setIsUserSubscribed] = useState(false);

  // Fetch post data
  const { post: data, loading, error } = usePostById(id);
  

  // Fetch profile data based on username from the post data
  const {
    profile: profileData,
    profileLoading,
    profileError,
  } = useProfileByUsername(data?.username);

  // Fetch profile data based on username from auth
  const {
    profile: authData,
    authLoading,
    authError,
  } = useProfileByUsername(auth?.user.username);

  useEffect(() => {
    // console.log(data?.codeURLs[0] && data?.codeURLs[0]);
    // console.log(data);
    // console.log(data.codeURLs[1].CodeFileKey);


    console.log(auth?.user.username);

    // Set loading
    setSubscriptionLoading(true);

    // Redirect unauthenticated users
    if (!auth.isAuthenticated) {
      window.location.href = "/login";
      return; // Early return to prevent further execution
    }

    const canShowPost = data?.username === auth.user?.username;

    // If the post is by the authenticated user, skip further logic.
    if (canShowPost) {
      console.log("Showing own post, skipping subscription check.");
      setSubscriptionLoading(false);
      return; // Early return
    }

    // Proceed if the profileData contains a stripeConnectedAccountID and the user is authenticated
    if (profileData?.stripeConnectedAccountID && auth.user.email) {
      axios
        .post("/api/subscription/get-mongo-subscriptions-for-posts", {
          authEmail: auth.user.email,
          authorConnectedAccountId: profileData.stripeConnectedAccountID,
        })
        .then((response) => {
          if (response.data === null) {
            // User was never subscribed
            window.location.href = "/home";
          } else {
            //User is subscribed
            setSubscriptionDetails(response.data);
            setIsUserSubscribed(true);
            setSubscriptionLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setSubscriptionError(err);
          setSubscriptionLoading(false);
        });
    }
  }, [profileData, auth, authData]); // Ensure authData is included in the dependency array if it's not static

  if (loading || profileLoading || subscriptionLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (error || profileError || subscriptionError) {
    const errorMessage =
      error?.message || profileError?.message || subscriptionError?.message;
    return <Center h="100vh">Error: {errorMessage}</Center>;
  }

  // Show the post if data.username matches auth.user.username or handle other conditions
  const canShowPost = data?.username == auth.user?.username;

  // This code ensures only authors and subscribers can view the post
  return (
    <PageLayout>
      {(canShowPost || userSubscribed) && data ? (
        <Box pb="32px" overflowY="auto">
          <VStack>
            <HStack justifyContent="space-between" w="full">
              <HStack py={4} alignItems="left">
                <Avatar
                  name={data.username || data.fullName}
                  src={data.profileImage}
                />
                <VStack justify="center">
                  <ChakraLink
                    as={RouterLink}
                    to={`/u/${data.username ? data.username : data.fullName}`} // Dynamically generating the path
                    fontSize="18px"
                    mb={0}
                    display="block" // Ensuring the link is properly clickable
                    fontWeight="bold"
                    color="#0A090B"
                  >
                    {data.username ? data.username : data.fullName}{" "}
                    <ExternalLinkIcon mx="2px" />
                  </ChakraLink>
                </VStack>
              </HStack>

              <Box>
                      {data.postType === "Design" ? (
                        <ChakraLink
                          href={data.url}
                          target="_blank"
                          bg="#1964FF"
                          borderRadius="6px"
                          color="white"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding="0px 14px"
                          height="44px"
                          _hover={{ color: "white", bg: "#0e4bef" }}
                          fontSize="16px"
                          fontWeight="600"
                        >
                          View Design
                        </ChakraLink>
                      ) : (
                        <ChakraLink
                        href={data.codeURLs && data.codeURLs[1].CodeFileLink}
                        download
                        bg="#1964FF"
                        borderRadius="6px"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding="0px 14px"
                        height="44px"
                        _hover={{ color: "white", bg: "#0e4bef" }}
                        fontSize="16px"
                        fontWeight="600"
                      >
                        Download Code
                      </ChakraLink>
                      )}
                  </Box>
            </HStack>

            <VStack mt={0} mb={4} alignItems="left" w="full">
              <Box position="relative" backgroundColor="#f8f8f8">
                <Image
                  mb={0}
                  borderWidth="1px"
                  borderRadius="md"
                  w="100%"
                  maxH="60vh"
                  objectFit="contain"
                  src={data.mediaLink}
                />
                <IconButton
                  icon={<ViewIcon />}
                  position="absolute"
                  bottom="2"
                  right="2"
                  onClick={() => setIsModalOpen(true)}
                  aria-label="Expand image"
                />
              </Box>

              {/* Modal for displaying the image in full view */}
              <Modal
                size="xl"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              >
                <ModalOverlay />
                <ModalContent
                  maxW="90vw"
                  maxH="90vh"
                  mt="5vh"
                  mb="5vh"
                  overflowY="hidden"
                >
                  <ModalCloseButton mt={3} p={0} />

                  <ModalHeader>
                    <Flex
                      direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and up
                      width="full"
                      justifyContent="space-between" // Maintain the space-between for horizontal layout
                      alignItems="left" // Ensure items are centered in the Flex container
                      pr={{ base: "0", md: "16" }} // Adjust padding-right responsively if needed
                    >
                      <Flex alignItems="center">
                        <Avatar
                          mr={2}
                          size="md"
                          name={data.fullName}
                          src={data.profileImage}
                        />
                        <VStack justify="center">
                          <ChakraLink
                            as={RouterLink}
                            to={`/u/${
                              data.username ? data.username : data.fullName
                            }`} // Dynamically generating the path
                            fontSize="18px"
                            mb={0}
                            display="block" // Ensuring the link is properly clickable
                            fontWeight="bold"
                            color="blue"
                          >
                            {data.username ? data.username : data.fullName}{" "}
                            <ExternalLinkIcon mx="2px" />
                          </ChakraLink>
                        </VStack>
                      </Flex>
                      <Box>
                      {data.postType === "Design" ? (
                        <ChakraLink
                          href={data.url}
                          target="_blank"
                          bg="#1964FF"
                          borderRadius="6px"
                          color="white"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding="0px 14px"
                          height="44px"
                          _hover={{ color: "white", bg: "#0e4bef" }}
                          fontSize="16px"
                          fontWeight="600"
                        >
                          View Design
                        </ChakraLink>
                      ) : (
                        <ChakraLink
                        href={data.codeURLs && data.codeURLs[1].CodeFileLink}
                        download
                        bg="#1964FF"
                        borderRadius="6px"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding="0px 14px"
                        height="44px"
                        _hover={{ color: "white", bg: "#0e4bef" }}
                        fontSize="16px"
                        fontWeight="600"
                      >
                        Download Code
                      </ChakraLink>
                      )}
                  </Box>
                    </Flex>
                  </ModalHeader>

                  <ModalBody p={0} m={0} overflowY="auto">
                    <Image
                      src={data.mediaLink}
                      alt={`Full view of ${data.title}`}
                      objectFit="cover"
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>

              <Text
                width="full"
                height="26px"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="600"
                fontSize="18px"
                lineHeight="26px"
                display="flex"
                letterSpacing="-0.18px"
                color="#222124"
              >
                {data.title}
              </Text>
              <Text>{data.description}</Text>
              <Text>{data.tags}</Text>
            </VStack>
          </VStack>
        </Box>
      ) : (
        <Center h="100vh">You do not have permission to view this post.</Center>
      )}
    </PageLayout>
  );
};

export default ViewPost;
