import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
    Stack,
    Divider,
    useBreakpointValue,
  } from "@chakra-ui/react";
  import PublicNav from "../components/general/PublicNav";
import Footer from "../components/general/Footer";
  
  const TermsAndConditions = () => {
    const paddingx = useBreakpointValue({ base: "8", md: "16" });
    const paddingy = useBreakpointValue({ base: "2", md: "2" });
    return (
  
      <Flex flexDirection="column" px={paddingx} py={paddingy} bg="#ffffff" minH="100vh" minW="100vw">
        <PublicNav />
  
        <Container maxW="container.md" py={8}>
          <Heading as="h1" size="xl" mb={4}>
            UIMarketplace Terms of Use
          </Heading>
          <Text mb={6}>Last updated: September 24, 2024</Text>
  
          <Stack spacing={6}>
            <Section title="1. Welcome to UIMarketplace">
              <Text>
                These Terms of Service ("Terms") govern your access to and use
                of the UIMarketplace website, application programming
                interfaces (APIs), and all related services provided by Montague
                Labs LLC ("Montague Labs", "we", "us", or "our"). Please read
                these Terms carefully before using UIMarketplace. By accessing
                or using UIMarketplace, you ("you" or "User") agree to be
                bound by these Terms. If you disagree with any part of the
                Terms, then you may not access or use UIMarketplace.
              </Text>
            </Section>
  
            <Section title="2. UIMarketplace Services">
              <Text>
                UIMarketplace is a platform that allows creators ("Creators") to
                upload design files and front-end code files ("Content") to
                their channels and charge access to their Content for a
                subscription fee ("Subscription") from users who wish to access
                and utilize such Content ("Subscribers").
              </Text>
            </Section>
  
            <Section title="3. Eligibility">
              <Text>
                You must be at least 18 years old and able to form a binding
                contract with Montague Labs to use UIMarketplace. By accessing
                or using UIMarketplace, you represent and warrant that you meet
                these eligibility requirements. If you are accessing or using
                UIMarketplace on behalf of a company or other legal entity, you
                represent and warrant that you have the authority to bind that
                entity to these Terms.
              </Text>
            </Section>
  
            <Section title="4. Account Creation and Security">
              <Text>
                To access certain features of UIMarketplace, you may need to
                create an account. You are responsible for maintaining the
                confidentiality of your account login information, including
                your password, and for all activity that occurs under your
                account. You agree to notify us immediately of any unauthorized
                access to or use of your account. We reserve the right to
                terminate your account or restrict your access to UIMarketplace
                for any reason, at our sole discretion.
              </Text>
            </Section>
  
            <Section title="5. Content">
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  5.1 Content Ownership and Licenses
                </Heading>
                <ul>
                  <li>
                    <b>Creator Content:</b> Creators retain full ownership of the
                    Content they upload to UIMarketplace.
                  </li>
                  <li>
                    <b>Subscriber Use:</b> Subscribers are granted a
                    non-exclusive, non-transferable, revocable license to
                    access and use Content for their own personal or internal
                    business purposes, solely as permitted by the applicable
                    Subscription plan and these Terms. Subscribers may not share,
                    distribute, or resell Content to any third party.
                  </li>
                  <li>
                    <b>License Granted to UIMarketplace:</b> By uploading
                    Content to UIMarketplace, you grant to Montague Labs a
                    royalty-free, perpetual, irrevocable, non-exclusive,
                    sublicensable, worldwide license to use, copy, reproduce,
                    modify, adapt, publish, translate, distribute, perform,
                    display, and create derivative works from your Content, in
                    whole or in part, in any media formats and through any media
                    channels now known or later developed, for the purpose of
                    operating, promoting, and improving UIMarketplace. This
                    license includes the right to use your name, username, and
                    likeness in connection with your Content.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  5.2 Content Restrictions
                </Heading>
                You are solely responsible for the Content you upload, post, or
                otherwise make available on or through UIMarketplace. You
                represent and warrant that:
                <ul>
                  <li>
                    You own all rights in and to your Content or have obtained
                    all necessary licenses and permissions to use and authorize
                    UIMarketplace to use your Content as described herein;
                  </li>
                  <li>
                    Your Content does not and will not infringe, misappropriate,
                    or violate any third party's intellectual property rights,
                    privacy rights, publicity rights, or other legal or moral
                    rights;
                  </li>
                  <li>
                    Your Content is not and will not be illegal, harmful,
                    threatening, abusive, harassing, tortious, defamatory,
                    vulgar, obscene, libelous, invasive of another's privacy,
                    hateful, or racially, ethnically, or otherwise
                    objectionable; and
                  </li>
                  <li>
                    Your Content does not and will not contain any viruses,
                    worms, malware, Trojan horses, or other harmful or malicious
                    code.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  5.3 Prohibited Content
                </Heading>
                UIMarketplace prohibits the uploading, posting, or otherwise
                making available of any Content that:
                <ul>
                  <li>
                    Is sexually explicit or suggestive, or exploits, abuses, or
                    endangers children;
                  </li>
                  <li>
                    Promotes or glorifies violence, hatred, or discrimination
                    against any individual or group;
                  </li>
                  <li>Is false, misleading, or deceptive;</li>
                  <li>
                    Promotes illegal activities or violates any applicable laws
                    or regulations;
                  </li>
                  <li>
                    Impersonates any person or entity, or falsely states or
                    misrepresents your affiliation with any person or entity;
                  </li>
                  <li>
                    Contains personal information of any third party, including
                    but not limited to names, addresses, phone numbers, and
                    email addresses, without their consent;
                  </li>
                  <li>
                    Interferes with or disrupts the operation of UIMarketplace
                    or the servers or networks connected to UIMarketplace; or
                  </li>
                  <li>
                    Attempts to gain unauthorized access to any portion of
                    UIMarketplace, other accounts, computer systems, or
                    networks connected to UIMarketplace.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  5.4 Content Removal
                </Heading>
                We reserve the right, but are not obligated, to monitor Content
                on UIMarketplace. We may remove or disable access to any
                Content that we believe, in our sole discretion, violates
                these Terms or may be harmful or objectionable to
                UIMarketplace, our users, or third parties. We may also
                terminate your access to UIMarketplace if you repeatedly
                violate these Terms or engage in any other conduct that we
                deem, in our sole discretion, to be harmful or objectionable.
              </Text>
            </Section>
  
            <Section title="6. Payments and Fees">
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  6.1 Payment Processing
                </Heading>
                UIMarketplace uses Stripe to process all Subscription payments.
                By subscribing to a Creator's Content, you agree to be bound by
                Stripe's terms of service, which can be found on their website.
                You are responsible for paying all applicable Stripe processing
                fees.
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  6.2 UIMarketplace Fees
                </Heading>
                UIMarketplace charges Creators a 10% fee on all Subscription
                payments processed through the platform. This fee is deducted
                from the Subscription payment before it is paid out to the
                Creator's Stripe account.
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  6.3 Taxes
                </Heading>
                You are solely responsible for determining your tax reporting
                obligations and for paying any and all taxes associated with
                your use of UIMarketplace, including but not limited to any
                income taxes, sales taxes, value-added taxes, or other taxes or
                duties.
              </Text>
            </Section>
  
            <Section title="7. Account Termination and Suspension">
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  7.1 Termination by You
                </Heading>
                You may terminate your account at any time by contacting us at{" "}
                support@uimarketplace.com
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  7.2 Termination by UIMarketplace
                </Heading>
                UIMarketplace may terminate your account, delete your Content,
                and restrict your access to UIMarketplace at any time and for
                any reason, with or without notice, and without liability to
                you. We may terminate your account for any of the following
                reasons:
                <ul>
                  <li>You breach these Terms;</li>
                  <li>You infringe the intellectual property rights of others;</li>
                  <li>
                    We receive repeated complaints about your Content or conduct
                    on UIMarketplace;
                  </li>
                  <li>
                    You engage in any activity that we deem, in our sole
                    discretion, to be harmful or objectionable to
                    UIMarketplace, our users, or third parties; or
                  </li>
                  <li>
                    We are required to do so by law or legal process.
                  </li>
                </ul>
              </Text>
              <Text>
                <Heading as="h3" size="md" mb={2}>
                  7.3 Effect of Termination
                </Heading>
                Upon termination of your account, your right to use
                UIMarketplace will immediately cease. If you are a Creator,
                any active Subscriptions to your Content will be canceled and
                Subscribers will no longer have access to your Content. We may,
                but are not obligated to, delete your Content upon termination
                of your account.
              </Text>
            </Section>

            <Section title="8. Disclaimers">
            <Text>
              <Heading as="h3" size="md" mb={2}>
                8.1 No Warranties
              </Heading>
              UIMARKETPLACE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
              WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE
              ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. MONTAGUE LABS
              MAKES NO WARRANTY:
              <ul>
                <li>THAT UIMARKETPLACE WILL MEET YOUR REQUIREMENTS;</li>
                <li>
                  THAT UIMARKETPLACE WILL BE UNINTERRUPTED, TIMELY, SECURE,
                  OR ERROR-FREE;
                </li>
                <li>
                  THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
                  UIMARKETPLACE WILL BE ACCURATE OR RELIABLE;
                </li>
                <li>
                  THAT THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION,
                  OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
                  UIMARKETPLACE WILL MEET YOUR EXPECTATIONS;
                </li>
                <li>OR THAT ANY ERRORS IN UIMARKETPLACE WILL BE CORRECTED.</li>
              </ul>
            </Text>
            <Text>
              <Heading as="h3" size="md" mb={2}>
                8.2 No Endorsement
              </Heading>
              UIMarketplace may contain links to third-party websites or
              resources. We do not endorse and are not responsible for the
              content, products, or services offered by any third-party
              websites or resources. You acknowledge and agree that we shall
              not be liable, directly or indirectly, for any damage or loss
              caused or alleged to be caused by or in connection with use
              of or reliance on any content, goods, or services available on
              or through any such third-party websites or resources.
            </Text>
          </Section>

          <Section title="8. Limitation of Liability">
            <Text>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL MONTAGUE LABS, ITS AFFILIATES, LICENSORS, SERVICE
              PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE
              FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
              INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
              GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF
              MONTAGUE LABS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES), RESULTING FROM:
              <ul>
                <li>THE USE OR THE INABILITY TO USE UIMARKETPLACE;</li>
                <li>
                  THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                  RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES
                  PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
                  ENTERED INTO THROUGH OR FROM UIMARKETPLACE;
                </li>
                <li>
                  UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                  TRANSMISSIONS OR DATA;
                </li>
                <li>
                  STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON
                  UIMARKETPLACE;
                </li>
                <li>OR ANY OTHER MATTER RELATING TO UIMARKETPLACE.</li>
              </ul>
            </Text>
          </Section>

          <Section title="9. Indemnification">
            <Text>
              You agree to defend, indemnify, and hold harmless Montague Labs,
              its affiliates, licensors and service providers, and its and
              their respective officers, directors, employees, contractors,
              agents, licensors, suppliers, successors and assigns from and
              against any and all losses, liabilities, damages, judgments,
              awards, costs, and expenses (including reasonable attorneys'
              fees) arising out of or relating to your breach of these Terms
              or your use of UIMarketplace, including, but not limited to,
              your Content, any use of the Content, your connection to
              UIMarketplace, your violation of these Terms or any law or the
              rights of any third party.
            </Text>
          </Section>

          <Section title="10. Governing Law and Dispute Resolution">
            <Text>
              These Terms and any action related thereto will be governed by
              and construed in accordance with the laws of the State of
              [Your State], without regard to its conflict of law provisions.
              The exclusive jurisdiction for any claim or dispute arising
              out of or relating to these Terms or your use of UIMarketplace
              will be the state and federal courts located in [Your
              County], [Your State], and you agree to submit to the
              personal and exclusive jurisdiction of such courts.
            </Text>
          </Section>

          <Section title="11. Entire Agreement">
            <Text>
              These Terms constitute the entire agreement between you and
              Montague Labs relating to your access to and use of
              UIMarketplace and supersede any and all prior or contemporaneous
              written or oral agreements between you and Montague Labs
              regarding the same subject matter.
            </Text>
          </Section>

          <Section title="12. Waiver and Severability">
            <Text>
              Our failure to exercise or enforce any right or provision of
              these Terms will not operate as a waiver of such right or
              provision. If any provision of these Terms is held to be
              invalid or unenforceable, such provision will be struck and
              the remaining provisions will remain in full force and effect.
            </Text>
          </Section>

          <Section title="13. Contact Us">
            <Text>
              If you have any questions about these Terms, please contact us
              at support@uimarketplace.com
            </Text>
          </Section>
  

          </Stack>
        </Container>
        <Footer />
      </Flex>
    );
  };
  
  const Section = ({ title, children }) => (
    <Box>
      <Heading as="h2" size="lg" mb={2}>
        {title}
      </Heading>
      <Text mb={4}>{children}</Text>
      <Divider />
    </Box>
  );
  
  export default TermsAndConditions;